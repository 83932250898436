const startingDoodle = () => {
    if(window.innerWidth !== undefined && window.innerWidth > 950 ){
        return [
            {
                "tool": "pen",
                "points": [
                    207.5,
                    119.92103568710854,
                    208.5,
                    119.92103568710854,
                    208.5,
                    118.92078203352129,
                    207.5,
                    117.92052837993404,
                    207.5,
                    116.9202747263468,
                    206.5,
                    115.92002107275955,
                    206.5,
                    114.9197674191723,
                    205.5,
                    113.91951376558505,
                    202.5,
                    110.91875280482331,
                    202.5,
                    110.91875280482331,
                    201.5,
                    109.91849915123606,
                    200.5,
                    109.91849915123606,
                    199.5,
                    108.91824549764883,
                    197.5,
                    107.91799184406158,
                    197.5,
                    107.91799184406158,
                    195.5,
                    106.91773819047432,
                    194.5,
                    105.91748453688707,
                    193.5,
                    105.91748453688707,
                    192.5,
                    105.91748453688707,
                    191.5,
                    105.91748453688707,
                    190.5,
                    104.91723088329984,
                    189.5,
                    104.91723088329984,
                    187.5,
                    103.91697722971259,
                    185.5,
                    103.91697722971259,
                    183.5,
                    102.91672357612534,
                    182.5,
                    102.91672357612534,
                    177.5,
                    101.9164699225381,
                    176.5,
                    101.9164699225381,
                    174.5,
                    101.9164699225381,
                    174.5,
                    101.9164699225381,
                    172.5,
                    100.91621626895085,
                    171.5,
                    100.91621626895085,
                    170.5,
                    100.91621626895085,
                    169.5,
                    100.91621626895085,
                    168.5,
                    100.91621626895085,
                    166.5,
                    100.91621626895085,
                    165.5,
                    100.91621626895085,
                    164.5,
                    100.91621626895085,
                    163.5,
                    100.91621626895085,
                    161.5,
                    100.91621626895085,
                    160.5,
                    100.91621626895085,
                    159.5,
                    100.91621626895085,
                    156.5,
                    100.91621626895085,
                    154.5,
                    103.91697722971259,
                    152.5,
                    103.91697722971259,
                    151.5,
                    104.91723088329984,
                    149.5,
                    105.91748453688707,
                    148.5,
                    106.91773819047432,
                    147.5,
                    107.91799184406158,
                    147.5,
                    108.91824549764883,
                    145.5,
                    109.91849915123606,
                    143.5,
                    111.91900645841056,
                    143.5,
                    111.91900645841056,
                    139.5,
                    114.9197674191723,
                    138.5,
                    116.9202747263468,
                    138.5,
                    117.92052837993404,
                    137.5,
                    119.92103568710854,
                    136.5,
                    121.92154299428303,
                    135.5,
                    123.92205030145753,
                    135.5,
                    124.92230395504477,
                    135.5,
                    126.92281126221927,
                    135.5,
                    129.92357222298102,
                    135.5,
                    130.92382587656826,
                    135.5,
                    131.9240795301555,
                    135.5,
                    134.92484049091723,
                    135.5,
                    134.92484049091723,
                    135.5,
                    136.92534779809174,
                    135.5,
                    136.92534779809174,
                    135.5,
                    137.92560145167897,
                    135.5,
                    139.92610875885347,
                    135.5,
                    139.92610875885347,
                    136.5,
                    141.92661606602798,
                    137.5,
                    142.9268697196152,
                    137.5,
                    142.9268697196152,
                    138.5,
                    143.92712337320248,
                    140.5,
                    144.9273770267897,
                    142.5,
                    145.92763068037695,
                    144.5,
                    146.92788433396422,
                    145.5,
                    147.92813798755145,
                    146.5,
                    148.9283916411387,
                    147.5,
                    148.9283916411387,
                    149.5,
                    149.92864529472595,
                    149.5,
                    149.92864529472595,
                    150.5,
                    150.9288989483132,
                    155.5,
                    151.92915260190046,
                    156.5,
                    151.92915260190046,
                    157.5,
                    152.9294062554877,
                    158.5,
                    152.9294062554877,
                    158.5,
                    152.9294062554877,
                    159.5,
                    153.92965990907493,
                    160.5,
                    153.92965990907493,
                    161.5,
                    153.92965990907493,
                    162.5,
                    153.92965990907493,
                    162.5,
                    154.9299135626622,
                    163.5,
                    154.9299135626622,
                    164.5,
                    155.93016721624943,
                    164.5,
                    155.93016721624943,
                    166.5,
                    155.93016721624943,
                    167.5,
                    155.93016721624943,
                    168.5,
                    155.93016721624943,
                    170.5,
                    156.93042086983667,
                    172.5,
                    156.93042086983667,
                    174.5,
                    156.93042086983667,
                    175.5,
                    157.93067452342393,
                    175.5,
                    157.93067452342393,
                    177.5,
                    157.93067452342393,
                    177.5,
                    157.93067452342393,
                    177.5,
                    157.93067452342393,
                    179.5,
                    157.93067452342393,
                    180.5,
                    158.93092817701117,
                    181.5,
                    158.93092817701117,
                    183.5,
                    159.93118183059843,
                    184.5,
                    159.93118183059843,
                    187.5,
                    160.93143548418567,
                    189.5,
                    160.93143548418567,
                    190.5,
                    160.93143548418567,
                    191.5,
                    161.9316891377729,
                    191.5,
                    161.9316891377729,
                    191.5,
                    162.93194279136017,
                    193.5,
                    163.9321964449474,
                    195.5,
                    165.9327037521219,
                    196.5,
                    166.93295740570915,
                    197.5,
                    167.93321105929638,
                    199.5,
                    169.93371836647088,
                    200.5,
                    170.93397202005815,
                    200.5,
                    171.9342256736454,
                    201.5,
                    172.93447932723262,
                    202.5,
                    174.93498663440712,
                    203.5,
                    175.93524028799436,
                    203.5,
                    175.93524028799436,
                    203.5,
                    176.93549394158163,
                    203.5,
                    176.93549394158163,
                    203.5,
                    177.93574759516886,
                    203.5,
                    178.9360012487561,
                    203.5,
                    178.9360012487561,
                    203.5,
                    179.93625490234336,
                    203.5,
                    180.9365085559306,
                    203.5,
                    181.93676220951787,
                    203.5,
                    181.93676220951787,
                    203.5,
                    182.9370158631051,
                    203.5,
                    183.93726951669234,
                    202.5,
                    183.93726951669234,
                    202.5,
                    184.9375231702796,
                    202.5,
                    184.9375231702796,
                    201.5,
                    185.93777682386684,
                    201.5,
                    186.93803047745408,
                    201.5,
                    187.93828413104134,
                    200.5,
                    187.93828413104134,
                    198.5,
                    189.93879143821582,
                    197.5,
                    191.93929874539032,
                    194.5,
                    192.93955239897758,
                    194.5,
                    193.93980605256482,
                    192.5,
                    195.94031335973932,
                    190.5,
                    196.94056701332656,
                    189.5,
                    197.9408206669138,
                    188.5,
                    198.94107432050106,
                    188.5,
                    198.94107432050106,
                    187.5,
                    199.9413279740883,
                    187.5,
                    199.9413279740883,
                    186.5,
                    200.94158162767556,
                    185.5,
                    201.9418352812628,
                    185.5,
                    201.9418352812628,
                    184.5,
                    202.94208893485003,
                    183.5,
                    202.94208893485003,
                    183.5,
                    202.94208893485003,
                    181.5,
                    202.94208893485003,
                    181.5,
                    202.94208893485003,
                    180.5,
                    202.94208893485003,
                    179.5,
                    202.94208893485003,
                    179.5,
                    202.94208893485003,
                    176.5,
                    202.94208893485003,
                    175.5,
                    202.94208893485003,
                    172.5,
                    202.94208893485003,
                    171.5,
                    202.94208893485003,
                    166.5,
                    202.94208893485003,
                    159.5,
                    202.94208893485003,
                    156.5,
                    202.94208893485003,
                    149.5,
                    202.94208893485003,
                    147.5,
                    202.94208893485003,
                    145.5,
                    202.94208893485003,
                    143.5,
                    202.94208893485003,
                    141.5,
                    201.9418352812628,
                    141.5,
                    200.94158162767556,
                    140.5,
                    200.94158162767556,
                    139.5,
                    200.94158162767556,
                    138.5,
                    200.94158162767556,
                    137.5,
                    199.9413279740883,
                    137.5,
                    199.9413279740883,
                    136.5,
                    198.94107432050106,
                    135.5,
                    197.9408206669138,
                    133.5,
                    196.94056701332656,
                    132.5,
                    196.94056701332656,
                    131.5,
                    194.94005970615206,
                    129.5,
                    194.94005970615206,
                    128.5,
                    191.93929874539032,
                    128.5,
                    191.93929874539032,
                    127.5,
                    191.93929874539032,
                    127.5,
                    190.93904509180308,
                    127.5,
                    189.93879143821582
                ]
            },
            {
                "tool": "pen",
                "points": [
                    260.5,
                    106.91773819047432,
                    260.5,
                    105.91748453688707,
                    260.5,
                    106.91773819047432,
                    260.5,
                    107.91799184406158,
                    259.5,
                    108.91824549764883,
                    259.5,
                    111.91900645841056,
                    259.5,
                    112.91926011199781,
                    258.5,
                    114.9197674191723,
                    258.5,
                    115.92002107275955,
                    258.5,
                    117.92052837993404,
                    257.5,
                    119.92103568710854,
                    257.5,
                    120.92128934069578,
                    257.5,
                    125.92255760863202,
                    257.5,
                    126.92281126221927,
                    256.5,
                    128.92331856939376,
                    256.5,
                    129.92357222298102,
                    255.5,
                    135.9250941445045,
                    255.5,
                    137.92560145167897,
                    254.5,
                    139.92610875885347,
                    254.5,
                    145.92763068037695,
                    254.5,
                    147.92813798755145,
                    254.5,
                    149.92864529472595,
                    254.5,
                    151.92915260190046,
                    252.5,
                    159.93118183059843,
                    252.5,
                    162.93194279136017,
                    251.5,
                    169.93371836647088,
                    250.5,
                    175.93524028799436,
                    250.5,
                    186.93803047745408,
                    250.5,
                    191.93929874539032,
                    250.5,
                    196.94056701332656,
                    250.5,
                    198.94107432050106,
                    250.5,
                    199.9413279740883,
                    250.5,
                    201.9418352812628,
                    250.5,
                    202.94208893485003,
                    250.5,
                    203.9423425884373,
                    250.5,
                    204.94259624202454,
                    250.5,
                    205.94284989561177,
                    250.5,
                    206.94310354919904,
                    250.5,
                    206.94310354919904,
                    250.5,
                    204.94259624202454,
                    250.5,
                    203.9423425884373,
                    250.5,
                    202.94208893485003,
                    250.5,
                    202.94208893485003,
                    249.5,
                    200.94158162767556,
                    249.5,
                    200.94158162767556
                ]
            },
            {
                "tool": "pen",
                "points": [
                    249.5,
                    200.94158162767556,
                    250.5,
                    201.9418352812628,
                    250.5,
                    202.94208893485003,
                    250.5,
                    202.94208893485003,
                    250.5,
                    203.9423425884373,
                    250.5,
                    204.94259624202454,
                    250.5,
                    204.94259624202454,
                    250.5,
                    205.94284989561177,
                    250.5,
                    205.94284989561177,
                    250.5,
                    206.94310354919904,
                    250.5,
                    207.94335720278627,
                    250.5,
                    207.94335720278627,
                    250.5,
                    207.94335720278627,
                    250.5,
                    208.9436108563735,
                    250.5,
                    208.9436108563735,
                    250.5,
                    209.94386450996078,
                    250.5,
                    209.94386450996078,
                    250.5,
                    210.944118163548,
                    250.5,
                    210.944118163548,
                    250.5,
                    211.94437181713528,
                    250.5,
                    211.94437181713528,
                    250.5,
                    212.9446254707225,
                    250.5,
                    212.9446254707225
                ]
            },
            {
                "tool": "pen",
                "points": [
                    259.5,
                    119.92103568710854,
                    259.5,
                    119.92103568710854,
                    259.5,
                    118.92078203352129,
                    259.5,
                    118.92078203352129,
                    259.5,
                    117.92052837993404,
                    259.5,
                    117.92052837993404,
                    259.5,
                    117.92052837993404,
                    259.5,
                    116.9202747263468,
                    259.5,
                    116.9202747263468,
                    259.5,
                    115.92002107275955,
                    259.5,
                    115.92002107275955,
                    259.5,
                    115.92002107275955,
                    259.5,
                    114.9197674191723,
                    259.5,
                    114.9197674191723,
                    259.5,
                    113.91951376558505,
                    259.5,
                    113.91951376558505,
                    259.5,
                    112.91926011199781,
                    259.5,
                    111.91900645841056,
                    259.5,
                    111.91900645841056,
                    259.5,
                    110.91875280482331,
                    259.5,
                    110.91875280482331,
                    259.5,
                    110.91875280482331,
                    259.5,
                    109.91849915123606,
                    259.5,
                    108.91824549764883,
                    259.5,
                    108.91824549764883,
                    259.5,
                    107.91799184406158,
                    259.5,
                    106.91773819047432,
                    259.5,
                    105.91748453688707,
                    259.5,
                    104.91723088329984,
                    259.5,
                    103.91697722971259,
                    259.5,
                    102.91672357612534,
                    259.5,
                    102.91672357612534,
                    259.5,
                    101.9164699225381,
                    259.5,
                    101.9164699225381,
                    259.5,
                    101.9164699225381,
                    259.5,
                    100.91621626895085,
                    259.5,
                    100.91621626895085,
                    259.5,
                    99.9159626153636
                ]
            },
            {
                "tool": "pen",
                "points": [
                    285.5,
                    134.92484049091723,
                    285.5,
                    135.9250941445045,
                    283.5,
                    135.9250941445045,
                    281.5,
                    135.9250941445045,
                    280.5,
                    135.9250941445045,
                    280.5,
                    135.9250941445045,
                    279.5,
                    135.9250941445045,
                    278.5,
                    135.9250941445045,
                    276.5,
                    136.92534779809174,
                    275.5,
                    136.92534779809174,
                    274.5,
                    136.92534779809174,
                    272.5,
                    136.92534779809174,
                    271.5,
                    137.92560145167897,
                    270.5,
                    137.92560145167897,
                    268.5,
                    137.92560145167897,
                    267.5,
                    137.92560145167897,
                    266.5,
                    137.92560145167897,
                    264.5,
                    137.92560145167897,
                    263.5,
                    137.92560145167897,
                    261.5,
                    137.92560145167897,
                    260.5,
                    137.92560145167897,
                    259.5,
                    137.92560145167897,
                    258.5,
                    137.92560145167897,
                    257.5,
                    137.92560145167897,
                    256.5,
                    137.92560145167897,
                    255.5,
                    137.92560145167897,
                    254.5,
                    137.92560145167897,
                    252.5,
                    137.92560145167897,
                    251.5,
                    137.92560145167897,
                    250.5,
                    137.92560145167897,
                    245.5,
                    137.92560145167897,
                    244.5,
                    138.92585510526624,
                    241.5,
                    138.92585510526624,
                    239.5,
                    138.92585510526624,
                    239.5,
                    138.92585510526624,
                    236.5,
                    138.92585510526624,
                    235.5,
                    138.92585510526624,
                    235.5,
                    138.92585510526624,
                    234.5,
                    138.92585510526624,
                    234.5,
                    138.92585510526624,
                    233.5,
                    138.92585510526624,
                    232.5,
                    138.92585510526624,
                    231.5,
                    138.92585510526624,
                    230.5,
                    138.92585510526624,
                    230.5,
                    139.92610875885347,
                    229.5,
                    139.92610875885347,
                    228.5,
                    139.92610875885347,
                    228.5,
                    139.92610875885347,
                    228.5,
                    139.92610875885347,
                    227.5,
                    139.92610875885347,
                    227.5,
                    139.92610875885347,
                    226.5,
                    139.92610875885347,
                    226.5,
                    139.92610875885347,
                    225.5,
                    139.92610875885347,
                    225.5,
                    139.92610875885347
                ]
            },
            {
                "tool": "pen",
                "points": [
                    337.5,
                    155.93016721624943,
                    336.5,
                    155.93016721624943,
                    335.5,
                    155.93016721624943,
                    334.5,
                    155.93016721624943,
                    333.5,
                    155.93016721624943,
                    333.5,
                    155.93016721624943,
                    331.5,
                    155.93016721624943,
                    330.5,
                    155.93016721624943,
                    329.5,
                    155.93016721624943,
                    327.5,
                    155.93016721624943,
                    326.5,
                    155.93016721624943,
                    326.5,
                    155.93016721624943,
                    325.5,
                    155.93016721624943,
                    325.5,
                    155.93016721624943,
                    323.5,
                    155.93016721624943,
                    322.5,
                    155.93016721624943,
                    321.5,
                    155.93016721624943,
                    321.5,
                    155.93016721624943,
                    321.5,
                    155.93016721624943,
                    320.5,
                    155.93016721624943,
                    320.5,
                    155.93016721624943,
                    319.5,
                    155.93016721624943,
                    318.5,
                    155.93016721624943,
                    317.5,
                    155.93016721624943,
                    317.5,
                    156.93042086983667,
                    317.5,
                    157.93067452342393,
                    316.5,
                    157.93067452342393,
                    316.5,
                    158.93092817701117,
                    314.5,
                    158.93092817701117,
                    313.5,
                    158.93092817701117,
                    313.5,
                    159.93118183059843,
                    312.5,
                    159.93118183059843,
                    309.5,
                    162.93194279136017,
                    308.5,
                    163.9321964449474,
                    307.5,
                    164.93245009853464,
                    305.5,
                    165.9327037521219,
                    305.5,
                    166.93295740570915,
                    304.5,
                    167.93321105929638,
                    303.5,
                    168.93346471288365,
                    303.5,
                    169.93371836647088,
                    302.5,
                    170.93397202005815,
                    301.5,
                    171.9342256736454,
                    301.5,
                    172.93447932723262,
                    301.5,
                    173.9347329808199,
                    301.5,
                    174.93498663440712,
                    301.5,
                    175.93524028799436,
                    300.5,
                    176.93549394158163,
                    300.5,
                    177.93574759516886,
                    300.5,
                    178.9360012487561,
                    300.5,
                    179.93625490234336,
                    300.5,
                    180.9365085559306,
                    300.5,
                    181.93676220951787,
                    300.5,
                    182.9370158631051,
                    300.5,
                    182.9370158631051,
                    300.5,
                    183.93726951669234,
                    300.5,
                    184.9375231702796,
                    300.5,
                    185.93777682386684,
                    299.5,
                    186.93803047745408,
                    299.5,
                    187.93828413104134,
                    299.5,
                    188.93853778462858,
                    299.5,
                    188.93853778462858,
                    299.5,
                    189.93879143821582,
                    299.5,
                    190.93904509180308,
                    299.5,
                    191.93929874539032,
                    299.5,
                    192.93955239897758,
                    299.5,
                    194.94005970615206,
                    299.5,
                    195.94031335973932,
                    299.5,
                    196.94056701332656,
                    299.5,
                    197.9408206669138,
                    299.5,
                    198.94107432050106,
                    299.5,
                    198.94107432050106,
                    299.5,
                    199.9413279740883,
                    299.5,
                    200.94158162767556,
                    299.5,
                    200.94158162767556,
                    300.5,
                    202.94208893485003,
                    301.5,
                    203.9423425884373,
                    302.5,
                    204.94259624202454,
                    302.5,
                    205.94284989561177,
                    304.5,
                    205.94284989561177,
                    304.5,
                    205.94284989561177,
                    305.5,
                    205.94284989561177,
                    306.5,
                    205.94284989561177,
                    307.5,
                    206.94310354919904,
                    308.5,
                    206.94310354919904,
                    309.5,
                    207.94335720278627,
                    310.5,
                    207.94335720278627,
                    311.5,
                    207.94335720278627,
                    311.5,
                    207.94335720278627,
                    313.5,
                    207.94335720278627,
                    314.5,
                    207.94335720278627,
                    315.5,
                    208.9436108563735,
                    316.5,
                    208.9436108563735,
                    316.5,
                    208.9436108563735,
                    317.5,
                    208.9436108563735,
                    318.5,
                    208.9436108563735,
                    318.5,
                    208.9436108563735,
                    319.5,
                    208.9436108563735,
                    322.5,
                    208.9436108563735,
                    323.5,
                    208.9436108563735,
                    325.5,
                    208.9436108563735,
                    327.5,
                    208.9436108563735,
                    328.5,
                    208.9436108563735,
                    330.5,
                    208.9436108563735,
                    330.5,
                    208.9436108563735,
                    332.5,
                    207.94335720278627,
                    333.5,
                    207.94335720278627,
                    334.5,
                    207.94335720278627,
                    336.5,
                    206.94310354919904,
                    337.5,
                    205.94284989561177,
                    338.5,
                    204.94259624202454,
                    340.5,
                    203.9423425884373,
                    341.5,
                    202.94208893485003,
                    343.5,
                    201.9418352812628,
                    344.5,
                    199.9413279740883,
                    345.5,
                    198.94107432050106,
                    346.5,
                    197.9408206669138,
                    347.5,
                    196.94056701332656,
                    348.5,
                    195.94031335973932,
                    348.5,
                    194.94005970615206,
                    349.5,
                    192.93955239897758,
                    350.5,
                    190.93904509180308,
                    351.5,
                    188.93853778462858,
                    352.5,
                    185.93777682386684,
                    353.5,
                    184.9375231702796,
                    353.5,
                    183.93726951669234,
                    353.5,
                    179.93625490234336,
                    354.5,
                    178.9360012487561,
                    354.5,
                    178.9360012487561,
                    354.5,
                    175.93524028799436,
                    354.5,
                    174.93498663440712,
                    354.5,
                    173.9347329808199,
                    354.5,
                    172.93447932723262,
                    354.5,
                    171.9342256736454,
                    354.5,
                    171.9342256736454,
                    354.5,
                    170.93397202005815,
                    354.5,
                    169.93371836647088,
                    354.5,
                    168.93346471288365,
                    354.5,
                    167.93321105929638,
                    354.5,
                    166.93295740570915,
                    354.5,
                    165.9327037521219,
                    354.5,
                    164.93245009853464,
                    354.5,
                    163.9321964449474,
                    354.5,
                    163.9321964449474,
                    354.5,
                    161.9316891377729,
                    354.5,
                    161.9316891377729,
                    353.5,
                    160.93143548418567,
                    352.5,
                    160.93143548418567,
                    352.5,
                    159.93118183059843,
                    350.5,
                    158.93092817701117,
                    349.5,
                    158.93092817701117,
                    349.5,
                    158.93092817701117,
                    348.5,
                    158.93092817701117,
                    346.5,
                    158.93092817701117,
                    346.5,
                    158.93092817701117,
                    345.5,
                    157.93067452342393
                ]
            },
            {
                "tool": "pen",
                "points": [
                    346.5,
                    157.93067452342393,
                    347.5,
                    157.93067452342393,
                    347.5,
                    157.93067452342393,
                    348.5,
                    158.93092817701117,
                    348.5,
                    159.93118183059843,
                    349.5,
                    159.93118183059843,
                    349.5,
                    159.93118183059843,
                    350.5,
                    159.93118183059843,
                    350.5,
                    160.93143548418567,
                    351.5,
                    161.9316891377729,
                    351.5,
                    161.9316891377729,
                    351.5,
                    162.93194279136017,
                    352.5,
                    162.93194279136017,
                    352.5,
                    163.9321964449474,
                    352.5,
                    163.9321964449474,
                    353.5,
                    164.93245009853464,
                    354.5,
                    165.9327037521219,
                    354.5,
                    166.93295740570915,
                    355.5,
                    167.93321105929638,
                    355.5,
                    169.93371836647088,
                    355.5,
                    170.93397202005815,
                    356.5,
                    173.9347329808199,
                    356.5,
                    173.9347329808199,
                    356.5,
                    174.93498663440712,
                    356.5,
                    175.93524028799436,
                    356.5,
                    175.93524028799436,
                    356.5,
                    178.9360012487561,
                    356.5,
                    179.93625490234336,
                    356.5,
                    179.93625490234336,
                    356.5,
                    180.9365085559306,
                    356.5,
                    181.93676220951787,
                    356.5,
                    181.93676220951787,
                    356.5,
                    182.9370158631051,
                    356.5,
                    183.93726951669234,
                    356.5,
                    183.93726951669234,
                    356.5,
                    184.9375231702796,
                    356.5,
                    185.93777682386684,
                    356.5,
                    185.93777682386684,
                    356.5,
                    186.93803047745408,
                    357.5,
                    187.93828413104134,
                    357.5,
                    188.93853778462858,
                    357.5,
                    188.93853778462858,
                    357.5,
                    188.93853778462858,
                    357.5,
                    189.93879143821582,
                    358.5,
                    189.93879143821582,
                    359.5,
                    190.93904509180308,
                    359.5,
                    193.93980605256482,
                    360.5,
                    193.93980605256482,
                    361.5,
                    194.94005970615206,
                    361.5,
                    195.94031335973932,
                    362.5,
                    196.94056701332656,
                    363.5,
                    197.9408206669138,
                    363.5,
                    197.9408206669138,
                    364.5,
                    198.94107432050106,
                    364.5,
                    199.9413279740883,
                    364.5,
                    199.9413279740883,
                    364.5,
                    200.94158162767556,
                    364.5,
                    201.9418352812628,
                    365.5,
                    202.94208893485003,
                    366.5,
                    202.94208893485003,
                    367.5,
                    202.94208893485003,
                    367.5,
                    203.9423425884373,
                    368.5,
                    204.94259624202454,
                    368.5,
                    205.94284989561177,
                    369.5,
                    206.94310354919904,
                    372.5,
                    210.944118163548,
                    373.5,
                    211.94437181713528,
                    373.5,
                    212.9446254707225,
                    373.5,
                    212.9446254707225,
                    374.5,
                    213.94487912430975
                ]
            },
            {
                "tool": "pen",
                "points": [
                    419.5,
                    158.93092817701117,
                    419.5,
                    159.93118183059843,
                    419.5,
                    161.9316891377729,
                    419.5,
                    162.93194279136017,
                    419.5,
                    164.93245009853464,
                    419.5,
                    165.9327037521219,
                    419.5,
                    166.93295740570915,
                    419.5,
                    167.93321105929638,
                    419.5,
                    169.93371836647088,
                    420.5,
                    171.9342256736454,
                    420.5,
                    172.93447932723262,
                    420.5,
                    173.9347329808199,
                    420.5,
                    173.9347329808199,
                    420.5,
                    175.93524028799436,
                    420.5,
                    178.9360012487561,
                    420.5,
                    179.93625490234336,
                    420.5,
                    179.93625490234336,
                    422.5,
                    182.9370158631051,
                    422.5,
                    182.9370158631051,
                    422.5,
                    184.9375231702796,
                    422.5,
                    184.9375231702796,
                    423.5,
                    189.93879143821582,
                    423.5,
                    189.93879143821582,
                    424.5,
                    192.93955239897758,
                    424.5,
                    192.93955239897758,
                    425.5,
                    194.94005970615206,
                    425.5,
                    195.94031335973932,
                    425.5,
                    197.9408206669138,
                    425.5,
                    197.9408206669138,
                    425.5,
                    199.9413279740883,
                    425.5,
                    200.94158162767556,
                    425.5,
                    201.9418352812628,
                    425.5,
                    202.94208893485003,
                    425.5,
                    204.94259624202454,
                    426.5,
                    205.94284989561177,
                    426.5,
                    206.94310354919904,
                    426.5,
                    207.94335720278627,
                    426.5,
                    208.9436108563735,
                    426.5,
                    209.94386450996078,
                    426.5,
                    210.944118163548,
                    425.5,
                    211.94437181713528,
                    425.5,
                    212.9446254707225,
                    425.5,
                    212.9446254707225,
                    425.5,
                    213.94487912430975,
                    425.5,
                    214.94513277789702,
                    425.5,
                    215.94538643148425,
                    425.5,
                    216.9456400850715,
                    425.5,
                    216.9456400850715,
                    425.5,
                    216.9456400850715,
                    425.5,
                    215.94538643148425,
                    425.5,
                    213.94487912430975,
                    425.5,
                    210.944118163548,
                    425.5,
                    209.94386450996078,
                    425.5,
                    207.94335720278627,
                    425.5,
                    206.94310354919904,
                    425.5,
                    204.94259624202454,
                    425.5,
                    202.94208893485003,
                    425.5,
                    201.9418352812628,
                    425.5,
                    200.94158162767556,
                    425.5,
                    199.9413279740883,
                    425.5,
                    198.94107432050106,
                    425.5,
                    197.9408206669138,
                    425.5,
                    196.94056701332656,
                    425.5,
                    194.94005970615206,
                    425.5,
                    193.93980605256482,
                    425.5,
                    190.93904509180308,
                    426.5,
                    189.93879143821582,
                    426.5,
                    187.93828413104134,
                    427.5,
                    186.93803047745408,
                    427.5,
                    185.93777682386684,
                    427.5,
                    185.93777682386684,
                    427.5,
                    183.93726951669234,
                    427.5,
                    182.9370158631051,
                    427.5,
                    180.9365085559306,
                    427.5,
                    179.93625490234336,
                    428.5,
                    178.9360012487561,
                    428.5,
                    177.93574759516886,
                    428.5,
                    176.93549394158163,
                    428.5,
                    176.93549394158163,
                    429.5,
                    175.93524028799436,
                    429.5,
                    173.9347329808199,
                    429.5,
                    173.9347329808199,
                    429.5,
                    172.93447932723262,
                    429.5,
                    172.93447932723262,
                    430.5,
                    171.9342256736454,
                    430.5,
                    171.9342256736454,
                    430.5,
                    170.93397202005815,
                    430.5,
                    170.93397202005815,
                    431.5,
                    170.93397202005815,
                    431.5,
                    169.93371836647088,
                    432.5,
                    168.93346471288365,
                    433.5,
                    168.93346471288365,
                    433.5,
                    166.93295740570915,
                    434.5,
                    165.9327037521219,
                    435.5,
                    164.93245009853464,
                    437.5,
                    163.9321964449474,
                    438.5,
                    162.93194279136017,
                    439.5,
                    161.9316891377729,
                    440.5,
                    160.93143548418567,
                    442.5,
                    159.93118183059843,
                    443.5,
                    158.93092817701117,
                    445.5,
                    157.93067452342393,
                    446.5,
                    157.93067452342393,
                    447.5,
                    156.93042086983667,
                    448.5,
                    156.93042086983667,
                    448.5,
                    156.93042086983667,
                    450.5,
                    156.93042086983667,
                    451.5,
                    155.93016721624943,
                    451.5,
                    155.93016721624943,
                    452.5,
                    155.93016721624943,
                    453.5,
                    155.93016721624943,
                    455.5,
                    155.93016721624943,
                    457.5,
                    155.93016721624943,
                    457.5,
                    155.93016721624943,
                    458.5,
                    155.93016721624943,
                    459.5,
                    155.93016721624943
                ]
            },
            {
                "tool": "pen",
                "points": [
                    426.5,
                    199.9413279740883,
                    426.5,
                    199.9413279740883,
                    426.5,
                    200.94158162767556,
                    426.5,
                    200.94158162767556,
                    426.5,
                    202.94208893485003,
                    426.5,
                    202.94208893485003,
                    426.5,
                    203.9423425884373,
                    426.5,
                    204.94259624202454,
                    425.5,
                    205.94284989561177,
                    425.5,
                    206.94310354919904,
                    425.5,
                    207.94335720278627,
                    425.5,
                    208.9436108563735,
                    424.5,
                    210.944118163548,
                    424.5,
                    211.94437181713528,
                    424.5,
                    212.9446254707225,
                    424.5,
                    213.94487912430975,
                    424.5,
                    214.94513277789702,
                    424.5,
                    214.94513277789702,
                    424.5,
                    215.94538643148425,
                    423.5,
                    217.94589373865875,
                    423.5,
                    218.946147392246,
                    423.5,
                    219.94640104583323,
                    423.5,
                    219.94640104583323,
                    423.5,
                    220.9466546994205
                ]
            },
            {
                "tool": "pen",
                "points": [
                    514.5,
                    105.91748453688707,
                    514.5,
                    106.91773819047432,
                    514.5,
                    114.9197674191723,
                    514.5,
                    120.92128934069578,
                    514.5,
                    125.92255760863202,
                    514.5,
                    128.92331856939376,
                    513.5,
                    135.9250941445045,
                    511.5,
                    145.92763068037695,
                    510.5,
                    153.92965990907493,
                    508.5,
                    162.93194279136017,
                    508.5,
                    165.9327037521219,
                    507.5,
                    170.93397202005815,
                    506.5,
                    172.93447932723262,
                    506.5,
                    174.93498663440712,
                    506.5,
                    176.93549394158163,
                    506.5,
                    179.93625490234336,
                    506.5,
                    181.93676220951787,
                    506.5,
                    183.93726951669234,
                    505.5,
                    184.9375231702796,
                    505.5,
                    188.93853778462858,
                    505.5,
                    189.93879143821582,
                    505.5,
                    190.93904509180308,
                    505.5,
                    190.93904509180308,
                    505.5,
                    193.93980605256482,
                    505.5,
                    194.94005970615206,
                    505.5,
                    196.94056701332656,
                    505.5,
                    199.9413279740883,
                    505.5,
                    200.94158162767556,
                    505.5,
                    201.9418352812628,
                    505.5,
                    203.9423425884373,
                    505.5,
                    204.94259624202454,
                    505.5,
                    207.94335720278627,
                    505.5,
                    208.9436108563735,
                    505.5,
                    209.94386450996078,
                    505.5,
                    212.9446254707225,
                    505.5,
                    213.94487912430975,
                    505.5,
                    214.94513277789702,
                    505.5,
                    214.94513277789702,
                    505.5,
                    215.94538643148425,
                    505.5,
                    215.94538643148425,
                    505.5,
                    216.9456400850715,
                    505.5,
                    217.94589373865875,
                    505.5,
                    221.94690835300773
                ]
            },
            {
                "tool": "pen",
                "points": [
                    561.5,
                    134.92484049091723,
                    561.5,
                    134.92484049091723,
                    560.5,
                    135.9250941445045,
                    558.5,
                    135.9250941445045,
                    556.5,
                    136.92534779809174,
                    552.5,
                    137.92560145167897,
                    550.5,
                    137.92560145167897,
                    549.5,
                    137.92560145167897,
                    548.5,
                    137.92560145167897,
                    546.5,
                    137.92560145167897,
                    544.5,
                    137.92560145167897,
                    543.5,
                    137.92560145167897,
                    540.5,
                    137.92560145167897,
                    540.5,
                    137.92560145167897,
                    538.5,
                    137.92560145167897,
                    536.5,
                    137.92560145167897,
                    535.5,
                    138.92585510526624,
                    534.5,
                    138.92585510526624,
                    534.5,
                    138.92585510526624,
                    532.5,
                    138.92585510526624,
                    531.5,
                    138.92585510526624,
                    530.5,
                    138.92585510526624,
                    530.5,
                    138.92585510526624,
                    529.5,
                    138.92585510526624,
                    527.5,
                    138.92585510526624,
                    527.5,
                    138.92585510526624,
                    526.5,
                    138.92585510526624,
                    525.5,
                    138.92585510526624,
                    524.5,
                    138.92585510526624,
                    523.5,
                    138.92585510526624,
                    522.5,
                    138.92585510526624,
                    521.5,
                    138.92585510526624,
                    520.5,
                    138.92585510526624,
                    519.5,
                    138.92585510526624,
                    518.5,
                    138.92585510526624,
                    517.5,
                    138.92585510526624,
                    516.5,
                    138.92585510526624,
                    515.5,
                    138.92585510526624,
                    512.5,
                    138.92585510526624,
                    511.5,
                    138.92585510526624,
                    510.5,
                    138.92585510526624,
                    507.5,
                    138.92585510526624,
                    506.5,
                    138.92585510526624,
                    505.5,
                    138.92585510526624,
                    502.5,
                    138.92585510526624,
                    499.5,
                    139.92610875885347,
                    497.5,
                    139.92610875885347,
                    497.5,
                    139.92610875885347,
                    494.5,
                    139.92610875885347,
                    493.5,
                    139.92610875885347,
                    492.5,
                    139.92610875885347,
                    492.5,
                    139.92610875885347,
                    491.5,
                    139.92610875885347,
                    490.5,
                    139.92610875885347,
                    488.5,
                    139.92610875885347,
                    487.5,
                    139.92610875885347,
                    486.5,
                    139.92610875885347,
                    485.5,
                    139.92610875885347,
                    484.5,
                    139.92610875885347,
                    484.5,
                    139.92610875885347,
                    482.5,
                    139.92610875885347,
                    481.5,
                    139.92610875885347,
                    481.5,
                    139.92610875885347,
                    480.5,
                    139.92610875885347,
                    480.5,
                    139.92610875885347,
                    479.5,
                    139.92610875885347,
                    479.5,
                    139.92610875885347,
                    478.5,
                    139.92610875885347,
                    477.5,
                    139.92610875885347,
                    477.5,
                    139.92610875885347,
                    477.5,
                    139.92610875885347
                ]
            },
            {
                "tool": "pen",
                "points": [
                    64.5,
                    249.953095684803,
                    64.5,
                    249.953095684803,
                    64.5,
                    249.953095684803,
                    64.5,
                    250.9537210756723,
                    64.5,
                    250.9537210756723,
                    64.5,
                    252.95497185741087,
                    64.5,
                    253.95559724828018,
                    64.5,
                    253.95559724828018,
                    64.5,
                    254.95622263914947,
                    64.5,
                    255.95684803001876,
                    64.5,
                    255.95684803001876,
                    64.5,
                    256.95747342088805,
                    64.5,
                    257.95809881175734,
                    64.5,
                    257.95809881175734,
                    64.5,
                    258.9587242026266,
                    64.5,
                    260.9599749843652,
                    64.5,
                    260.9599749843652,
                    64.5,
                    261.9606003752345,
                    64.5,
                    262.96122576610384,
                    64.5,
                    262.96122576610384,
                    64.5,
                    263.9618511569731,
                    64.5,
                    263.9618511569731,
                    64.5,
                    264.9624765478424,
                    64.5,
                    265.9631019387117,
                    64.5,
                    265.9631019387117,
                    64.5,
                    266.963727329581,
                    63.5,
                    268.96497811131957,
                    63.5,
                    268.96497811131957,
                    63.5,
                    269.96560350218886,
                    63.5,
                    270.96622889305814,
                    63.5,
                    270.96622889305814,
                    63.5,
                    271.96685428392743,
                    63.5,
                    271.96685428392743,
                    62.5,
                    272.9674796747967,
                    62.5,
                    272.9674796747967,
                    62.5,
                    273.96810506566607,
                    62.5,
                    275.96935584740464,
                    62.5,
                    276.96998123827393,
                    62.5,
                    276.96998123827393,
                    62.5,
                    277.9706066291432,
                    62.5,
                    278.9712320200125,
                    62.5,
                    279.9718574108818,
                    62.5,
                    279.9718574108818,
                    62.5,
                    281.9731081926204,
                    62.5,
                    282.97373358348966,
                    61.5,
                    284.97498436522824,
                    60.5,
                    285.9756097560976,
                    60.5,
                    286.97623514696687,
                    60.5,
                    287.97686053783616,
                    60.5,
                    289.97811131957474,
                    60.5,
                    293.9806128830519,
                    60.5,
                    295.98186366479047,
                    60.5,
                    296.98248905565976,
                    60.5,
                    298.9837398373984,
                    60.5,
                    299.9843652282677,
                    59.5,
                    300.98499061913697,
                    59.5,
                    302.98624140087554,
                    59.5,
                    305.9881175734834,
                    59.5,
                    306.9887429643527,
                    59.5,
                    308.98999374609133,
                    59.5,
                    310.9912445278299,
                    59.5,
                    312.9924953095685,
                    59.5,
                    313.99312070043777,
                    59.5,
                    317.9956222639149,
                    59.5,
                    319.9968730456535,
                    59.5,
                    323.9993746091307,
                    59.5,
                    326.0006253908693,
                    59.5,
                    331.00375234521573,
                    59.5,
                    335.00625390869294,
                    59.5,
                    337.0075046904315,
                    59.5,
                    339.0087554721701,
                    59.5,
                    341.01000625390867,
                    59.5,
                    344.0118824265166,
                    59.5,
                    345.0125078173859,
                    59.5,
                    347.01375859912446,
                    59.5,
                    348.01438398999375,
                    59.5,
                    349.01500938086303,
                    59.5,
                    349.01500938086303,
                    59.5,
                    350.0156347717323,
                    59.5,
                    351.0162601626016,
                    59.5,
                    352.0168855534709,
                    59.5,
                    353.0175109443402,
                    59.5,
                    354.0181363352095,
                    59.5,
                    354.0181363352095,
                    59.5,
                    355.0187617260788,
                    59.5,
                    357.0200125078174,
                    59.5,
                    358.0206378986867,
                    59.5,
                    358.0206378986867,
                    59.5,
                    359.021263289556,
                    58.5,
                    361.02251407129455,
                    58.5,
                    362.02313946216384,
                    58.5,
                    363.02376485303313,
                    58.5,
                    363.02376485303313,
                    58.5,
                    363.02376485303313,
                    58.5,
                    364.0243902439024,
                    58.5,
                    364.0243902439024
                ]
            },
            {
                "tool": "pen",
                "points": [
                    63.5,
                    242.94871794871796,
                    63.5,
                    243.94934333958724,
                    63.5,
                    243.94934333958724,
                    63.5,
                    243.94934333958724,
                    64.5,
                    243.94934333958724,
                    65.5,
                    243.94934333958724,
                    65.5,
                    243.94934333958724,
                    66.5,
                    243.94934333958724,
                    67.5,
                    242.94871794871796,
                    67.5,
                    242.94871794871796,
                    68.5,
                    242.94871794871796,
                    69.5,
                    242.94871794871796,
                    70.5,
                    242.94871794871796,
                    70.5,
                    242.94871794871796,
                    71.5,
                    242.94871794871796,
                    72.5,
                    242.94871794871796,
                    73.5,
                    242.94871794871796,
                    73.5,
                    242.94871794871796,
                    74.5,
                    241.94809255784864,
                    75.5,
                    241.94809255784864,
                    77.5,
                    241.94809255784864,
                    77.5,
                    241.94809255784864,
                    78.5,
                    241.94809255784864,
                    80.5,
                    241.94809255784864,
                    81.5,
                    241.94809255784864,
                    82.5,
                    241.94809255784864,
                    83.5,
                    241.94809255784864,
                    85.5,
                    241.94809255784864,
                    86.5,
                    241.94809255784864,
                    87.5,
                    241.94809255784864,
                    89.5,
                    241.94809255784864,
                    89.5,
                    241.94809255784864,
                    90.5,
                    241.94809255784864,
                    91.5,
                    241.94809255784864,
                    92.5,
                    241.94809255784864,
                    92.5,
                    241.94809255784864,
                    94.5,
                    241.94809255784864,
                    96.5,
                    242.94871794871796,
                    96.5,
                    242.94871794871796,
                    98.5,
                    243.94934333958724,
                    99.5,
                    243.94934333958724,
                    100.5,
                    243.94934333958724,
                    102.5,
                    244.94996873045653,
                    102.5,
                    245.95059412132582,
                    103.5,
                    245.95059412132582,
                    104.5,
                    246.9512195121951,
                    104.5,
                    246.9512195121951,
                    105.5,
                    246.9512195121951,
                    107.5,
                    247.9518449030644,
                    107.5,
                    247.9518449030644,
                    108.5,
                    248.95247029393371,
                    110.5,
                    248.95247029393371,
                    111.5,
                    249.953095684803,
                    112.5,
                    249.953095684803,
                    112.5,
                    249.953095684803,
                    113.5,
                    250.9537210756723,
                    113.5,
                    250.9537210756723,
                    114.5,
                    251.95434646654158,
                    114.5,
                    251.95434646654158,
                    117.5,
                    254.95622263914947,
                    118.5,
                    255.95684803001876,
                    118.5,
                    255.95684803001876,
                    119.5,
                    256.95747342088805,
                    120.5,
                    256.95747342088805,
                    120.5,
                    257.95809881175734,
                    120.5,
                    258.9587242026266,
                    120.5,
                    258.9587242026266,
                    120.5,
                    259.9593495934959,
                    122.5,
                    260.9599749843652,
                    123.5,
                    264.9624765478424,
                    123.5,
                    265.9631019387117,
                    124.5,
                    265.9631019387117,
                    124.5,
                    266.963727329581,
                    125.5,
                    268.96497811131957,
                    125.5,
                    268.96497811131957,
                    125.5,
                    268.96497811131957,
                    125.5,
                    269.96560350218886,
                    126.5,
                    270.96622889305814,
                    127.5,
                    270.96622889305814,
                    127.5,
                    271.96685428392743,
                    127.5,
                    273.96810506566607,
                    127.5,
                    273.96810506566607,
                    127.5,
                    274.96873045653535,
                    127.5,
                    275.96935584740464,
                    128.5,
                    276.96998123827393,
                    128.5,
                    276.96998123827393,
                    128.5,
                    278.9712320200125,
                    128.5,
                    278.9712320200125,
                    128.5,
                    279.9718574108818,
                    128.5,
                    281.9731081926204,
                    131.5,
                    285.9756097560976,
                    132.5,
                    286.97623514696687,
                    132.5,
                    287.97686053783616,
                    132.5,
                    288.97748592870545,
                    132.5,
                    289.97811131957474,
                    132.5,
                    294.9812382739212,
                    132.5,
                    296.98248905565976,
                    132.5,
                    300.98499061913697,
                    133.5,
                    301.98561601000625,
                    133.5,
                    305.9881175734834,
                    133.5,
                    307.989368355222,
                    133.5,
                    311.9918699186992,
                    133.5,
                    312.9924953095685,
                    133.5,
                    314.99374609130706,
                    133.5,
                    316.99499687304564,
                    134.5,
                    318.9962476547842,
                    134.5,
                    319.9968730456535,
                    134.5,
                    319.9968730456535,
                    134.5,
                    321.99812382739213,
                    134.5,
                    323.9993746091307,
                    134.5,
                    326.0006253908693,
                    134.5,
                    328.00187617260787,
                    134.5,
                    328.00187617260787,
                    134.5,
                    329.00250156347715,
                    134.5,
                    330.00312695434644,
                    134.5,
                    331.00375234521573,
                    134.5,
                    332.0043777360851,
                    134.5,
                    332.0043777360851,
                    134.5,
                    333.00500312695436,
                    134.5,
                    335.00625390869294,
                    134.5,
                    336.00687929956223,
                    134.5,
                    337.0075046904315,
                    134.5,
                    337.0075046904315,
                    134.5,
                    338.0081300813008,
                    134.5,
                    338.0081300813008,
                    134.5,
                    339.0087554721701,
                    134.5,
                    339.0087554721701,
                    134.5,
                    340.0093808630394,
                    133.5,
                    341.01000625390867,
                    133.5,
                    341.01000625390867,
                    133.5,
                    342.01063164477796,
                    133.5,
                    343.01125703564725,
                    132.5,
                    343.01125703564725,
                    132.5,
                    345.0125078173859,
                    131.5,
                    346.01313320825517,
                    130.5,
                    346.01313320825517,
                    130.5,
                    347.01375859912446,
                    130.5,
                    347.01375859912446,
                    129.5,
                    348.01438398999375,
                    128.5,
                    349.01500938086303,
                    128.5,
                    349.01500938086303,
                    128.5,
                    350.0156347717323,
                    128.5,
                    351.0162601626016,
                    127.5,
                    351.0162601626016,
                    127.5,
                    351.0162601626016,
                    126.5,
                    351.0162601626016,
                    126.5,
                    352.0168855534709,
                    125.5,
                    352.0168855534709,
                    125.5,
                    353.0175109443402,
                    124.5,
                    353.0175109443402,
                    124.5,
                    353.0175109443402,
                    123.5,
                    353.0175109443402,
                    119.5,
                    353.0175109443402,
                    119.5,
                    354.0181363352095,
                    119.5,
                    354.0181363352095,
                    118.5,
                    355.0187617260788,
                    117.5,
                    355.0187617260788,
                    117.5,
                    355.0187617260788,
                    117.5,
                    355.0187617260788,
                    116.5,
                    356.0193871169481,
                    115.5,
                    357.0200125078174,
                    114.5,
                    357.0200125078174,
                    114.5,
                    357.0200125078174,
                    113.5,
                    357.0200125078174,
                    112.5,
                    358.0206378986867,
                    112.5,
                    358.0206378986867,
                    111.5,
                    358.0206378986867,
                    111.5,
                    358.0206378986867,
                    109.5,
                    359.021263289556,
                    108.5,
                    359.021263289556,
                    104.5,
                    360.02188868042526,
                    104.5,
                    360.02188868042526,
                    103.5,
                    360.02188868042526,
                    103.5,
                    361.02251407129455,
                    102.5,
                    361.02251407129455,
                    101.5,
                    361.02251407129455,
                    100.5,
                    361.02251407129455,
                    99.5,
                    362.02313946216384,
                    99.5,
                    362.02313946216384,
                    99.5,
                    362.02313946216384,
                    98.5,
                    362.02313946216384,
                    96.5,
                    362.02313946216384,
                    96.5,
                    362.02313946216384,
                    95.5,
                    363.02376485303313,
                    95.5,
                    363.02376485303313,
                    94.5,
                    363.02376485303313,
                    90.5,
                    363.02376485303313,
                    89.5,
                    363.02376485303313,
                    89.5,
                    364.0243902439024,
                    88.5,
                    364.0243902439024,
                    88.5,
                    365.0250156347717,
                    87.5,
                    365.0250156347717,
                    86.5,
                    365.0250156347717,
                    86.5,
                    365.0250156347717,
                    85.5,
                    365.0250156347717,
                    84.5,
                    365.0250156347717,
                    83.5,
                    365.0250156347717,
                    82.5,
                    365.0250156347717,
                    81.5,
                    365.0250156347717,
                    81.5,
                    365.0250156347717,
                    80.5,
                    365.0250156347717,
                    78.5,
                    365.0250156347717,
                    78.5,
                    365.0250156347717,
                    77.5,
                    365.0250156347717,
                    77.5,
                    366.025641025641,
                    76.5,
                    366.025641025641,
                    76.5,
                    366.025641025641,
                    75.5,
                    366.025641025641,
                    74.5,
                    366.025641025641,
                    73.5,
                    367.02626641651034,
                    72.5,
                    367.02626641651034,
                    71.5,
                    367.02626641651034,
                    71.5,
                    367.02626641651034,
                    70.5,
                    367.02626641651034,
                    70.5,
                    367.02626641651034,
                    69.5,
                    367.02626641651034,
                    69.5,
                    367.02626641651034,
                    68.5,
                    367.02626641651034,
                    68.5,
                    367.02626641651034,
                    67.5,
                    367.02626641651034,
                    65.5,
                    367.02626641651034,
                    65.5,
                    367.02626641651034,
                    64.5,
                    367.02626641651034,
                    64.5,
                    366.025641025641,
                    64.5,
                    366.025641025641
                ]
            },
            {
                "tool": "pen",
                "points": [
                    178.5,
                    298.9837398373984,
                    178.5,
                    298.9837398373984,
                    178.5,
                    298.9837398373984,
                    178.5,
                    299.9843652282677,
                    178.5,
                    300.98499061913697,
                    178.5,
                    301.98561601000625,
                    178.5,
                    302.98624140087554,
                    178.5,
                    303.98686679174483,
                    178.5,
                    304.9874921826141,
                    178.5,
                    305.9881175734834,
                    178.5,
                    306.9887429643527,
                    178.5,
                    307.989368355222,
                    178.5,
                    309.9906191369606,
                    178.5,
                    310.9912445278299,
                    178.5,
                    311.9918699186992,
                    178.5,
                    312.9924953095685,
                    178.5,
                    313.99312070043777,
                    178.5,
                    313.99312070043777,
                    178.5,
                    314.99374609130706,
                    178.5,
                    314.99374609130706,
                    178.5,
                    315.99437148217635,
                    178.5,
                    316.99499687304564,
                    178.5,
                    317.9956222639149,
                    178.5,
                    317.9956222639149,
                    178.5,
                    318.9962476547842,
                    178.5,
                    318.9962476547842,
                    178.5,
                    319.9968730456535,
                    178.5,
                    320.99749843652285,
                    178.5,
                    321.99812382739213,
                    178.5,
                    322.9987492182614,
                    178.5,
                    327.0012507817386,
                    178.5,
                    329.00250156347715,
                    178.5,
                    330.00312695434644,
                    178.5,
                    332.0043777360851,
                    178.5,
                    333.00500312695436,
                    178.5,
                    334.00562851782365,
                    178.5,
                    335.00625390869294,
                    178.5,
                    339.0087554721701,
                    178.5,
                    341.01000625390867,
                    178.5,
                    344.0118824265166,
                    178.5,
                    345.0125078173859,
                    178.5,
                    349.01500938086303,
                    177.5,
                    355.0187617260788,
                    177.5,
                    357.0200125078174,
                    176.5,
                    358.0206378986867,
                    176.5,
                    360.02188868042526,
                    176.5,
                    362.02313946216384,
                    176.5,
                    363.02376485303313,
                    176.5,
                    364.0243902439024,
                    175.5,
                    364.0243902439024,
                    175.5,
                    364.0243902439024,
                    175.5,
                    365.0250156347717,
                    175.5,
                    365.0250156347717,
                    175.5,
                    365.0250156347717,
                    175.5,
                    364.0243902439024,
                    175.5,
                    362.02313946216384,
                    175.5,
                    361.02251407129455,
                    175.5,
                    361.02251407129455,
                    176.5,
                    360.02188868042526,
                    176.5,
                    359.021263289556,
                    176.5,
                    358.0206378986867,
                    176.5,
                    356.0193871169481,
                    176.5,
                    355.0187617260788,
                    177.5,
                    355.0187617260788,
                    177.5,
                    354.0181363352095,
                    177.5,
                    353.0175109443402,
                    177.5,
                    353.0175109443402,
                    177.5,
                    351.0162601626016,
                    177.5,
                    351.0162601626016,
                    177.5,
                    349.01500938086303,
                    177.5,
                    348.01438398999375,
                    177.5,
                    348.01438398999375,
                    177.5,
                    347.01375859912446,
                    177.5,
                    347.01375859912446,
                    177.5,
                    346.01313320825517,
                    177.5,
                    346.01313320825517,
                    178.5,
                    346.01313320825517,
                    178.5,
                    345.0125078173859,
                    178.5,
                    344.0118824265166,
                    178.5,
                    344.0118824265166,
                    178.5,
                    343.01125703564725,
                    178.5,
                    342.01063164477796,
                    178.5,
                    342.01063164477796,
                    178.5,
                    341.01000625390867,
                    178.5,
                    340.0093808630394,
                    178.5,
                    339.0087554721701,
                    178.5,
                    338.0081300813008,
                    178.5,
                    337.0075046904315,
                    178.5,
                    336.00687929956223,
                    178.5,
                    334.00562851782365,
                    178.5,
                    330.00312695434644,
                    178.5,
                    329.00250156347715,
                    178.5,
                    327.0012507817386,
                    178.5,
                    326.0006253908693,
                    178.5,
                    321.99812382739213,
                    178.5,
                    321.99812382739213,
                    178.5,
                    320.99749843652285,
                    178.5,
                    320.99749843652285,
                    179.5,
                    319.9968730456535,
                    179.5,
                    319.9968730456535,
                    179.5,
                    318.9962476547842,
                    179.5,
                    318.9962476547842,
                    180.5,
                    318.9962476547842,
                    180.5,
                    317.9956222639149,
                    180.5,
                    316.99499687304564,
                    180.5,
                    316.99499687304564,
                    180.5,
                    316.99499687304564,
                    180.5,
                    315.99437148217635,
                    181.5,
                    314.99374609130706,
                    181.5,
                    314.99374609130706,
                    181.5,
                    313.99312070043777,
                    181.5,
                    313.99312070043777,
                    182.5,
                    313.99312070043777,
                    182.5,
                    313.99312070043777,
                    182.5,
                    311.9918699186992,
                    182.5,
                    310.9912445278299,
                    182.5,
                    310.9912445278299,
                    182.5,
                    310.9912445278299,
                    182.5,
                    309.9906191369606,
                    183.5,
                    309.9906191369606,
                    183.5,
                    309.9906191369606,
                    184.5,
                    308.98999374609133,
                    184.5,
                    308.98999374609133,
                    184.5,
                    308.98999374609133,
                    185.5,
                    307.989368355222,
                    185.5,
                    306.9887429643527,
                    186.5,
                    306.9887429643527,
                    187.5,
                    305.9881175734834,
                    187.5,
                    304.9874921826141,
                    187.5,
                    304.9874921826141,
                    187.5,
                    304.9874921826141,
                    188.5,
                    304.9874921826141,
                    189.5,
                    304.9874921826141,
                    189.5,
                    303.98686679174483,
                    189.5,
                    303.98686679174483,
                    190.5,
                    302.98624140087554,
                    191.5,
                    302.98624140087554,
                    191.5,
                    301.98561601000625,
                    192.5,
                    301.98561601000625,
                    193.5,
                    300.98499061913697,
                    193.5,
                    300.98499061913697,
                    193.5,
                    299.9843652282677,
                    194.5,
                    299.9843652282677,
                    194.5,
                    299.9843652282677,
                    195.5,
                    299.9843652282677,
                    196.5,
                    299.9843652282677,
                    196.5,
                    299.9843652282677,
                    197.5,
                    299.9843652282677,
                    198.5,
                    299.9843652282677,
                    198.5,
                    299.9843652282677,
                    199.5,
                    298.9837398373984,
                    200.5,
                    298.9837398373984,
                    201.5,
                    298.9837398373984,
                    202.5,
                    298.9837398373984,
                    202.5,
                    298.9837398373984,
                    204.5,
                    298.9837398373984,
                    205.5,
                    298.9837398373984,
                    206.5,
                    298.9837398373984,
                    207.5,
                    298.9837398373984,
                    208.5,
                    298.9837398373984,
                    208.5,
                    298.9837398373984,
                    209.5,
                    298.9837398373984,
                    210.5,
                    298.9837398373984,
                    211.5,
                    298.9837398373984,
                    212.5,
                    298.9837398373984,
                    212.5,
                    298.9837398373984,
                    213.5,
                    298.9837398373984,
                    214.5,
                    298.9837398373984,
                    214.5,
                    298.9837398373984,
                    215.5,
                    298.9837398373984,
                    216.5,
                    298.9837398373984,
                    216.5,
                    298.9837398373984,
                    217.5,
                    298.9837398373984,
                    217.5,
                    298.9837398373984,
                    218.5,
                    298.9837398373984,
                    219.5,
                    298.9837398373984,
                    219.5,
                    298.9837398373984,
                    220.5,
                    298.9837398373984,
                    221.5,
                    298.9837398373984,
                    221.5,
                    298.9837398373984,
                    221.5,
                    298.9837398373984,
                    222.5,
                    299.9843652282677,
                    223.5,
                    299.9843652282677,
                    223.5,
                    299.9843652282677,
                    224.5,
                    299.9843652282677,
                    224.5,
                    299.9843652282677,
                    225.5,
                    300.98499061913697,
                    225.5,
                    300.98499061913697,
                    226.5,
                    300.98499061913697,
                    226.5,
                    300.98499061913697,
                    228.5,
                    301.98561601000625,
                    229.5,
                    302.98624140087554,
                    229.5,
                    302.98624140087554,
                    233.5,
                    304.9874921826141,
                    233.5,
                    305.9881175734834,
                    234.5,
                    305.9881175734834,
                    234.5,
                    306.9887429643527,
                    235.5,
                    306.9887429643527,
                    235.5,
                    307.989368355222,
                    236.5,
                    307.989368355222,
                    236.5,
                    307.989368355222,
                    239.5,
                    310.9912445278299,
                    239.5,
                    311.9918699186992,
                    240.5,
                    311.9918699186992,
                    240.5,
                    312.9924953095685,
                    240.5,
                    312.9924953095685,
                    240.5,
                    313.99312070043777,
                    240.5,
                    313.99312070043777,
                    240.5,
                    313.99312070043777
                ]
            },
            {
                "tool": "pen",
                "points": [
                    173.5,
                    354.0181363352095,
                    173.5,
                    355.0187617260788,
                    173.5,
                    355.0187617260788,
                    173.5,
                    356.0193871169481,
                    173.5,
                    356.0193871169481,
                    173.5,
                    357.0200125078174,
                    173.5,
                    357.0200125078174,
                    173.5,
                    358.0206378986867,
                    173.5,
                    358.0206378986867,
                    173.5,
                    359.021263289556,
                    173.5,
                    359.021263289556,
                    173.5,
                    360.02188868042526,
                    173.5,
                    360.02188868042526,
                    173.5,
                    361.02251407129455,
                    173.5,
                    361.02251407129455,
                    173.5,
                    361.02251407129455,
                    173.5,
                    362.02313946216384,
                    173.5,
                    362.02313946216384,
                    173.5,
                    363.02376485303313,
                    174.5,
                    363.02376485303313,
                    174.5,
                    363.02376485303313,
                    174.5,
                    364.0243902439024,
                    174.5,
                    364.0243902439024,
                    174.5,
                    365.0250156347717,
                    174.5,
                    365.0250156347717,
                    175.5,
                    365.0250156347717,
                    175.5,
                    365.0250156347717
                ]
            },
            {
                "tool": "pen",
                "points": [
                    296.5,
                    318.9962476547842,
                    297.5,
                    318.9962476547842,
                    297.5,
                    318.9962476547842,
                    297.5,
                    317.9956222639149,
                    297.5,
                    316.99499687304564,
                    296.5,
                    316.99499687304564,
                    296.5,
                    316.99499687304564,
                    295.5,
                    316.99499687304564,
                    295.5,
                    315.99437148217635,
                    294.5,
                    315.99437148217635,
                    294.5,
                    314.99374609130706,
                    293.5,
                    314.99374609130706,
                    293.5,
                    314.99374609130706,
                    292.5,
                    314.99374609130706,
                    290.5,
                    314.99374609130706,
                    290.5,
                    314.99374609130706,
                    286.5,
                    313.99312070043777,
                    285.5,
                    313.99312070043777,
                    285.5,
                    313.99312070043777,
                    284.5,
                    313.99312070043777,
                    283.5,
                    313.99312070043777,
                    283.5,
                    313.99312070043777,
                    281.5,
                    313.99312070043777,
                    281.5,
                    313.99312070043777,
                    280.5,
                    313.99312070043777,
                    280.5,
                    313.99312070043777,
                    279.5,
                    313.99312070043777,
                    279.5,
                    313.99312070043777,
                    278.5,
                    313.99312070043777,
                    277.5,
                    313.99312070043777,
                    276.5,
                    313.99312070043777,
                    276.5,
                    313.99312070043777,
                    275.5,
                    313.99312070043777,
                    275.5,
                    313.99312070043777,
                    274.5,
                    313.99312070043777,
                    273.5,
                    314.99374609130706,
                    272.5,
                    314.99374609130706,
                    271.5,
                    314.99374609130706,
                    271.5,
                    314.99374609130706,
                    271.5,
                    315.99437148217635,
                    270.5,
                    315.99437148217635,
                    268.5,
                    316.99499687304564,
                    267.5,
                    318.9962476547842,
                    266.5,
                    318.9962476547842,
                    266.5,
                    319.9968730456535,
                    264.5,
                    319.9968730456535,
                    263.5,
                    319.9968730456535,
                    263.5,
                    320.99749843652285,
                    262.5,
                    321.99812382739213,
                    262.5,
                    321.99812382739213,
                    261.5,
                    322.9987492182614,
                    261.5,
                    323.9993746091307,
                    260.5,
                    323.9993746091307,
                    259.5,
                    323.9993746091307,
                    259.5,
                    325,
                    258.5,
                    327.0012507817386,
                    258.5,
                    327.0012507817386,
                    257.5,
                    329.00250156347715,
                    256.5,
                    329.00250156347715,
                    255.5,
                    330.00312695434644,
                    253.5,
                    332.0043777360851,
                    253.5,
                    332.0043777360851,
                    252.5,
                    333.00500312695436,
                    252.5,
                    334.00562851782365,
                    251.5,
                    335.00625390869294,
                    250.5,
                    337.0075046904315,
                    250.5,
                    337.0075046904315,
                    250.5,
                    338.0081300813008,
                    249.5,
                    339.0087554721701,
                    249.5,
                    340.0093808630394,
                    249.5,
                    340.0093808630394,
                    248.5,
                    341.01000625390867,
                    248.5,
                    342.01063164477796,
                    248.5,
                    343.01125703564725,
                    248.5,
                    344.0118824265166,
                    248.5,
                    345.0125078173859,
                    248.5,
                    345.0125078173859,
                    248.5,
                    345.0125078173859,
                    248.5,
                    346.01313320825517,
                    248.5,
                    347.01375859912446,
                    248.5,
                    348.01438398999375,
                    248.5,
                    349.01500938086303,
                    248.5,
                    349.01500938086303,
                    248.5,
                    350.0156347717323,
                    248.5,
                    352.0168855534709,
                    248.5,
                    353.0175109443402,
                    248.5,
                    353.0175109443402,
                    248.5,
                    354.0181363352095,
                    249.5,
                    355.0187617260788,
                    249.5,
                    356.0193871169481,
                    249.5,
                    357.0200125078174,
                    250.5,
                    357.0200125078174,
                    250.5,
                    358.0206378986867,
                    252.5,
                    362.02313946216384,
                    253.5,
                    362.02313946216384,
                    253.5,
                    364.0243902439024,
                    253.5,
                    364.0243902439024,
                    254.5,
                    365.0250156347717,
                    254.5,
                    366.025641025641,
                    255.5,
                    366.025641025641,
                    256.5,
                    366.025641025641,
                    256.5,
                    367.02626641651034,
                    256.5,
                    367.02626641651034,
                    257.5,
                    368.0268918073796,
                    258.5,
                    368.0268918073796,
                    258.5,
                    369.0275171982489,
                    262.5,
                    371.0287679799875,
                    262.5,
                    371.0287679799875,
                    263.5,
                    372.0293933708568,
                    263.5,
                    372.0293933708568,
                    264.5,
                    372.0293933708568,
                    264.5,
                    373.03001876172607,
                    268.5,
                    374.03064415259536,
                    268.5,
                    374.03064415259536,
                    269.5,
                    374.03064415259536,
                    270.5,
                    374.03064415259536,
                    270.5,
                    374.03064415259536,
                    271.5,
                    374.03064415259536,
                    272.5,
                    374.03064415259536,
                    273.5,
                    374.03064415259536,
                    273.5,
                    374.03064415259536,
                    274.5,
                    374.03064415259536,
                    275.5,
                    374.03064415259536,
                    276.5,
                    374.03064415259536,
                    277.5,
                    374.03064415259536,
                    277.5,
                    374.03064415259536,
                    278.5,
                    374.03064415259536,
                    279.5,
                    374.03064415259536,
                    280.5,
                    374.03064415259536,
                    280.5,
                    374.03064415259536,
                    281.5,
                    374.03064415259536,
                    282.5,
                    374.03064415259536,
                    283.5,
                    374.03064415259536,
                    285.5,
                    373.03001876172607,
                    286.5,
                    373.03001876172607,
                    287.5,
                    372.0293933708568,
                    288.5,
                    372.0293933708568,
                    289.5,
                    371.0287679799875,
                    291.5,
                    371.0287679799875,
                    291.5,
                    370.0281425891182,
                    292.5,
                    369.0275171982489,
                    293.5,
                    369.0275171982489,
                    294.5,
                    368.0268918073796,
                    295.5,
                    367.02626641651034,
                    296.5,
                    367.02626641651034,
                    297.5,
                    366.025641025641,
                    299.5,
                    365.0250156347717,
                    302.5,
                    362.02313946216384,
                    302.5,
                    361.02251407129455,
                    303.5,
                    360.02188868042526,
                    304.5,
                    358.0206378986867,
                    305.5,
                    357.0200125078174,
                    306.5,
                    357.0200125078174,
                    306.5,
                    356.0193871169481,
                    306.5,
                    355.0187617260788,
                    306.5,
                    355.0187617260788,
                    306.5,
                    355.0187617260788,
                    307.5,
                    354.0181363352095,
                    307.5,
                    354.0181363352095,
                    308.5,
                    353.0175109443402,
                    308.5,
                    352.0168855534709,
                    308.5,
                    352.0168855534709,
                    308.5,
                    351.0162601626016,
                    308.5,
                    350.0156347717323,
                    309.5,
                    350.0156347717323,
                    309.5,
                    349.01500938086303,
                    309.5,
                    348.01438398999375,
                    309.5,
                    348.01438398999375,
                    309.5,
                    347.01375859912446,
                    309.5,
                    345.0125078173859,
                    309.5,
                    344.0118824265166,
                    309.5,
                    343.01125703564725,
                    310.5,
                    343.01125703564725,
                    310.5,
                    343.01125703564725,
                    310.5,
                    342.01063164477796,
                    310.5,
                    342.01063164477796,
                    310.5,
                    341.01000625390867,
                    310.5,
                    341.01000625390867,
                    310.5,
                    341.01000625390867,
                    310.5,
                    340.0093808630394,
                    310.5,
                    340.0093808630394,
                    310.5,
                    339.0087554721701,
                    310.5,
                    339.0087554721701,
                    310.5,
                    338.0081300813008,
                    310.5,
                    338.0081300813008,
                    310.5,
                    337.0075046904315,
                    310.5,
                    336.00687929956223,
                    310.5,
                    336.00687929956223,
                    310.5,
                    335.00625390869294,
                    309.5,
                    335.00625390869294,
                    309.5,
                    334.00562851782365,
                    309.5,
                    334.00562851782365,
                    309.5,
                    334.00562851782365,
                    309.5,
                    333.00500312695436,
                    309.5,
                    333.00500312695436,
                    308.5,
                    332.0043777360851,
                    308.5,
                    331.00375234521573,
                    308.5,
                    331.00375234521573,
                    308.5,
                    331.00375234521573,
                    308.5,
                    329.00250156347715,
                    308.5,
                    328.00187617260787,
                    307.5,
                    328.00187617260787,
                    307.5,
                    327.0012507817386,
                    307.5,
                    326.0006253908693,
                    307.5,
                    326.0006253908693,
                    307.5,
                    326.0006253908693,
                    307.5,
                    325,
                    307.5,
                    325,
                    307.5,
                    323.9993746091307,
                    307.5,
                    323.9993746091307,
                    306.5,
                    322.9987492182614,
                    306.5,
                    322.9987492182614,
                    306.5,
                    321.99812382739213,
                    306.5,
                    321.99812382739213,
                    306.5,
                    321.99812382739213,
                    306.5,
                    320.99749843652285,
                    306.5,
                    319.9968730456535
                ]
            },
            {
                "tool": "pen",
                "points": [
                    304.5,
                    318.9962476547842,
                    304.5,
                    317.9956222639149,
                    305.5,
                    318.9962476547842,
                    307.5,
                    321.99812382739213,
                    307.5,
                    322.9987492182614,
                    308.5,
                    322.9987492182614,
                    308.5,
                    323.9993746091307,
                    309.5,
                    323.9993746091307,
                    309.5,
                    325,
                    309.5,
                    327.0012507817386,
                    310.5,
                    327.0012507817386,
                    310.5,
                    327.0012507817386,
                    311.5,
                    328.00187617260787,
                    311.5,
                    329.00250156347715,
                    311.5,
                    329.00250156347715,
                    312.5,
                    331.00375234521573,
                    312.5,
                    332.0043777360851,
                    312.5,
                    332.0043777360851,
                    313.5,
                    333.00500312695436,
                    313.5,
                    333.00500312695436,
                    314.5,
                    334.00562851782365,
                    314.5,
                    335.00625390869294,
                    314.5,
                    335.00625390869294,
                    314.5,
                    335.00625390869294,
                    314.5,
                    336.00687929956223,
                    314.5,
                    336.00687929956223,
                    314.5,
                    337.0075046904315,
                    314.5,
                    337.0075046904315,
                    314.5,
                    338.0081300813008,
                    314.5,
                    338.0081300813008,
                    315.5,
                    340.0093808630394,
                    315.5,
                    340.0093808630394,
                    315.5,
                    341.01000625390867,
                    315.5,
                    341.01000625390867,
                    315.5,
                    342.01063164477796,
                    315.5,
                    342.01063164477796,
                    315.5,
                    342.01063164477796,
                    315.5,
                    343.01125703564725,
                    315.5,
                    344.0118824265166,
                    316.5,
                    344.0118824265166,
                    316.5,
                    344.0118824265166,
                    316.5,
                    345.0125078173859,
                    316.5,
                    346.01313320825517,
                    316.5,
                    346.01313320825517,
                    316.5,
                    347.01375859912446,
                    316.5,
                    348.01438398999375,
                    316.5,
                    348.01438398999375,
                    317.5,
                    349.01500938086303,
                    317.5,
                    350.0156347717323,
                    317.5,
                    350.0156347717323,
                    317.5,
                    351.0162601626016,
                    317.5,
                    351.0162601626016,
                    318.5,
                    352.0168855534709,
                    318.5,
                    352.0168855534709,
                    318.5,
                    353.0175109443402,
                    318.5,
                    353.0175109443402,
                    318.5,
                    354.0181363352095,
                    318.5,
                    355.0187617260788,
                    318.5,
                    355.0187617260788,
                    318.5,
                    355.0187617260788,
                    318.5,
                    356.0193871169481,
                    318.5,
                    356.0193871169481,
                    318.5,
                    357.0200125078174,
                    318.5,
                    357.0200125078174,
                    318.5,
                    357.0200125078174,
                    319.5,
                    358.0206378986867,
                    319.5,
                    358.0206378986867,
                    319.5,
                    359.021263289556,
                    319.5,
                    359.021263289556,
                    319.5,
                    359.021263289556,
                    319.5,
                    360.02188868042526,
                    320.5,
                    361.02251407129455,
                    320.5,
                    362.02313946216384,
                    320.5,
                    362.02313946216384,
                    320.5,
                    362.02313946216384,
                    320.5,
                    363.02376485303313,
                    320.5,
                    363.02376485303313,
                    321.5,
                    365.0250156347717,
                    322.5,
                    365.0250156347717,
                    322.5,
                    366.025641025641,
                    322.5,
                    366.025641025641,
                    322.5,
                    367.02626641651034,
                    322.5,
                    367.02626641651034,
                    322.5,
                    367.02626641651034,
                    322.5,
                    368.0268918073796,
                    323.5,
                    369.0275171982489,
                    323.5,
                    369.0275171982489,
                    323.5,
                    370.0281425891182,
                    324.5,
                    372.0293933708568,
                    324.5,
                    372.0293933708568,
                    325.5,
                    373.03001876172607,
                    325.5,
                    373.03001876172607,
                    325.5,
                    374.03064415259536,
                    325.5,
                    374.03064415259536,
                    325.5,
                    375.03126954346465,
                    325.5,
                    375.03126954346465,
                    325.5,
                    376.03189493433393,
                    326.5,
                    376.03189493433393,
                    326.5,
                    376.03189493433393
                ]
            },
            {
                "tool": "pen",
                "points": [
                    348.5,
                    312.9924953095685,
                    349.5,
                    313.99312070043777,
                    349.5,
                    313.99312070043777,
                    349.5,
                    314.99374609130706,
                    349.5,
                    315.99437148217635,
                    349.5,
                    315.99437148217635,
                    349.5,
                    316.99499687304564,
                    350.5,
                    320.99749843652285,
                    351.5,
                    320.99749843652285,
                    351.5,
                    320.99749843652285,
                    352.5,
                    321.99812382739213,
                    352.5,
                    322.9987492182614,
                    352.5,
                    323.9993746091307,
                    352.5,
                    323.9993746091307,
                    353.5,
                    325,
                    353.5,
                    326.0006253908693,
                    353.5,
                    326.0006253908693,
                    353.5,
                    326.0006253908693,
                    353.5,
                    327.0012507817386,
                    353.5,
                    327.0012507817386,
                    354.5,
                    329.00250156347715,
                    354.5,
                    329.00250156347715,
                    354.5,
                    330.00312695434644,
                    355.5,
                    331.00375234521573,
                    355.5,
                    331.00375234521573,
                    355.5,
                    331.00375234521573,
                    356.5,
                    332.0043777360851,
                    356.5,
                    332.0043777360851,
                    356.5,
                    333.00500312695436,
                    356.5,
                    333.00500312695436,
                    356.5,
                    334.00562851782365,
                    357.5,
                    336.00687929956223,
                    357.5,
                    336.00687929956223,
                    358.5,
                    337.0075046904315,
                    358.5,
                    337.0075046904315,
                    358.5,
                    338.0081300813008,
                    359.5,
                    338.0081300813008,
                    360.5,
                    339.0087554721701,
                    360.5,
                    339.0087554721701,
                    360.5,
                    340.0093808630394,
                    360.5,
                    340.0093808630394,
                    360.5,
                    340.0093808630394,
                    360.5,
                    341.01000625390867,
                    360.5,
                    342.01063164477796,
                    361.5,
                    342.01063164477796,
                    361.5,
                    343.01125703564725,
                    361.5,
                    344.0118824265166,
                    361.5,
                    345.0125078173859,
                    361.5,
                    345.0125078173859,
                    361.5,
                    346.01313320825517,
                    362.5,
                    346.01313320825517,
                    362.5,
                    347.01375859912446,
                    362.5,
                    347.01375859912446,
                    363.5,
                    349.01500938086303,
                    363.5,
                    349.01500938086303,
                    366.5,
                    352.0168855534709,
                    366.5,
                    353.0175109443402,
                    366.5,
                    353.0175109443402,
                    366.5,
                    354.0181363352095,
                    367.5,
                    356.0193871169481,
                    367.5,
                    357.0200125078174,
                    367.5,
                    358.0206378986867,
                    368.5,
                    359.021263289556,
                    368.5,
                    360.02188868042526,
                    368.5,
                    361.02251407129455,
                    370.5,
                    363.02376485303313,
                    370.5,
                    363.02376485303313,
                    370.5,
                    364.0243902439024,
                    371.5,
                    365.0250156347717,
                    371.5,
                    367.02626641651034,
                    371.5,
                    367.02626641651034,
                    371.5,
                    368.0268918073796,
                    372.5,
                    368.0268918073796,
                    372.5,
                    368.0268918073796,
                    372.5,
                    369.0275171982489,
                    372.5,
                    369.0275171982489,
                    373.5,
                    370.0281425891182,
                    373.5,
                    370.0281425891182,
                    373.5,
                    371.0287679799875,
                    374.5,
                    371.0287679799875,
                    374.5,
                    372.0293933708568,
                    374.5,
                    372.0293933708568
                ]
            },
            {
                "tool": "pen",
                "points": [
                    374.5,
                    372.0293933708568,
                    375.5,
                    372.0293933708568,
                    375.5,
                    372.0293933708568,
                    375.5,
                    369.0275171982489,
                    375.5,
                    368.0268918073796,
                    376.5,
                    368.0268918073796,
                    376.5,
                    367.02626641651034,
                    377.5,
                    367.02626641651034,
                    377.5,
                    366.025641025641,
                    377.5,
                    365.0250156347717,
                    377.5,
                    365.0250156347717,
                    378.5,
                    364.0243902439024,
                    378.5,
                    364.0243902439024,
                    379.5,
                    363.02376485303313,
                    379.5,
                    362.02313946216384,
                    379.5,
                    362.02313946216384,
                    379.5,
                    361.02251407129455,
                    379.5,
                    361.02251407129455,
                    379.5,
                    360.02188868042526,
                    379.5,
                    360.02188868042526,
                    379.5,
                    360.02188868042526,
                    379.5,
                    359.021263289556,
                    379.5,
                    359.021263289556,
                    379.5,
                    358.0206378986867,
                    379.5,
                    358.0206378986867,
                    380.5,
                    358.0206378986867,
                    380.5,
                    357.0200125078174,
                    381.5,
                    357.0200125078174,
                    381.5,
                    356.0193871169481,
                    382.5,
                    355.0187617260788,
                    382.5,
                    355.0187617260788,
                    382.5,
                    355.0187617260788,
                    382.5,
                    355.0187617260788,
                    383.5,
                    355.0187617260788,
                    383.5,
                    354.0181363352095,
                    384.5,
                    354.0181363352095,
                    384.5,
                    353.0175109443402,
                    384.5,
                    353.0175109443402,
                    384.5,
                    352.0168855534709,
                    385.5,
                    352.0168855534709,
                    385.5,
                    351.0162601626016,
                    386.5,
                    351.0162601626016,
                    386.5,
                    351.0162601626016,
                    386.5,
                    350.0156347717323,
                    387.5,
                    350.0156347717323,
                    387.5,
                    349.01500938086303,
                    388.5,
                    349.01500938086303,
                    388.5,
                    349.01500938086303,
                    389.5,
                    348.01438398999375,
                    389.5,
                    348.01438398999375,
                    389.5,
                    347.01375859912446,
                    389.5,
                    346.01313320825517,
                    389.5,
                    346.01313320825517,
                    390.5,
                    346.01313320825517,
                    390.5,
                    346.01313320825517,
                    391.5,
                    345.0125078173859,
                    391.5,
                    344.0118824265166,
                    392.5,
                    344.0118824265166,
                    392.5,
                    344.0118824265166,
                    393.5,
                    343.01125703564725,
                    393.5,
                    342.01063164477796,
                    393.5,
                    342.01063164477796,
                    394.5,
                    342.01063164477796,
                    395.5,
                    342.01063164477796,
                    395.5,
                    341.01000625390867,
                    396.5,
                    341.01000625390867,
                    396.5,
                    341.01000625390867,
                    397.5,
                    340.0093808630394,
                    397.5,
                    340.0093808630394,
                    398.5,
                    339.0087554721701,
                    398.5,
                    339.0087554721701,
                    398.5,
                    338.0081300813008,
                    399.5,
                    338.0081300813008,
                    399.5,
                    338.0081300813008,
                    399.5,
                    337.0075046904315,
                    400.5,
                    337.0075046904315
                ]
            },
            {
                "tool": "pen",
                "points": [
                    402.5,
                    337.0075046904315,
                    402.5,
                    337.0075046904315,
                    402.5,
                    337.0075046904315,
                    402.5,
                    338.0081300813008,
                    403.5,
                    338.0081300813008,
                    403.5,
                    338.0081300813008,
                    403.5,
                    339.0087554721701,
                    403.5,
                    339.0087554721701,
                    403.5,
                    341.01000625390867,
                    403.5,
                    342.01063164477796,
                    404.5,
                    342.01063164477796,
                    404.5,
                    342.01063164477796,
                    404.5,
                    343.01125703564725,
                    404.5,
                    343.01125703564725,
                    404.5,
                    344.0118824265166,
                    404.5,
                    345.0125078173859,
                    404.5,
                    345.0125078173859,
                    404.5,
                    345.0125078173859,
                    405.5,
                    346.01313320825517,
                    405.5,
                    346.01313320825517,
                    405.5,
                    348.01438398999375,
                    405.5,
                    348.01438398999375,
                    405.5,
                    349.01500938086303,
                    405.5,
                    350.0156347717323,
                    405.5,
                    350.0156347717323,
                    405.5,
                    351.0162601626016,
                    405.5,
                    351.0162601626016,
                    405.5,
                    352.0168855534709,
                    405.5,
                    352.0168855534709,
                    405.5,
                    353.0175109443402,
                    406.5,
                    354.0181363352095,
                    406.5,
                    354.0181363352095,
                    406.5,
                    354.0181363352095,
                    407.5,
                    355.0187617260788,
                    407.5,
                    356.0193871169481,
                    407.5,
                    356.0193871169481,
                    407.5,
                    357.0200125078174,
                    408.5,
                    359.021263289556,
                    408.5,
                    359.021263289556,
                    409.5,
                    359.021263289556,
                    409.5,
                    360.02188868042526,
                    409.5,
                    360.02188868042526,
                    409.5,
                    361.02251407129455,
                    410.5,
                    362.02313946216384,
                    411.5,
                    362.02313946216384,
                    411.5,
                    362.02313946216384,
                    411.5,
                    362.02313946216384,
                    412.5,
                    363.02376485303313,
                    412.5,
                    363.02376485303313,
                    412.5,
                    364.0243902439024,
                    413.5,
                    364.0243902439024,
                    413.5,
                    365.0250156347717,
                    413.5,
                    365.0250156347717,
                    413.5,
                    366.025641025641,
                    413.5,
                    366.025641025641,
                    414.5,
                    366.025641025641,
                    415.5,
                    367.02626641651034,
                    415.5,
                    367.02626641651034,
                    415.5,
                    368.0268918073796,
                    416.5,
                    368.0268918073796,
                    416.5,
                    368.0268918073796
                ]
            },
            {
                "tool": "pen",
                "points": [
                    418.5,
                    367.02626641651034,
                    419.5,
                    367.02626641651034,
                    420.5,
                    366.025641025641,
                    420.5,
                    366.025641025641,
                    420.5,
                    365.0250156347717,
                    420.5,
                    365.0250156347717,
                    420.5,
                    364.0243902439024,
                    421.5,
                    364.0243902439024,
                    421.5,
                    363.02376485303313,
                    421.5,
                    363.02376485303313,
                    422.5,
                    363.02376485303313,
                    422.5,
                    363.02376485303313,
                    422.5,
                    363.02376485303313,
                    422.5,
                    362.02313946216384,
                    422.5,
                    362.02313946216384,
                    423.5,
                    361.02251407129455,
                    423.5,
                    361.02251407129455,
                    423.5,
                    361.02251407129455,
                    424.5,
                    360.02188868042526,
                    424.5,
                    360.02188868042526,
                    424.5,
                    359.021263289556,
                    425.5,
                    359.021263289556,
                    425.5,
                    359.021263289556,
                    425.5,
                    358.0206378986867,
                    426.5,
                    357.0200125078174,
                    426.5,
                    357.0200125078174,
                    427.5,
                    356.0193871169481,
                    427.5,
                    356.0193871169481,
                    427.5,
                    355.0187617260788,
                    427.5,
                    354.0181363352095,
                    428.5,
                    354.0181363352095,
                    428.5,
                    353.0175109443402,
                    428.5,
                    353.0175109443402,
                    429.5,
                    353.0175109443402,
                    429.5,
                    352.0168855534709,
                    429.5,
                    352.0168855534709,
                    429.5,
                    352.0168855534709,
                    430.5,
                    351.0162601626016,
                    430.5,
                    351.0162601626016,
                    430.5,
                    350.0156347717323,
                    431.5,
                    350.0156347717323,
                    431.5,
                    350.0156347717323,
                    432.5,
                    350.0156347717323,
                    432.5,
                    349.01500938086303,
                    432.5,
                    349.01500938086303,
                    432.5,
                    348.01438398999375,
                    432.5,
                    348.01438398999375,
                    433.5,
                    348.01438398999375,
                    434.5,
                    347.01375859912446,
                    434.5,
                    346.01313320825517,
                    434.5,
                    346.01313320825517,
                    435.5,
                    345.0125078173859,
                    435.5,
                    344.0118824265166,
                    436.5,
                    343.01125703564725,
                    436.5,
                    343.01125703564725,
                    436.5,
                    343.01125703564725,
                    436.5,
                    342.01063164477796,
                    436.5,
                    342.01063164477796,
                    437.5,
                    342.01063164477796,
                    437.5,
                    341.01000625390867,
                    437.5,
                    341.01000625390867,
                    437.5,
                    340.0093808630394,
                    438.5,
                    340.0093808630394,
                    438.5,
                    339.0087554721701,
                    438.5,
                    339.0087554721701,
                    438.5,
                    339.0087554721701,
                    439.5,
                    338.0081300813008,
                    440.5,
                    338.0081300813008,
                    441.5,
                    337.0075046904315,
                    441.5,
                    336.00687929956223,
                    441.5,
                    336.00687929956223,
                    442.5,
                    335.00625390869294,
                    442.5,
                    334.00562851782365,
                    443.5,
                    334.00562851782365,
                    443.5,
                    333.00500312695436,
                    443.5,
                    333.00500312695436,
                    443.5,
                    332.0043777360851,
                    444.5,
                    332.0043777360851,
                    444.5,
                    332.0043777360851,
                    444.5,
                    332.0043777360851,
                    445.5,
                    331.00375234521573,
                    445.5,
                    330.00312695434644,
                    445.5,
                    330.00312695434644,
                    445.5,
                    330.00312695434644,
                    446.5,
                    329.00250156347715,
                    446.5,
                    329.00250156347715,
                    446.5,
                    328.00187617260787,
                    446.5,
                    328.00187617260787,
                    447.5,
                    328.00187617260787,
                    447.5,
                    327.0012507817386,
                    447.5,
                    327.0012507817386,
                    447.5,
                    327.0012507817386,
                    448.5,
                    327.0012507817386,
                    448.5,
                    327.0012507817386,
                    448.5,
                    327.0012507817386,
                    448.5,
                    326.0006253908693,
                    449.5,
                    326.0006253908693,
                    449.5,
                    325,
                    450.5,
                    325,
                    451.5,
                    323.9993746091307,
                    451.5,
                    322.9987492182614,
                    452.5,
                    322.9987492182614,
                    452.5,
                    322.9987492182614,
                    452.5,
                    321.99812382739213,
                    453.5,
                    321.99812382739213,
                    454.5,
                    320.99749843652285,
                    454.5,
                    320.99749843652285,
                    454.5,
                    320.99749843652285,
                    455.5,
                    319.9968730456535,
                    455.5,
                    318.9962476547842,
                    455.5,
                    318.9962476547842,
                    456.5,
                    317.9956222639149,
                    456.5,
                    317.9956222639149,
                    456.5,
                    316.99499687304564,
                    457.5,
                    316.99499687304564,
                    457.5,
                    316.99499687304564,
                    458.5,
                    315.99437148217635,
                    458.5,
                    315.99437148217635,
                    459.5,
                    315.99437148217635
                ]
            },
            {
                "tool": "pen",
                "points": [
                    483.5,
                    321.99812382739213,
                    483.5,
                    321.99812382739213,
                    483.5,
                    323.9993746091307,
                    483.5,
                    323.9993746091307,
                    483.5,
                    325,
                    483.5,
                    325,
                    483.5,
                    326.0006253908693,
                    485.5,
                    331.00375234521573,
                    486.5,
                    332.0043777360851,
                    486.5,
                    333.00500312695436,
                    486.5,
                    333.00500312695436,
                    486.5,
                    334.00562851782365,
                    486.5,
                    335.00625390869294,
                    487.5,
                    335.00625390869294,
                    487.5,
                    336.00687929956223,
                    487.5,
                    337.0075046904315,
                    487.5,
                    338.0081300813008,
                    487.5,
                    339.0087554721701,
                    487.5,
                    341.01000625390867,
                    487.5,
                    341.01000625390867,
                    487.5,
                    342.01063164477796,
                    488.5,
                    343.01125703564725,
                    488.5,
                    343.01125703564725,
                    488.5,
                    343.01125703564725,
                    488.5,
                    344.0118824265166,
                    488.5,
                    345.0125078173859,
                    488.5,
                    345.0125078173859,
                    488.5,
                    346.01313320825517,
                    488.5,
                    346.01313320825517,
                    488.5,
                    347.01375859912446,
                    488.5,
                    347.01375859912446,
                    488.5,
                    348.01438398999375,
                    488.5,
                    348.01438398999375,
                    488.5,
                    348.01438398999375,
                    489.5,
                    349.01500938086303,
                    489.5,
                    349.01500938086303,
                    489.5,
                    350.0156347717323,
                    489.5,
                    350.0156347717323,
                    490.5,
                    351.0162601626016,
                    490.5,
                    351.0162601626016,
                    490.5,
                    352.0168855534709,
                    490.5,
                    352.0168855534709,
                    490.5,
                    353.0175109443402,
                    490.5,
                    354.0181363352095,
                    490.5,
                    354.0181363352095,
                    490.5,
                    354.0181363352095,
                    490.5,
                    355.0187617260788,
                    490.5,
                    355.0187617260788,
                    490.5,
                    356.0193871169481,
                    490.5,
                    356.0193871169481,
                    490.5,
                    357.0200125078174,
                    490.5,
                    358.0206378986867,
                    490.5,
                    358.0206378986867,
                    490.5,
                    359.021263289556,
                    490.5,
                    360.02188868042526,
                    490.5,
                    360.02188868042526,
                    490.5,
                    362.02313946216384,
                    490.5,
                    363.02376485303313,
                    490.5,
                    363.02376485303313,
                    490.5,
                    364.0243902439024,
                    490.5,
                    364.0243902439024,
                    490.5,
                    364.0243902439024,
                    490.5,
                    365.0250156347717,
                    490.5,
                    365.0250156347717,
                    490.5,
                    366.025641025641,
                    490.5,
                    366.025641025641,
                    490.5,
                    367.02626641651034,
                    490.5,
                    367.02626641651034,
                    490.5,
                    368.0268918073796,
                    490.5,
                    369.0275171982489,
                    490.5,
                    369.0275171982489,
                    490.5,
                    370.0281425891182,
                    490.5,
                    370.0281425891182,
                    490.5,
                    370.0281425891182,
                    490.5,
                    371.0287679799875,
                    490.5,
                    371.0287679799875,
                    490.5,
                    372.0293933708568,
                    490.5,
                    372.0293933708568,
                    490.5,
                    373.03001876172607,
                    490.5,
                    373.03001876172607,
                    490.5,
                    373.03001876172607,
                    490.5,
                    374.03064415259536,
                    490.5,
                    374.03064415259536,
                    490.5,
                    375.03126954346465,
                    490.5,
                    375.03126954346465
                ]
            },
            {
                "tool": "pen",
                "points": [
                    483.5,
                    288.97748592870545,
                    483.5,
                    288.97748592870545,
                    483.5,
                    288.97748592870545,
                    483.5,
                    289.97811131957474,
                    483.5,
                    289.97811131957474,
                    482.5,
                    289.97811131957474,
                    481.5,
                    290.978736710444,
                    481.5,
                    290.978736710444,
                    481.5,
                    290.978736710444,
                    481.5,
                    291.9793621013133,
                    481.5,
                    291.9793621013133,
                    481.5,
                    292.9799874921826,
                    481.5,
                    292.9799874921826,
                    481.5,
                    292.9799874921826,
                    481.5,
                    293.9806128830519,
                    481.5,
                    293.9806128830519,
                    482.5,
                    293.9806128830519,
                    482.5,
                    293.9806128830519
                ]
            },
            {
                "tool": "pen",
                "points": [
                    527.5,
                    317.9956222639149,
                    527.5,
                    318.9962476547842,
                    527.5,
                    318.9962476547842,
                    527.5,
                    321.99812382739213,
                    527.5,
                    321.99812382739213,
                    527.5,
                    322.9987492182614,
                    527.5,
                    322.9987492182614,
                    527.5,
                    325,
                    527.5,
                    327.0012507817386,
                    527.5,
                    329.00250156347715,
                    527.5,
                    335.00625390869294,
                    527.5,
                    335.00625390869294,
                    527.5,
                    339.0087554721701,
                    526.5,
                    341.01000625390867,
                    526.5,
                    343.01125703564725,
                    526.5,
                    344.0118824265166,
                    525.5,
                    347.01375859912446,
                    525.5,
                    348.01438398999375,
                    525.5,
                    349.01500938086303,
                    525.5,
                    350.0156347717323,
                    525.5,
                    352.0168855534709,
                    525.5,
                    352.0168855534709,
                    525.5,
                    353.0175109443402,
                    525.5,
                    355.0187617260788,
                    525.5,
                    356.0193871169481,
                    525.5,
                    357.0200125078174,
                    525.5,
                    357.0200125078174,
                    525.5,
                    358.0206378986867,
                    525.5,
                    359.021263289556,
                    525.5,
                    360.02188868042526,
                    525.5,
                    361.02251407129455,
                    525.5,
                    363.02376485303313,
                    525.5,
                    363.02376485303313,
                    525.5,
                    364.0243902439024,
                    525.5,
                    364.0243902439024,
                    525.5,
                    365.0250156347717,
                    525.5,
                    365.0250156347717,
                    525.5,
                    366.025641025641,
                    525.5,
                    366.025641025641
                ]
            },
            {
                "tool": "pen",
                "points": [
                    530.5,
                    334.00562851782365,
                    530.5,
                    333.00500312695436,
                    530.5,
                    333.00500312695436,
                    530.5,
                    333.00500312695436,
                    531.5,
                    332.0043777360851,
                    531.5,
                    332.0043777360851,
                    531.5,
                    331.00375234521573,
                    531.5,
                    331.00375234521573,
                    531.5,
                    330.00312695434644,
                    532.5,
                    330.00312695434644,
                    532.5,
                    329.00250156347715,
                    533.5,
                    329.00250156347715,
                    533.5,
                    328.00187617260787,
                    533.5,
                    328.00187617260787,
                    533.5,
                    328.00187617260787,
                    533.5,
                    328.00187617260787,
                    534.5,
                    327.0012507817386,
                    534.5,
                    327.0012507817386,
                    535.5,
                    326.0006253908693,
                    535.5,
                    326.0006253908693,
                    535.5,
                    326.0006253908693,
                    536.5,
                    325,
                    536.5,
                    323.9993746091307,
                    537.5,
                    323.9993746091307,
                    538.5,
                    322.9987492182614,
                    538.5,
                    322.9987492182614,
                    539.5,
                    321.99812382739213,
                    539.5,
                    321.99812382739213,
                    540.5,
                    321.99812382739213,
                    540.5,
                    320.99749843652285,
                    541.5,
                    320.99749843652285,
                    541.5,
                    320.99749843652285,
                    542.5,
                    319.9968730456535,
                    543.5,
                    319.9968730456535,
                    543.5,
                    319.9968730456535,
                    544.5,
                    319.9968730456535,
                    544.5,
                    319.9968730456535,
                    545.5,
                    318.9962476547842,
                    545.5,
                    318.9962476547842,
                    546.5,
                    318.9962476547842,
                    547.5,
                    318.9962476547842,
                    547.5,
                    317.9956222639149,
                    548.5,
                    317.9956222639149,
                    549.5,
                    317.9956222639149,
                    549.5,
                    317.9956222639149,
                    551.5,
                    317.9956222639149,
                    552.5,
                    317.9956222639149,
                    553.5,
                    317.9956222639149,
                    554.5,
                    317.9956222639149,
                    554.5,
                    317.9956222639149,
                    555.5,
                    317.9956222639149,
                    556.5,
                    317.9956222639149,
                    556.5,
                    317.9956222639149,
                    557.5,
                    317.9956222639149,
                    557.5,
                    317.9956222639149,
                    558.5,
                    317.9956222639149,
                    559.5,
                    317.9956222639149,
                    561.5,
                    317.9956222639149,
                    563.5,
                    317.9956222639149,
                    563.5,
                    317.9956222639149,
                    564.5,
                    317.9956222639149,
                    565.5,
                    317.9956222639149,
                    567.5,
                    317.9956222639149,
                    568.5,
                    317.9956222639149,
                    568.5,
                    317.9956222639149,
                    569.5,
                    317.9956222639149,
                    570.5,
                    317.9956222639149,
                    570.5,
                    317.9956222639149,
                    571.5,
                    317.9956222639149,
                    572.5,
                    317.9956222639149,
                    573.5,
                    317.9956222639149,
                    573.5,
                    317.9956222639149,
                    574.5,
                    317.9956222639149,
                    575.5,
                    317.9956222639149,
                    575.5,
                    317.9956222639149,
                    576.5,
                    317.9956222639149,
                    576.5,
                    317.9956222639149,
                    577.5,
                    317.9956222639149,
                    577.5,
                    317.9956222639149,
                    578.5,
                    318.9962476547842,
                    578.5,
                    318.9962476547842,
                    579.5,
                    318.9962476547842,
                    579.5,
                    319.9968730456535,
                    580.5,
                    319.9968730456535,
                    580.5,
                    319.9968730456535,
                    581.5,
                    320.99749843652285,
                    582.5,
                    321.99812382739213,
                    582.5,
                    321.99812382739213,
                    582.5,
                    321.99812382739213,
                    583.5,
                    322.9987492182614,
                    583.5,
                    325,
                    583.5,
                    325,
                    583.5,
                    325,
                    584.5,
                    326.0006253908693,
                    584.5,
                    327.0012507817386,
                    585.5,
                    327.0012507817386,
                    585.5,
                    328.00187617260787,
                    585.5,
                    328.00187617260787,
                    585.5,
                    329.00250156347715,
                    585.5,
                    329.00250156347715,
                    585.5,
                    330.00312695434644,
                    585.5,
                    330.00312695434644,
                    586.5,
                    331.00375234521573,
                    586.5,
                    331.00375234521573,
                    586.5,
                    332.0043777360851,
                    586.5,
                    333.00500312695436,
                    586.5,
                    333.00500312695436,
                    586.5,
                    334.00562851782365,
                    586.5,
                    334.00562851782365,
                    587.5,
                    335.00625390869294,
                    587.5,
                    336.00687929956223,
                    587.5,
                    336.00687929956223,
                    587.5,
                    337.0075046904315,
                    587.5,
                    337.0075046904315,
                    588.5,
                    338.0081300813008,
                    588.5,
                    338.0081300813008,
                    588.5,
                    339.0087554721701,
                    589.5,
                    340.0093808630394,
                    589.5,
                    340.0093808630394,
                    589.5,
                    341.01000625390867,
                    589.5,
                    341.01000625390867,
                    589.5,
                    342.01063164477796,
                    589.5,
                    342.01063164477796,
                    590.5,
                    342.01063164477796,
                    590.5,
                    343.01125703564725,
                    590.5,
                    343.01125703564725,
                    590.5,
                    344.0118824265166,
                    590.5,
                    344.0118824265166,
                    590.5,
                    344.0118824265166,
                    590.5,
                    345.0125078173859,
                    590.5,
                    346.01313320825517,
                    590.5,
                    346.01313320825517,
                    590.5,
                    346.01313320825517,
                    590.5,
                    348.01438398999375,
                    590.5,
                    349.01500938086303,
                    590.5,
                    349.01500938086303,
                    590.5,
                    351.0162601626016,
                    590.5,
                    351.0162601626016,
                    590.5,
                    352.0168855534709,
                    590.5,
                    352.0168855534709,
                    590.5,
                    353.0175109443402,
                    590.5,
                    353.0175109443402,
                    590.5,
                    353.0175109443402,
                    590.5,
                    354.0181363352095,
                    590.5,
                    354.0181363352095,
                    590.5,
                    355.0187617260788,
                    590.5,
                    355.0187617260788,
                    590.5,
                    356.0193871169481,
                    590.5,
                    356.0193871169481,
                    590.5,
                    357.0200125078174,
                    590.5,
                    357.0200125078174,
                    590.5,
                    357.0200125078174,
                    590.5,
                    358.0206378986867,
                    590.5,
                    358.0206378986867
                ]
            },
            {
                "tool": "pen",
                "points": [
                    677.5,
                    321.99812382739213,
                    676.5,
                    321.99812382739213,
                    676.5,
                    320.99749843652285,
                    675.5,
                    320.99749843652285,
                    674.5,
                    319.9968730456535,
                    673.5,
                    318.9962476547842,
                    673.5,
                    318.9962476547842,
                    672.5,
                    318.9962476547842,
                    672.5,
                    318.9962476547842,
                    671.5,
                    317.9956222639149,
                    670.5,
                    317.9956222639149,
                    669.5,
                    317.9956222639149,
                    668.5,
                    317.9956222639149,
                    668.5,
                    317.9956222639149,
                    667.5,
                    317.9956222639149,
                    666.5,
                    317.9956222639149,
                    666.5,
                    317.9956222639149,
                    665.5,
                    317.9956222639149,
                    664.5,
                    317.9956222639149,
                    664.5,
                    317.9956222639149,
                    664.5,
                    317.9956222639149,
                    663.5,
                    317.9956222639149,
                    663.5,
                    317.9956222639149,
                    662.5,
                    317.9956222639149,
                    662.5,
                    317.9956222639149,
                    662.5,
                    317.9956222639149,
                    661.5,
                    317.9956222639149,
                    660.5,
                    317.9956222639149,
                    660.5,
                    317.9956222639149,
                    660.5,
                    317.9956222639149,
                    659.5,
                    317.9956222639149,
                    659.5,
                    317.9956222639149,
                    658.5,
                    317.9956222639149,
                    658.5,
                    317.9956222639149,
                    657.5,
                    317.9956222639149,
                    657.5,
                    317.9956222639149,
                    656.5,
                    317.9956222639149,
                    656.5,
                    317.9956222639149,
                    655.5,
                    317.9956222639149,
                    655.5,
                    317.9956222639149,
                    653.5,
                    317.9956222639149,
                    653.5,
                    317.9956222639149,
                    652.5,
                    318.9962476547842,
                    651.5,
                    318.9962476547842,
                    651.5,
                    318.9962476547842,
                    647.5,
                    320.99749843652285,
                    646.5,
                    321.99812382739213,
                    646.5,
                    321.99812382739213,
                    645.5,
                    322.9987492182614,
                    644.5,
                    322.9987492182614,
                    644.5,
                    323.9993746091307,
                    643.5,
                    323.9993746091307,
                    643.5,
                    323.9993746091307,
                    642.5,
                    325,
                    642.5,
                    325,
                    641.5,
                    326.0006253908693,
                    641.5,
                    326.0006253908693,
                    640.5,
                    326.0006253908693,
                    639.5,
                    327.0012507817386,
                    639.5,
                    327.0012507817386,
                    638.5,
                    328.00187617260787,
                    638.5,
                    328.00187617260787,
                    638.5,
                    328.00187617260787,
                    637.5,
                    329.00250156347715,
                    637.5,
                    330.00312695434644,
                    637.5,
                    330.00312695434644,
                    636.5,
                    332.0043777360851,
                    635.5,
                    332.0043777360851,
                    635.5,
                    333.00500312695436,
                    634.5,
                    333.00500312695436,
                    634.5,
                    334.00562851782365,
                    634.5,
                    335.00625390869294,
                    632.5,
                    338.0081300813008,
                    632.5,
                    339.0087554721701,
                    629.5,
                    342.01063164477796,
                    628.5,
                    342.01063164477796,
                    628.5,
                    343.01125703564725,
                    628.5,
                    344.0118824265166,
                    628.5,
                    345.0125078173859,
                    627.5,
                    345.0125078173859,
                    627.5,
                    346.01313320825517,
                    627.5,
                    346.01313320825517,
                    627.5,
                    348.01438398999375,
                    627.5,
                    349.01500938086303,
                    626.5,
                    349.01500938086303,
                    626.5,
                    350.0156347717323,
                    626.5,
                    351.0162601626016,
                    626.5,
                    352.0168855534709,
                    626.5,
                    352.0168855534709,
                    626.5,
                    353.0175109443402,
                    626.5,
                    354.0181363352095,
                    626.5,
                    355.0187617260788,
                    626.5,
                    355.0187617260788,
                    626.5,
                    356.0193871169481,
                    626.5,
                    357.0200125078174,
                    626.5,
                    357.0200125078174,
                    626.5,
                    358.0206378986867,
                    626.5,
                    358.0206378986867,
                    626.5,
                    359.021263289556,
                    626.5,
                    359.021263289556,
                    626.5,
                    359.021263289556,
                    626.5,
                    360.02188868042526,
                    626.5,
                    360.02188868042526,
                    627.5,
                    361.02251407129455,
                    628.5,
                    362.02313946216384,
                    629.5,
                    363.02376485303313,
                    629.5,
                    363.02376485303313,
                    630.5,
                    363.02376485303313,
                    630.5,
                    363.02376485303313,
                    630.5,
                    363.02376485303313,
                    631.5,
                    364.0243902439024,
                    631.5,
                    364.0243902439024,
                    632.5,
                    364.0243902439024,
                    632.5,
                    364.0243902439024,
                    633.5,
                    364.0243902439024,
                    633.5,
                    364.0243902439024,
                    634.5,
                    364.0243902439024,
                    634.5,
                    364.0243902439024,
                    635.5,
                    364.0243902439024,
                    635.5,
                    364.0243902439024,
                    635.5,
                    364.0243902439024,
                    636.5,
                    365.0250156347717,
                    637.5,
                    365.0250156347717,
                    637.5,
                    365.0250156347717,
                    637.5,
                    365.0250156347717,
                    638.5,
                    365.0250156347717,
                    639.5,
                    365.0250156347717,
                    639.5,
                    365.0250156347717,
                    640.5,
                    365.0250156347717,
                    640.5,
                    366.025641025641,
                    641.5,
                    366.025641025641,
                    641.5,
                    366.025641025641,
                    642.5,
                    366.025641025641,
                    642.5,
                    366.025641025641,
                    643.5,
                    366.025641025641,
                    644.5,
                    366.025641025641,
                    644.5,
                    367.02626641651034,
                    645.5,
                    367.02626641651034,
                    646.5,
                    367.02626641651034,
                    647.5,
                    367.02626641651034,
                    648.5,
                    367.02626641651034,
                    649.5,
                    367.02626641651034,
                    650.5,
                    367.02626641651034,
                    651.5,
                    367.02626641651034,
                    653.5,
                    367.02626641651034,
                    655.5,
                    367.02626641651034,
                    655.5,
                    367.02626641651034,
                    656.5,
                    367.02626641651034,
                    656.5,
                    367.02626641651034,
                    657.5,
                    367.02626641651034,
                    657.5,
                    367.02626641651034,
                    658.5,
                    367.02626641651034,
                    659.5,
                    367.02626641651034,
                    660.5,
                    367.02626641651034,
                    662.5,
                    366.025641025641,
                    664.5,
                    365.0250156347717,
                    664.5,
                    365.0250156347717,
                    665.5,
                    364.0243902439024,
                    667.5,
                    363.02376485303313,
                    667.5,
                    362.02313946216384,
                    668.5,
                    362.02313946216384,
                    669.5,
                    361.02251407129455,
                    669.5,
                    361.02251407129455,
                    670.5,
                    360.02188868042526,
                    670.5,
                    360.02188868042526,
                    670.5,
                    360.02188868042526,
                    670.5,
                    360.02188868042526,
                    671.5,
                    360.02188868042526,
                    671.5,
                    359.021263289556,
                    671.5,
                    359.021263289556,
                    671.5,
                    358.0206378986867,
                    671.5,
                    358.0206378986867,
                    671.5,
                    358.0206378986867,
                    672.5,
                    357.0200125078174,
                    672.5,
                    357.0200125078174,
                    672.5,
                    356.0193871169481,
                    672.5,
                    355.0187617260788,
                    672.5,
                    355.0187617260788,
                    672.5,
                    355.0187617260788,
                    673.5,
                    355.0187617260788,
                    673.5,
                    354.0181363352095,
                    673.5,
                    354.0181363352095,
                    673.5,
                    354.0181363352095,
                    673.5,
                    353.0175109443402,
                    673.5,
                    353.0175109443402,
                    673.5,
                    353.0175109443402,
                    674.5,
                    353.0175109443402,
                    674.5,
                    352.0168855534709,
                    674.5,
                    351.0162601626016,
                    674.5,
                    351.0162601626016,
                    674.5,
                    351.0162601626016,
                    674.5,
                    351.0162601626016,
                    674.5,
                    350.0156347717323,
                    674.5,
                    350.0156347717323,
                    674.5,
                    349.01500938086303,
                    674.5,
                    349.01500938086303,
                    674.5,
                    348.01438398999375,
                    674.5,
                    348.01438398999375,
                    674.5,
                    348.01438398999375,
                    674.5,
                    348.01438398999375,
                    675.5,
                    348.01438398999375,
                    675.5,
                    347.01375859912446,
                    675.5,
                    346.01313320825517,
                    675.5,
                    346.01313320825517,
                    675.5,
                    346.01313320825517,
                    676.5,
                    345.0125078173859,
                    676.5,
                    344.0118824265166,
                    676.5,
                    343.01125703564725,
                    676.5,
                    342.01063164477796,
                    677.5,
                    342.01063164477796,
                    678.5,
                    340.0093808630394,
                    678.5,
                    339.0087554721701,
                    679.5,
                    337.0075046904315,
                    679.5,
                    336.00687929956223,
                    679.5,
                    336.00687929956223,
                    680.5,
                    335.00625390869294,
                    680.5,
                    335.00625390869294,
                    680.5,
                    335.00625390869294,
                    680.5,
                    334.00562851782365,
                    681.5,
                    334.00562851782365,
                    681.5,
                    333.00500312695436,
                    681.5,
                    333.00500312695436,
                    681.5,
                    332.0043777360851,
                    681.5,
                    332.0043777360851,
                    681.5,
                    331.00375234521573,
                    681.5,
                    331.00375234521573
                ]
            },
            {
                "tool": "pen",
                "points": [
                    681.5,
                    313.99312070043777,
                    681.5,
                    313.99312070043777,
                    681.5,
                    313.99312070043777,
                    681.5,
                    314.99374609130706,
                    681.5,
                    315.99437148217635,
                    681.5,
                    315.99437148217635,
                    681.5,
                    316.99499687304564,
                    681.5,
                    317.9956222639149,
                    681.5,
                    317.9956222639149,
                    681.5,
                    318.9962476547842,
                    681.5,
                    318.9962476547842,
                    681.5,
                    319.9968730456535,
                    681.5,
                    319.9968730456535,
                    681.5,
                    320.99749843652285,
                    681.5,
                    321.99812382739213,
                    681.5,
                    322.9987492182614,
                    681.5,
                    322.9987492182614,
                    681.5,
                    323.9993746091307,
                    681.5,
                    325,
                    681.5,
                    325,
                    681.5,
                    326.0006253908693,
                    681.5,
                    327.0012507817386,
                    681.5,
                    328.00187617260787,
                    681.5,
                    329.00250156347715,
                    681.5,
                    330.00312695434644,
                    681.5,
                    330.00312695434644,
                    681.5,
                    331.00375234521573,
                    681.5,
                    332.0043777360851,
                    681.5,
                    333.00500312695436,
                    681.5,
                    334.00562851782365,
                    681.5,
                    337.0075046904315,
                    681.5,
                    339.0087554721701,
                    681.5,
                    340.0093808630394,
                    681.5,
                    341.01000625390867,
                    681.5,
                    342.01063164477796,
                    681.5,
                    342.01063164477796,
                    681.5,
                    343.01125703564725,
                    681.5,
                    344.0118824265166,
                    681.5,
                    346.01313320825517,
                    681.5,
                    346.01313320825517,
                    681.5,
                    347.01375859912446,
                    681.5,
                    347.01375859912446,
                    681.5,
                    348.01438398999375,
                    681.5,
                    348.01438398999375,
                    681.5,
                    348.01438398999375,
                    681.5,
                    349.01500938086303,
                    681.5,
                    350.0156347717323,
                    681.5,
                    350.0156347717323,
                    681.5,
                    351.0162601626016,
                    681.5,
                    352.0168855534709,
                    681.5,
                    352.0168855534709,
                    681.5,
                    353.0175109443402,
                    681.5,
                    354.0181363352095,
                    681.5,
                    354.0181363352095,
                    681.5,
                    355.0187617260788,
                    681.5,
                    356.0193871169481,
                    681.5,
                    356.0193871169481,
                    681.5,
                    356.0193871169481,
                    681.5,
                    357.0200125078174,
                    681.5,
                    358.0206378986867,
                    681.5,
                    359.021263289556,
                    681.5,
                    359.021263289556,
                    681.5,
                    359.021263289556,
                    681.5,
                    360.02188868042526,
                    681.5,
                    361.02251407129455,
                    681.5,
                    361.02251407129455,
                    681.5,
                    361.02251407129455,
                    681.5,
                    362.02313946216384,
                    681.5,
                    363.02376485303313,
                    681.5,
                    363.02376485303313,
                    680.5,
                    364.0243902439024,
                    680.5,
                    364.0243902439024,
                    680.5,
                    365.0250156347717,
                    680.5,
                    365.0250156347717,
                    680.5,
                    366.025641025641,
                    680.5,
                    367.02626641651034,
                    680.5,
                    367.02626641651034,
                    680.5,
                    368.0268918073796,
                    680.5,
                    370.0281425891182,
                    679.5,
                    370.0281425891182,
                    679.5,
                    370.0281425891182,
                    679.5,
                    371.0287679799875,
                    679.5,
                    371.0287679799875,
                    679.5,
                    372.0293933708568,
                    679.5,
                    372.0293933708568,
                    679.5,
                    373.03001876172607,
                    678.5,
                    374.03064415259536,
                    678.5,
                    375.03126954346465,
                    678.5,
                    375.03126954346465,
                    678.5,
                    377.0325203252032,
                    678.5,
                    378.03314571607257,
                    678.5,
                    379.03377110694186,
                    677.5,
                    379.03377110694186,
                    677.5,
                    380.03439649781114,
                    677.5,
                    380.03439649781114,
                    677.5,
                    381.03502188868043,
                    677.5,
                    382.0356472795497,
                    677.5,
                    382.0356472795497,
                    677.5,
                    383.036272670419,
                    677.5,
                    384.0368980612883,
                    676.5,
                    385.0375234521576,
                    676.5,
                    386.0381488430269,
                    676.5,
                    387.03877423389616,
                    675.5,
                    387.03877423389616,
                    675.5,
                    388.03939962476545,
                    675.5,
                    389.04002501563474,
                    675.5,
                    389.04002501563474,
                    675.5,
                    389.04002501563474,
                    675.5,
                    390.0406504065041,
                    675.5,
                    391.0412757973734,
                    675.5,
                    392.04190118824266,
                    675.5,
                    397.0450281425891,
                    674.5,
                    398.0456535334584,
                    674.5,
                    399.0462789243277,
                    674.5,
                    399.0462789243277,
                    674.5,
                    400.04690431519697,
                    674.5,
                    401.0475297060663,
                    674.5,
                    401.0475297060663,
                    674.5,
                    401.0475297060663,
                    673.5,
                    402.0481550969356,
                    673.5,
                    402.0481550969356,
                    672.5,
                    403.0487804878049,
                    672.5,
                    404.0494058786742,
                    672.5,
                    404.0494058786742,
                    672.5,
                    405.05003126954347,
                    671.5,
                    405.05003126954347,
                    671.5,
                    405.05003126954347,
                    671.5,
                    406.05065666041276,
                    670.5,
                    407.05128205128204,
                    670.5,
                    407.05128205128204,
                    670.5,
                    407.05128205128204,
                    670.5,
                    408.05190744215133,
                    670.5,
                    408.05190744215133,
                    670.5,
                    409.0525328330206,
                    669.5,
                    409.0525328330206,
                    669.5,
                    410.0531582238899,
                    668.5,
                    411.0537836147592,
                    668.5,
                    412.0544090056285,
                    668.5,
                    412.0544090056285,
                    667.5,
                    413.05503439649783,
                    667.5,
                    414.0556597873671,
                    666.5,
                    414.0556597873671,
                    666.5,
                    414.0556597873671,
                    666.5,
                    415.0562851782364,
                    666.5,
                    415.0562851782364,
                    665.5,
                    416.0569105691057,
                    664.5,
                    416.0569105691057,
                    664.5,
                    416.0569105691057,
                    663.5,
                    417.057535959975,
                    662.5,
                    419.05878674171356,
                    662.5,
                    419.05878674171356,
                    661.5,
                    419.05878674171356,
                    661.5,
                    420.05941213258285,
                    660.5,
                    420.05941213258285,
                    659.5,
                    421.06003752345214,
                    659.5,
                    421.06003752345214,
                    658.5,
                    421.06003752345214,
                    658.5,
                    422.0606629143214,
                    657.5,
                    422.0606629143214,
                    656.5,
                    423.0612883051907,
                    655.5,
                    424.06191369606006,
                    655.5,
                    424.06191369606006,
                    654.5,
                    425.06253908692935,
                    654.5,
                    425.06253908692935,
                    653.5,
                    425.06253908692935,
                    653.5,
                    425.06253908692935,
                    652.5,
                    426.06316447779864,
                    652.5,
                    426.06316447779864,
                    651.5,
                    426.06316447779864,
                    650.5,
                    427.0637898686679,
                    650.5,
                    427.0637898686679,
                    650.5,
                    427.0637898686679,
                    649.5,
                    427.0637898686679,
                    648.5,
                    427.0637898686679,
                    648.5,
                    428.0644152595372,
                    647.5,
                    428.0644152595372,
                    647.5,
                    428.0644152595372,
                    646.5,
                    428.0644152595372,
                    646.5,
                    428.0644152595372,
                    645.5,
                    428.0644152595372,
                    645.5,
                    428.0644152595372,
                    644.5,
                    428.0644152595372,
                    643.5,
                    428.0644152595372,
                    643.5,
                    428.0644152595372,
                    643.5,
                    428.0644152595372,
                    642.5,
                    428.0644152595372,
                    641.5,
                    428.0644152595372,
                    638.5,
                    428.0644152595372,
                    637.5,
                    428.0644152595372,
                    635.5,
                    428.0644152595372,
                    632.5,
                    428.0644152595372,
                    631.5,
                    428.0644152595372,
                    630.5,
                    428.0644152595372,
                    630.5,
                    428.0644152595372,
                    628.5,
                    428.0644152595372,
                    627.5,
                    428.0644152595372,
                    626.5,
                    428.0644152595372,
                    625.5,
                    428.0644152595372,
                    624.5,
                    428.0644152595372,
                    623.5,
                    428.0644152595372,
                    622.5,
                    428.0644152595372,
                    621.5,
                    427.0637898686679,
                    621.5,
                    427.0637898686679,
                    620.5,
                    426.06316447779864,
                    619.5,
                    425.06253908692935,
                    619.5,
                    425.06253908692935,
                    618.5,
                    425.06253908692935,
                    617.5,
                    424.06191369606006,
                    617.5,
                    424.06191369606006,
                    615.5,
                    423.0612883051907,
                    615.5,
                    422.0606629143214,
                    614.5,
                    420.05941213258285,
                    614.5,
                    420.05941213258285,
                    613.5,
                    419.05878674171356,
                    612.5,
                    418.0581613508443,
                    612.5,
                    418.0581613508443,
                    612.5,
                    417.057535959975,
                    612.5,
                    417.057535959975,
                    612.5,
                    416.0569105691057,
                    612.5,
                    416.0569105691057,
                    612.5,
                    415.0562851782364,
                    612.5,
                    415.0562851782364,
                    612.5,
                    414.0556597873671,
                    612.5,
                    413.05503439649783,
                    612.5,
                    413.05503439649783,
                    612.5,
                    412.0544090056285,
                    612.5,
                    411.0537836147592,
                    612.5,
                    410.0531582238899,
                    612.5,
                    409.0525328330206,
                    612.5,
                    408.05190744215133,
                    612.5,
                    408.05190744215133,
                    612.5,
                    408.05190744215133,
                    612.5,
                    407.05128205128204
                ]
            },
            {
                "tool": "pen",
                "points": [
                    570.5,
                    248.95247029393371,
                    570.5,
                    247.9518449030644,
                    570.5,
                    247.9518449030644,
                    571.5,
                    246.9512195121951,
                    572.5,
                    245.95059412132582,
                    573.5,
                    244.94996873045653,
                    573.5,
                    243.94934333958724,
                    575.5,
                    239.94684177611006,
                    576.5,
                    239.94684177611006,
                    577.5,
                    237.94559099437149,
                    578.5,
                    235.94434021263288,
                    579.5,
                    234.9437148217636,
                    580.5,
                    233.9430894308943,
                    581.5,
                    233.9430894308943,
                    582.5,
                    232.94246404002502,
                    582.5,
                    231.94183864915573,
                    583.5,
                    231.94183864915573,
                    583.5,
                    230.94121325828644,
                    585.5,
                    229.94058786741712,
                    587.5,
                    229.94058786741712,
                    588.5,
                    228.93996247654783,
                    589.5,
                    227.93933708567855,
                    589.5,
                    227.93933708567855,
                    590.5,
                    226.93871169480926,
                    591.5,
                    225.93808630393997,
                    592.5,
                    223.93683552220136,
                    592.5,
                    223.93683552220136,
                    592.5,
                    222.93621013133207,
                    593.5,
                    222.93621013133207,
                    593.5,
                    221.9355847404628,
                    594.5,
                    221.9355847404628,
                    595.5,
                    220.9349593495935,
                    595.5,
                    219.9343339587242,
                    596.5,
                    217.9330831769856,
                    597.5,
                    216.93245778611632,
                    598.5,
                    215.93183239524703,
                    598.5,
                    213.93058161350845,
                    600.5,
                    211.92933083176985,
                    602.5,
                    207.9268292682927,
                    603.5,
                    205.9255784865541,
                    604.5,
                    205.9255784865541,
                    605.5,
                    203.9243277048155,
                    605.5,
                    201.92307692307693,
                    607.5,
                    197.92057535959975,
                    608.5,
                    195.91932457786115,
                    610.5,
                    194.91869918699186,
                    610.5,
                    192.91744840525328,
                    611.5,
                    190.9161976235147,
                    613.5,
                    188.9149468417761,
                    614.5,
                    186.91369606003752,
                    615.5,
                    185.91307066916823,
                    616.5,
                    183.91181988742963,
                    617.5,
                    181.91056910569105,
                    618.5,
                    179.90931832395248,
                    619.5,
                    177.90806754221387,
                    621.5,
                    174.906191369606,
                    621.5,
                    172.9049405878674,
                    622.5,
                    170.90368980612882,
                    622.5,
                    168.90243902439025,
                    623.5,
                    166.90118824265164,
                    624.5,
                    165.90056285178235,
                    624.5,
                    164.89993746091307,
                    624.5,
                    162.8986866791745,
                    624.5,
                    162.8986866791745,
                    624.5,
                    162.8986866791745,
                    624.5,
                    161.8980612883052,
                    624.5,
                    160.89743589743588,
                    624.5,
                    160.89743589743588,
                    624.5,
                    159.8968105065666,
                    624.5,
                    159.8968105065666,
                    625.5,
                    159.8968105065666,
                    625.5,
                    158.8961851156973,
                    625.5,
                    158.8961851156973,
                    625.5,
                    158.8961851156973,
                    625.5,
                    157.89555972482802,
                    625.5,
                    157.89555972482802,
                    624.5,
                    158.8961851156973,
                    623.5,
                    160.89743589743588,
                    622.5,
                    160.89743589743588,
                    621.5,
                    161.8980612883052,
                    617.5,
                    164.89993746091307,
                    617.5,
                    164.89993746091307,
                    615.5,
                    165.90056285178235,
                    614.5,
                    165.90056285178235,
                    614.5,
                    165.90056285178235,
                    613.5,
                    167.90181363352096,
                    612.5,
                    168.90243902439025,
                    612.5,
                    168.90243902439025,
                    611.5,
                    168.90243902439025,
                    611.5,
                    169.90306441525954,
                    611.5,
                    169.90306441525954,
                    610.5,
                    170.90368980612882,
                    610.5,
                    170.90368980612882,
                    609.5,
                    170.90368980612882,
                    609.5,
                    171.9043151969981,
                    608.5,
                    171.9043151969981,
                    608.5,
                    172.9049405878674,
                    607.5,
                    172.9049405878674,
                    607.5,
                    172.9049405878674,
                    606.5,
                    173.90556597873672,
                    606.5,
                    173.90556597873672,
                    605.5,
                    173.90556597873672,
                    604.5,
                    174.906191369606,
                    604.5,
                    174.906191369606
                ]
            },
            {
                "tool": "pen",
                "points": [
                    632.5,
                    146.8886804252658,
                    632.5,
                    147.88930581613508,
                    632.5,
                    147.88930581613508,
                    632.5,
                    148.88993120700437,
                    632.5,
                    149.89055659787368,
                    632.5,
                    149.89055659787368,
                    632.5,
                    150.89118198874297,
                    630.5,
                    151.89180737961226,
                    629.5,
                    152.89243277048155,
                    629.5,
                    152.89243277048155,
                    629.5,
                    152.89243277048155,
                    628.5,
                    153.89305816135084,
                    628.5,
                    154.89368355222012,
                    628.5,
                    154.89368355222012,
                    628.5,
                    154.89368355222012,
                    627.5,
                    155.89430894308944,
                    627.5,
                    156.89493433395873,
                    626.5,
                    156.89493433395873,
                    626.5,
                    157.89555972482802,
                    625.5,
                    158.8961851156973,
                    624.5,
                    158.8961851156973,
                    624.5,
                    158.8961851156973,
                    624.5,
                    159.8968105065666,
                    623.5,
                    159.8968105065666,
                    623.5,
                    160.89743589743588,
                    622.5,
                    160.89743589743588,
                    622.5,
                    160.89743589743588,
                    621.5,
                    160.89743589743588,
                    621.5,
                    161.8980612883052,
                    620.5,
                    161.8980612883052,
                    620.5,
                    161.8980612883052,
                    620.5,
                    162.8986866791745,
                    619.5,
                    162.8986866791745,
                    617.5,
                    163.89931207004378,
                    617.5,
                    163.89931207004378,
                    617.5,
                    164.89993746091307,
                    616.5,
                    164.89993746091307,
                    616.5,
                    164.89993746091307,
                    615.5,
                    165.90056285178235,
                    615.5,
                    165.90056285178235,
                    615.5,
                    165.90056285178235,
                    614.5,
                    166.90118824265164,
                    613.5,
                    166.90118824265164,
                    613.5,
                    166.90118824265164,
                    613.5,
                    167.90181363352096,
                    612.5,
                    167.90181363352096,
                    611.5,
                    168.90243902439025,
                    611.5,
                    168.90243902439025,
                    609.5,
                    169.90306441525954,
                    609.5,
                    170.90368980612882,
                    608.5,
                    170.90368980612882,
                    607.5,
                    170.90368980612882,
                    607.5,
                    171.9043151969981,
                    606.5,
                    171.9043151969981,
                    606.5,
                    172.9049405878674,
                    606.5,
                    172.9049405878674,
                    605.5,
                    172.9049405878674,
                    604.5,
                    172.9049405878674,
                    604.5,
                    173.90556597873672,
                    604.5,
                    173.90556597873672,
                    603.5,
                    174.906191369606,
                    603.5,
                    174.906191369606,
                    602.5,
                    174.906191369606,
                    602.5,
                    174.906191369606,
                    601.5,
                    175.9068167604753,
                    601.5,
                    175.9068167604753,
                    600.5,
                    175.9068167604753,
                    600.5,
                    176.90744215134458,
                    600.5,
                    176.90744215134458,
                    599.5,
                    176.90744215134458,
                    598.5,
                    177.90806754221387,
                    598.5,
                    178.9086929330832,
                    597.5,
                    178.9086929330832,
                    597.5,
                    178.9086929330832,
                    596.5,
                    178.9086929330832,
                    596.5,
                    178.9086929330832,
                    596.5,
                    178.9086929330832,
                    595.5,
                    179.90931832395248,
                    595.5,
                    179.90931832395248,
                    594.5,
                    180.90994371482176,
                    594.5,
                    180.90994371482176,
                    593.5,
                    180.90994371482176
                ]
            },
            {
                "tool": "pen",
                "points": [
                    636.5,
                    145.8880550343965,
                    636.5,
                    145.8880550343965,
                    636.5,
                    145.8880550343965,
                    636.5,
                    146.8886804252658,
                    636.5,
                    147.88930581613508,
                    636.5,
                    147.88930581613508,
                    636.5,
                    148.88993120700437,
                    636.5,
                    148.88993120700437,
                    636.5,
                    149.89055659787368,
                    636.5,
                    149.89055659787368,
                    636.5,
                    149.89055659787368,
                    636.5,
                    150.89118198874297,
                    636.5,
                    151.89180737961226,
                    636.5,
                    151.89180737961226,
                    635.5,
                    151.89180737961226,
                    634.5,
                    151.89180737961226,
                    634.5,
                    152.89243277048155,
                    634.5,
                    152.89243277048155,
                    634.5,
                    153.89305816135084,
                    634.5,
                    153.89305816135084,
                    634.5,
                    154.89368355222012,
                    634.5,
                    154.89368355222012,
                    634.5,
                    155.89430894308944,
                    634.5,
                    156.89493433395873,
                    634.5,
                    156.89493433395873,
                    634.5,
                    156.89493433395873,
                    634.5,
                    157.89555972482802,
                    634.5,
                    158.8961851156973,
                    633.5,
                    159.8968105065666,
                    633.5,
                    160.89743589743588,
                    633.5,
                    160.89743589743588,
                    633.5,
                    161.8980612883052,
                    633.5,
                    161.8980612883052,
                    633.5,
                    161.8980612883052,
                    633.5,
                    162.8986866791745,
                    632.5,
                    163.89931207004378,
                    632.5,
                    163.89931207004378,
                    632.5,
                    164.89993746091307,
                    632.5,
                    164.89993746091307,
                    632.5,
                    165.90056285178235,
                    632.5,
                    165.90056285178235,
                    632.5,
                    165.90056285178235,
                    632.5,
                    166.90118824265164,
                    632.5,
                    167.90181363352096,
                    629.5,
                    169.90306441525954,
                    629.5,
                    170.90368980612882,
                    629.5,
                    170.90368980612882,
                    628.5,
                    171.9043151969981,
                    628.5,
                    172.9049405878674,
                    628.5,
                    172.9049405878674,
                    628.5,
                    172.9049405878674,
                    628.5,
                    173.90556597873672,
                    628.5,
                    173.90556597873672,
                    628.5,
                    174.906191369606,
                    628.5,
                    174.906191369606,
                    628.5,
                    174.906191369606,
                    627.5,
                    175.9068167604753,
                    627.5,
                    175.9068167604753,
                    627.5,
                    176.90744215134458,
                    627.5,
                    176.90744215134458,
                    627.5,
                    177.90806754221387,
                    627.5,
                    177.90806754221387,
                    627.5,
                    178.9086929330832,
                    627.5,
                    178.9086929330832,
                    627.5,
                    179.90931832395248,
                    627.5,
                    180.90994371482176,
                    627.5,
                    180.90994371482176,
                    627.5,
                    180.90994371482176,
                    627.5,
                    181.91056910569105,
                    627.5,
                    181.91056910569105,
                    627.5,
                    182.91119449656034,
                    627.5,
                    182.91119449656034,
                    627.5,
                    183.91181988742963,
                    627.5,
                    183.91181988742963,
                    627.5,
                    184.91244527829895,
                    627.5,
                    185.91307066916823,
                    627.5,
                    185.91307066916823,
                    627.5,
                    186.91369606003752,
                    627.5,
                    186.91369606003752,
                    627.5,
                    187.9143214509068,
                    627.5,
                    187.9143214509068,
                    627.5,
                    188.9149468417761,
                    627.5,
                    188.9149468417761,
                    628.5,
                    189.9155722326454,
                    629.5,
                    189.9155722326454,
                    629.5,
                    189.9155722326454,
                    629.5,
                    189.9155722326454,
                    630.5,
                    190.9161976235147,
                    630.5,
                    190.9161976235147,
                    631.5,
                    191.916823014384,
                    631.5,
                    191.916823014384
                ]
            }
        ]
    }else{
        return [
            {
                "tool": "pen",
                "points": [
                    34.32873563218391,
                    54.91236268560613,
                    34.32873563218391,
                    53.91210903201888,
                    34.32873563218391,
                    53.91210903201888,
                    34.32873563218391,
                    52.91185537843164,
                    34.32873563218391,
                    52.91185537843164,
                    34.32873563218391,
                    51.91160172484439,
                    34.32873563218391,
                    51.91160172484439,
                    34.32873563218391,
                    50.911348071257144,
                    33.326436781609196,
                    49.911094417669894,
                    33.326436781609196,
                    49.911094417669894,
                    32.324137931034485,
                    48.91084076408265,
                    32.324137931034485,
                    48.91084076408265,
                    32.324137931034485,
                    48.91084076408265,
                    31.32183908045977,
                    48.91084076408265,
                    31.32183908045977,
                    48.91084076408265,
                    29.317241379310346,
                    46.910333456908155,
                    28.314942528735635,
                    46.910333456908155,
                    28.314942528735635,
                    46.910333456908155,
                    28.314942528735635,
                    46.910333456908155,
                    27.31264367816092,
                    45.91007980332091,
                    27.31264367816092,
                    45.91007980332091,
                    26.310344827586206,
                    45.91007980332091,
                    26.310344827586206,
                    45.91007980332091,
                    25.308045977011496,
                    45.91007980332091,
                    23.30344827586207,
                    45.91007980332091,
                    23.30344827586207,
                    45.91007980332091,
                    22.301149425287356,
                    45.91007980332091,
                    22.301149425287356,
                    45.91007980332091,
                    21.298850574712645,
                    45.91007980332091,
                    21.298850574712645,
                    45.91007980332091,
                    20.29655172413793,
                    45.91007980332091,
                    20.29655172413793,
                    45.91007980332091,
                    19.29425287356322,
                    45.91007980332091,
                    18.291954022988506,
                    45.91007980332091,
                    18.291954022988506,
                    45.91007980332091,
                    17.289655172413795,
                    45.91007980332091,
                    16.28735632183908,
                    45.91007980332091,
                    16.28735632183908,
                    45.91007980332091,
                    16.28735632183908,
                    45.91007980332091,
                    15.285057471264368,
                    45.91007980332091,
                    15.285057471264368,
                    45.91007980332091,
                    14.282758620689656,
                    45.91007980332091,
                    14.282758620689656,
                    45.91007980332091,
                    14.282758620689656,
                    45.91007980332091,
                    13.280459770114943,
                    46.910333456908155,
                    12.27816091954023,
                    46.910333456908155,
                    12.27816091954023,
                    46.910333456908155,
                    12.27816091954023,
                    46.910333456908155,
                    11.275862068965518,
                    47.9105871104954,
                    11.275862068965518,
                    47.9105871104954,
                    11.275862068965518,
                    48.91084076408265,
                    11.275862068965518,
                    48.91084076408265,
                    10.273563218390805,
                    49.911094417669894,
                    10.273563218390805,
                    49.911094417669894,
                    10.273563218390805,
                    50.911348071257144,
                    10.273563218390805,
                    51.91160172484439,
                    10.273563218390805,
                    51.91160172484439,
                    9.271264367816093,
                    51.91160172484439,
                    8.26896551724138,
                    51.91160172484439,
                    8.26896551724138,
                    52.91185537843164,
                    8.26896551724138,
                    53.91210903201888,
                    7.266666666666667,
                    53.91210903201888,
                    7.266666666666667,
                    53.91210903201888,
                    7.266666666666667,
                    54.91236268560613,
                    7.266666666666667,
                    54.91236268560613,
                    7.266666666666667,
                    55.91261633919338,
                    7.266666666666667,
                    55.91261633919338,
                    7.266666666666667,
                    55.91261633919338,
                    7.266666666666667,
                    56.91286999278063,
                    7.266666666666667,
                    57.91312364636787,
                    6.264367816091954,
                    58.91337729995512,
                    5.2620689655172415,
                    59.913630953542366,
                    5.2620689655172415,
                    59.913630953542366,
                    5.2620689655172415,
                    60.91388460712962,
                    5.2620689655172415,
                    60.91388460712962,
                    5.2620689655172415,
                    61.91413826071686,
                    5.2620689655172415,
                    62.914391914304105,
                    4.259770114942529,
                    62.914391914304105,
                    4.259770114942529,
                    62.914391914304105,
                    4.259770114942529,
                    63.914645567891355,
                    4.259770114942529,
                    65.91515287506584,
                    4.259770114942529,
                    65.91515287506584,
                    4.259770114942529,
                    65.91515287506584,
                    4.259770114942529,
                    66.9154065286531,
                    3.2574712643678163,
                    66.9154065286531,
                    3.2574712643678163,
                    67.91566018224034,
                    3.2574712643678163,
                    67.91566018224034,
                    3.2574712643678163,
                    68.9159138358276,
                    3.2574712643678163,
                    68.9159138358276,
                    3.2574712643678163,
                    68.9159138358276,
                    3.2574712643678163,
                    69.91616748941483,
                    3.2574712643678163,
                    69.91616748941483,
                    3.2574712643678163,
                    70.91642114300208,
                    3.2574712643678163,
                    70.91642114300208,
                    3.2574712643678163,
                    71.91667479658933,
                    3.2574712643678163,
                    71.91667479658933,
                    4.259770114942529,
                    73.91718210376382,
                    4.259770114942529,
                    73.91718210376382,
                    4.259770114942529,
                    74.91743575735107,
                    5.2620689655172415,
                    75.91768941093832,
                    5.2620689655172415,
                    75.91768941093832,
                    6.264367816091954,
                    76.91794306452556,
                    6.264367816091954,
                    76.91794306452556,
                    7.266666666666667,
                    76.91794306452556,
                    7.266666666666667,
                    76.91794306452556,
                    8.26896551724138,
                    76.91794306452556,
                    9.271264367816093,
                    76.91794306452556,
                    10.273563218390805,
                    76.91794306452556,
                    10.273563218390805,
                    76.91794306452556,
                    11.275862068965518,
                    76.91794306452556,
                    12.27816091954023,
                    76.91794306452556,
                    12.27816091954023,
                    76.91794306452556,
                    13.280459770114943,
                    76.91794306452556,
                    13.280459770114943,
                    76.91794306452556,
                    13.280459770114943,
                    76.91794306452556,
                    14.282758620689656,
                    76.91794306452556,
                    14.282758620689656,
                    76.91794306452556,
                    15.285057471264368,
                    76.91794306452556,
                    15.285057471264368,
                    76.91794306452556,
                    16.28735632183908,
                    76.91794306452556,
                    17.289655172413795,
                    76.91794306452556,
                    17.289655172413795,
                    76.91794306452556,
                    18.291954022988506,
                    76.91794306452556,
                    18.291954022988506,
                    76.91794306452556,
                    19.29425287356322,
                    76.91794306452556,
                    20.29655172413793,
                    76.91794306452556,
                    20.29655172413793,
                    76.91794306452556,
                    21.298850574712645,
                    76.91794306452556,
                    21.298850574712645,
                    76.91794306452556,
                    22.301149425287356,
                    76.91794306452556,
                    22.301149425287356,
                    76.91794306452556,
                    23.30344827586207,
                    76.91794306452556,
                    23.30344827586207,
                    76.91794306452556,
                    24.30574712643678,
                    76.91794306452556,
                    24.30574712643678,
                    76.91794306452556,
                    25.308045977011496,
                    76.91794306452556,
                    26.310344827586206,
                    76.91794306452556,
                    26.310344827586206,
                    76.91794306452556,
                    26.310344827586206,
                    76.91794306452556,
                    27.31264367816092,
                    76.91794306452556,
                    28.314942528735635,
                    76.91794306452556,
                    29.317241379310346,
                    76.91794306452556,
                    29.317241379310346,
                    76.91794306452556,
                    30.31954022988506,
                    77.91819671811281,
                    30.31954022988506,
                    77.91819671811281,
                    31.32183908045977,
                    77.91819671811281,
                    32.324137931034485,
                    77.91819671811281,
                    32.324137931034485,
                    77.91819671811281,
                    32.324137931034485,
                    77.91819671811281,
                    33.326436781609196,
                    78.91845037170006,
                    34.32873563218391,
                    79.91870402528731,
                    34.32873563218391,
                    79.91870402528731,
                    34.32873563218391,
                    79.91870402528731,
                    34.32873563218391,
                    80.91895767887455,
                    35.331034482758625,
                    82.91946498604905,
                    35.331034482758625,
                    82.91946498604905,
                    35.331034482758625,
                    82.91946498604905,
                    35.331034482758625,
                    83.9197186396363,
                    35.331034482758625,
                    84.91997229322354,
                    35.331034482758625,
                    84.91997229322354,
                    35.331034482758625,
                    85.92022594681079,
                    35.331034482758625,
                    85.92022594681079,
                    35.331034482758625,
                    86.92047960039804,
                    35.331034482758625,
                    87.92073325398529,
                    35.331034482758625,
                    87.92073325398529,
                    35.331034482758625,
                    88.92098690757253,
                    35.331034482758625,
                    89.92124056115978,
                    35.331034482758625,
                    90.92149421474703,
                    35.331034482758625,
                    91.92174786833426,
                    35.331034482758625,
                    92.92200152192152,
                    35.331034482758625,
                    92.92200152192152,
                    35.331034482758625,
                    93.92225517550877,
                    35.331034482758625,
                    93.92225517550877,
                    35.331034482758625,
                    94.92250882909602,
                    35.331034482758625,
                    94.92250882909602,
                    35.331034482758625,
                    94.92250882909602,
                    35.331034482758625,
                    95.92276248268325,
                    35.331034482758625,
                    95.92276248268325,
                    35.331034482758625,
                    96.9230161362705,
                    34.32873563218391,
                    96.9230161362705,
                    34.32873563218391,
                    97.92326978985776,
                    33.326436781609196,
                    97.92326978985776,
                    33.326436781609196,
                    98.923523443445,
                    32.324137931034485,
                    98.923523443445,
                    32.324137931034485,
                    98.923523443445,
                    31.32183908045977,
                    99.92377709703224,
                    31.32183908045977,
                    99.92377709703224,
                    30.31954022988506,
                    99.92377709703224,
                    30.31954022988506,
                    100.9240307506195,
                    29.317241379310346,
                    100.9240307506195,
                    29.317241379310346,
                    100.9240307506195,
                    27.31264367816092,
                    101.92428440420674,
                    27.31264367816092,
                    101.92428440420674,
                    26.310344827586206,
                    101.92428440420674,
                    25.308045977011496,
                    101.92428440420674,
                    25.308045977011496,
                    101.92428440420674,
                    25.308045977011496,
                    102.924538057794,
                    24.30574712643678,
                    102.924538057794,
                    24.30574712643678,
                    102.924538057794,
                    22.301149425287356,
                    102.924538057794,
                    22.301149425287356,
                    102.924538057794,
                    21.298850574712645,
                    102.924538057794,
                    21.298850574712645,
                    102.924538057794,
                    20.29655172413793,
                    102.924538057794,
                    20.29655172413793,
                    103.92479171138123,
                    19.29425287356322,
                    103.92479171138123,
                    18.291954022988506,
                    103.92479171138123,
                    18.291954022988506,
                    103.92479171138123,
                    18.291954022988506,
                    103.92479171138123,
                    17.289655172413795,
                    103.92479171138123,
                    16.28735632183908,
                    103.92479171138123,
                    16.28735632183908,
                    103.92479171138123,
                    15.285057471264368,
                    103.92479171138123,
                    15.285057471264368,
                    104.92504536496848,
                    13.280459770114943,
                    105.92529901855573,
                    12.27816091954023,
                    105.92529901855573,
                    12.27816091954023,
                    105.92529901855573,
                    11.275862068965518,
                    105.92529901855573,
                    7.266666666666667,
                    105.92529901855573,
                    7.266666666666667,
                    105.92529901855573
                ]
            },
            {
                "tool": "pen",
                "points": [
                    64.39770114942529,
                    43.90957249614642,
                    64.39770114942529,
                    43.90957249614642,
                    65.4,
                    43.90957249614642,
                    65.4,
                    44.90982614973366,
                    65.4,
                    45.91007980332091,
                    65.4,
                    45.91007980332091,
                    65.4,
                    46.910333456908155,
                    65.4,
                    49.911094417669894,
                    65.4,
                    50.911348071257144,
                    65.4,
                    51.91160172484439,
                    65.4,
                    51.91160172484439,
                    65.4,
                    52.91185537843164,
                    65.4,
                    53.91210903201888,
                    65.4,
                    53.91210903201888,
                    65.4,
                    53.91210903201888,
                    65.4,
                    54.91236268560613,
                    65.4,
                    54.91236268560613,
                    65.4,
                    55.91261633919338,
                    65.4,
                    55.91261633919338,
                    65.4,
                    55.91261633919338,
                    65.4,
                    56.91286999278063,
                    65.4,
                    56.91286999278063,
                    65.4,
                    57.91312364636787,
                    65.4,
                    57.91312364636787,
                    65.4,
                    57.91312364636787,
                    65.4,
                    58.91337729995512,
                    65.4,
                    59.913630953542366,
                    65.4,
                    59.913630953542366,
                    65.4,
                    60.91388460712962,
                    65.4,
                    60.91388460712962,
                    65.4,
                    61.91413826071686,
                    65.4,
                    61.91413826071686,
                    65.4,
                    62.914391914304105,
                    65.4,
                    63.914645567891355,
                    65.4,
                    63.914645567891355,
                    65.4,
                    64.9148992214786,
                    65.4,
                    64.9148992214786,
                    65.4,
                    65.91515287506584,
                    65.4,
                    66.9154065286531,
                    65.4,
                    66.9154065286531,
                    65.4,
                    67.91566018224034,
                    65.4,
                    67.91566018224034,
                    65.4,
                    68.9159138358276,
                    65.4,
                    68.9159138358276,
                    65.4,
                    69.91616748941483,
                    65.4,
                    70.91642114300208,
                    65.4,
                    70.91642114300208,
                    64.39770114942529,
                    70.91642114300208,
                    64.39770114942529,
                    71.91667479658933,
                    64.39770114942529,
                    77.91819671811281,
                    64.39770114942529,
                    77.91819671811281,
                    64.39770114942529,
                    78.91845037170006,
                    64.39770114942529,
                    79.91870402528731,
                    64.39770114942529,
                    79.91870402528731,
                    64.39770114942529,
                    81.9192113324618,
                    64.39770114942529,
                    82.91946498604905,
                    64.39770114942529,
                    83.9197186396363,
                    64.39770114942529,
                    84.91997229322354,
                    64.39770114942529,
                    85.92022594681079,
                    64.39770114942529,
                    86.92047960039804,
                    64.39770114942529,
                    87.92073325398529,
                    64.39770114942529,
                    89.92124056115978,
                    64.39770114942529,
                    89.92124056115978,
                    64.39770114942529,
                    91.92174786833426,
                    64.39770114942529,
                    91.92174786833426,
                    64.39770114942529,
                    92.92200152192152,
                    64.39770114942529,
                    93.92225517550877,
                    64.39770114942529,
                    94.92250882909602,
                    64.39770114942529,
                    95.92276248268325,
                    64.39770114942529,
                    97.92326978985776,
                    64.39770114942529,
                    98.923523443445,
                    64.39770114942529,
                    99.92377709703224,
                    64.39770114942529,
                    99.92377709703224,
                    64.39770114942529,
                    99.92377709703224,
                    64.39770114942529,
                    100.9240307506195,
                    64.39770114942529,
                    100.9240307506195,
                    64.39770114942529,
                    101.92428440420674,
                    64.39770114942529,
                    101.92428440420674,
                    64.39770114942529,
                    100.9240307506195,
                    64.39770114942529,
                    99.92377709703224
                ]
            },
            {
                "tool": "pen",
                "points": [
                    87.45057471264369,
                    65.91515287506584,
                    86.44827586206897,
                    65.91515287506584,
                    86.44827586206897,
                    65.91515287506584,
                    85.44597701149425,
                    65.91515287506584,
                    85.44597701149425,
                    65.91515287506584,
                    84.44367816091955,
                    65.91515287506584,
                    83.44137931034483,
                    65.91515287506584,
                    83.44137931034483,
                    65.91515287506584,
                    82.43908045977012,
                    65.91515287506584,
                    80.43448275862069,
                    65.91515287506584,
                    80.43448275862069,
                    65.91515287506584,
                    79.43218390804599,
                    65.91515287506584,
                    78.42988505747127,
                    65.91515287506584,
                    78.42988505747127,
                    65.91515287506584,
                    76.42528735632185,
                    65.91515287506584,
                    75.42298850574713,
                    65.91515287506584,
                    72.41609195402299,
                    66.9154065286531,
                    68.40689655172415,
                    66.9154065286531,
                    67.40459770114943,
                    66.9154065286531,
                    66.40229885057471,
                    66.9154065286531,
                    65.4,
                    66.9154065286531,
                    61.390804597701155,
                    67.91566018224034,
                    60.38850574712644,
                    67.91566018224034,
                    60.38850574712644,
                    67.91566018224034,
                    60.38850574712644,
                    67.91566018224034,
                    59.38620689655173,
                    67.91566018224034,
                    57.3816091954023,
                    67.91566018224034,
                    57.3816091954023,
                    67.91566018224034,
                    57.3816091954023,
                    67.91566018224034,
                    56.37931034482759,
                    67.91566018224034,
                    56.37931034482759,
                    67.91566018224034,
                    55.377011494252876,
                    67.91566018224034,
                    55.377011494252876,
                    67.91566018224034,
                    54.374712643678166,
                    67.91566018224034,
                    53.37241379310345,
                    67.91566018224034,
                    53.37241379310345,
                    67.91566018224034
                ]
            },
            {
                "tool": "pen",
                "points": [
                    111.50574712643679,
                    75.91768941093832,
                    111.50574712643679,
                    75.91768941093832,
                    111.50574712643679,
                    74.91743575735107,
                    110.50344827586207,
                    74.91743575735107,
                    110.50344827586207,
                    74.91743575735107,
                    110.50344827586207,
                    74.91743575735107,
                    109.50114942528737,
                    74.91743575735107,
                    109.50114942528737,
                    74.91743575735107,
                    108.49885057471265,
                    74.91743575735107,
                    108.49885057471265,
                    74.91743575735107,
                    107.49655172413793,
                    74.91743575735107,
                    107.49655172413793,
                    74.91743575735107,
                    106.49425287356323,
                    74.91743575735107,
                    106.49425287356323,
                    75.91768941093832,
                    105.49195402298851,
                    75.91768941093832,
                    105.49195402298851,
                    75.91768941093832,
                    105.49195402298851,
                    76.91794306452556,
                    104.48965517241379,
                    76.91794306452556,
                    103.48735632183909,
                    76.91794306452556,
                    103.48735632183909,
                    76.91794306452556,
                    103.48735632183909,
                    76.91794306452556,
                    102.48505747126437,
                    76.91794306452556,
                    102.48505747126437,
                    77.91819671811281,
                    101.48275862068967,
                    77.91819671811281,
                    101.48275862068967,
                    78.91845037170006,
                    101.48275862068967,
                    78.91845037170006,
                    100.48045977011495,
                    79.91870402528731,
                    99.47816091954023,
                    79.91870402528731,
                    99.47816091954023,
                    79.91870402528731,
                    99.47816091954023,
                    81.9192113324618,
                    98.47586206896553,
                    81.9192113324618,
                    98.47586206896553,
                    82.91946498604905,
                    98.47586206896553,
                    82.91946498604905,
                    98.47586206896553,
                    83.9197186396363,
                    98.47586206896553,
                    83.9197186396363,
                    98.47586206896553,
                    84.91997229322354,
                    98.47586206896553,
                    84.91997229322354,
                    98.47586206896553,
                    84.91997229322354,
                    98.47586206896553,
                    85.92022594681079,
                    98.47586206896553,
                    86.92047960039804,
                    98.47586206896553,
                    86.92047960039804,
                    97.47356321839081,
                    86.92047960039804,
                    97.47356321839081,
                    88.92098690757253,
                    97.47356321839081,
                    89.92124056115978,
                    97.47356321839081,
                    89.92124056115978,
                    97.47356321839081,
                    89.92124056115978,
                    97.47356321839081,
                    90.92149421474703,
                    97.47356321839081,
                    91.92174786833426,
                    97.47356321839081,
                    91.92174786833426,
                    97.47356321839081,
                    91.92174786833426,
                    97.47356321839081,
                    92.92200152192152,
                    97.47356321839081,
                    92.92200152192152,
                    97.47356321839081,
                    93.92225517550877,
                    97.47356321839081,
                    93.92225517550877,
                    97.47356321839081,
                    93.92225517550877,
                    97.47356321839081,
                    94.92250882909602,
                    97.47356321839081,
                    94.92250882909602,
                    97.47356321839081,
                    95.92276248268325,
                    97.47356321839081,
                    96.9230161362705,
                    98.47586206896553,
                    96.9230161362705,
                    98.47586206896553,
                    96.9230161362705,
                    99.47816091954023,
                    97.92326978985776,
                    99.47816091954023,
                    97.92326978985776,
                    100.48045977011495,
                    97.92326978985776,
                    100.48045977011495,
                    98.923523443445,
                    101.48275862068967,
                    98.923523443445,
                    101.48275862068967,
                    98.923523443445,
                    101.48275862068967,
                    98.923523443445,
                    102.48505747126437,
                    98.923523443445,
                    103.48735632183909,
                    98.923523443445,
                    103.48735632183909,
                    98.923523443445,
                    104.48965517241379,
                    98.923523443445,
                    104.48965517241379,
                    98.923523443445,
                    105.49195402298851,
                    98.923523443445,
                    105.49195402298851,
                    98.923523443445,
                    106.49425287356323,
                    98.923523443445,
                    106.49425287356323,
                    98.923523443445,
                    107.49655172413793,
                    98.923523443445,
                    107.49655172413793,
                    98.923523443445,
                    107.49655172413793,
                    98.923523443445,
                    108.49885057471265,
                    98.923523443445,
                    108.49885057471265,
                    98.923523443445,
                    109.50114942528737,
                    98.923523443445,
                    109.50114942528737,
                    98.923523443445,
                    110.50344827586207,
                    98.923523443445,
                    110.50344827586207,
                    98.923523443445,
                    111.50574712643679,
                    98.923523443445,
                    111.50574712643679,
                    98.923523443445,
                    112.5080459770115,
                    98.923523443445,
                    112.5080459770115,
                    98.923523443445,
                    113.51034482758621,
                    98.923523443445,
                    114.51264367816093,
                    98.923523443445,
                    114.51264367816093,
                    98.923523443445,
                    115.51494252873563,
                    97.92326978985776,
                    115.51494252873563,
                    97.92326978985776,
                    116.51724137931035,
                    96.9230161362705,
                    116.51724137931035,
                    95.92276248268325,
                    117.51954022988507,
                    95.92276248268325,
                    118.52183908045977,
                    94.92250882909602,
                    118.52183908045977,
                    94.92250882909602,
                    118.52183908045977,
                    93.92225517550877,
                    119.52413793103449,
                    93.92225517550877,
                    120.5264367816092,
                    92.92200152192152,
                    120.5264367816092,
                    92.92200152192152,
                    120.5264367816092,
                    91.92174786833426,
                    121.52873563218391,
                    91.92174786833426,
                    122.53103448275863,
                    90.92149421474703,
                    122.53103448275863,
                    89.92124056115978,
                    122.53103448275863,
                    89.92124056115978,
                    122.53103448275863,
                    89.92124056115978,
                    122.53103448275863,
                    88.92098690757253,
                    122.53103448275863,
                    88.92098690757253,
                    123.53333333333333,
                    87.92073325398529,
                    123.53333333333333,
                    87.92073325398529,
                    123.53333333333333,
                    86.92047960039804,
                    123.53333333333333,
                    86.92047960039804,
                    124.53563218390805,
                    86.92047960039804,
                    124.53563218390805,
                    85.92022594681079,
                    124.53563218390805,
                    84.91997229322354,
                    124.53563218390805,
                    84.91997229322354,
                    124.53563218390805,
                    84.91997229322354,
                    124.53563218390805,
                    83.9197186396363,
                    124.53563218390805,
                    82.91946498604905,
                    124.53563218390805,
                    82.91946498604905,
                    124.53563218390805,
                    82.91946498604905,
                    124.53563218390805,
                    81.9192113324618,
                    124.53563218390805,
                    81.9192113324618,
                    124.53563218390805,
                    80.91895767887455,
                    124.53563218390805,
                    80.91895767887455,
                    124.53563218390805,
                    80.91895767887455,
                    124.53563218390805,
                    79.91870402528731,
                    124.53563218390805,
                    79.91870402528731,
                    124.53563218390805,
                    78.91845037170006,
                    124.53563218390805,
                    77.91819671811281,
                    124.53563218390805,
                    77.91819671811281,
                    124.53563218390805,
                    77.91819671811281,
                    124.53563218390805,
                    75.91768941093832,
                    124.53563218390805,
                    74.91743575735107,
                    124.53563218390805,
                    74.91743575735107,
                    124.53563218390805,
                    73.91718210376382,
                    124.53563218390805,
                    73.91718210376382,
                    124.53563218390805,
                    73.91718210376382,
                    123.53333333333333,
                    73.91718210376382,
                    123.53333333333333,
                    75.91768941093832,
                    123.53333333333333,
                    75.91768941093832,
                    124.53563218390805,
                    76.91794306452556,
                    124.53563218390805,
                    76.91794306452556,
                    124.53563218390805,
                    77.91819671811281,
                    124.53563218390805,
                    77.91819671811281,
                    124.53563218390805,
                    78.91845037170006,
                    124.53563218390805,
                    78.91845037170006,
                    124.53563218390805,
                    79.91870402528731,
                    124.53563218390805,
                    79.91870402528731,
                    124.53563218390805,
                    80.91895767887455,
                    124.53563218390805,
                    80.91895767887455,
                    124.53563218390805,
                    81.9192113324618,
                    124.53563218390805,
                    81.9192113324618,
                    124.53563218390805,
                    82.91946498604905,
                    124.53563218390805,
                    82.91946498604905,
                    124.53563218390805,
                    83.9197186396363,
                    124.53563218390805,
                    83.9197186396363,
                    124.53563218390805,
                    84.91997229322354,
                    124.53563218390805,
                    84.91997229322354,
                    124.53563218390805,
                    85.92022594681079,
                    124.53563218390805,
                    85.92022594681079,
                    124.53563218390805,
                    86.92047960039804,
                    124.53563218390805,
                    86.92047960039804,
                    124.53563218390805,
                    86.92047960039804,
                    124.53563218390805,
                    87.92073325398529,
                    124.53563218390805,
                    87.92073325398529,
                    124.53563218390805,
                    88.92098690757253,
                    124.53563218390805,
                    88.92098690757253,
                    124.53563218390805,
                    88.92098690757253,
                    124.53563218390805,
                    89.92124056115978,
                    124.53563218390805,
                    89.92124056115978,
                    124.53563218390805,
                    90.92149421474703,
                    124.53563218390805,
                    90.92149421474703,
                    124.53563218390805,
                    91.92174786833426,
                    124.53563218390805,
                    91.92174786833426,
                    124.53563218390805,
                    92.92200152192152,
                    124.53563218390805,
                    92.92200152192152,
                    124.53563218390805,
                    93.92225517550877,
                    125.53793103448277,
                    94.92250882909602,
                    126.54022988505747,
                    94.92250882909602,
                    126.54022988505747,
                    95.92276248268325,
                    126.54022988505747,
                    95.92276248268325,
                    126.54022988505747,
                    96.9230161362705,
                    126.54022988505747,
                    96.9230161362705,
                    126.54022988505747,
                    97.92326978985776,
                    128.5448275862069,
                    98.923523443445,
                    128.5448275862069,
                    99.92377709703224,
                    128.5448275862069,
                    99.92377709703224,
                    128.5448275862069,
                    100.9240307506195,
                    130.5494252873563,
                    101.92428440420674,
                    131.55172413793105,
                    101.92428440420674,
                    132.55402298850575,
                    102.924538057794,
                    132.55402298850575,
                    102.924538057794,
                    132.55402298850575,
                    103.92479171138123,
                    133.55632183908045,
                    103.92479171138123,
                    133.55632183908045,
                    104.92504536496848,
                    134.55862068965519,
                    104.92504536496848,
                    134.55862068965519,
                    104.92504536496848,
                    135.5609195402299,
                    104.92504536496848,
                    135.5609195402299,
                    104.92504536496848
                ]
            },
            {
                "tool": "pen",
                "points": [
                    146.58620689655174,
                    62.914391914304105,
                    146.58620689655174,
                    63.914645567891355,
                    146.58620689655174,
                    63.914645567891355,
                    147.58850574712645,
                    64.9148992214786,
                    148.59080459770115,
                    64.9148992214786,
                    148.59080459770115,
                    65.91515287506584,
                    148.59080459770115,
                    65.91515287506584,
                    148.59080459770115,
                    66.9154065286531,
                    148.59080459770115,
                    66.9154065286531,
                    148.59080459770115,
                    67.91566018224034,
                    148.59080459770115,
                    67.91566018224034,
                    148.59080459770115,
                    67.91566018224034,
                    148.59080459770115,
                    68.9159138358276,
                    148.59080459770115,
                    68.9159138358276,
                    148.59080459770115,
                    69.91616748941483,
                    148.59080459770115,
                    69.91616748941483,
                    148.59080459770115,
                    69.91616748941483,
                    148.59080459770115,
                    70.91642114300208,
                    148.59080459770115,
                    71.91667479658933,
                    148.59080459770115,
                    71.91667479658933,
                    148.59080459770115,
                    71.91667479658933,
                    148.59080459770115,
                    72.91692845017658,
                    148.59080459770115,
                    72.91692845017658,
                    148.59080459770115,
                    73.91718210376382,
                    148.59080459770115,
                    73.91718210376382,
                    148.59080459770115,
                    74.91743575735107,
                    148.59080459770115,
                    74.91743575735107,
                    148.59080459770115,
                    75.91768941093832,
                    148.59080459770115,
                    75.91768941093832,
                    147.58850574712645,
                    76.91794306452556,
                    147.58850574712645,
                    76.91794306452556,
                    147.58850574712645,
                    77.91819671811281,
                    147.58850574712645,
                    79.91870402528731,
                    147.58850574712645,
                    79.91870402528731,
                    147.58850574712645,
                    80.91895767887455,
                    147.58850574712645,
                    80.91895767887455,
                    147.58850574712645,
                    81.9192113324618,
                    147.58850574712645,
                    81.9192113324618,
                    147.58850574712645,
                    82.91946498604905,
                    147.58850574712645,
                    83.9197186396363,
                    147.58850574712645,
                    83.9197186396363,
                    147.58850574712645,
                    83.9197186396363,
                    147.58850574712645,
                    84.91997229322354,
                    147.58850574712645,
                    84.91997229322354,
                    147.58850574712645,
                    85.92022594681079,
                    147.58850574712645,
                    85.92022594681079,
                    147.58850574712645,
                    85.92022594681079,
                    147.58850574712645,
                    86.92047960039804,
                    147.58850574712645,
                    87.92073325398529,
                    147.58850574712645,
                    87.92073325398529,
                    147.58850574712645,
                    87.92073325398529,
                    147.58850574712645,
                    88.92098690757253,
                    147.58850574712645,
                    88.92098690757253,
                    147.58850574712645,
                    89.92124056115978,
                    147.58850574712645,
                    89.92124056115978,
                    147.58850574712645,
                    89.92124056115978,
                    147.58850574712645,
                    90.92149421474703,
                    147.58850574712645,
                    90.92149421474703,
                    147.58850574712645,
                    91.92174786833426,
                    147.58850574712645,
                    92.92200152192152,
                    147.58850574712645,
                    92.92200152192152,
                    147.58850574712645,
                    92.92200152192152,
                    147.58850574712645,
                    93.92225517550877,
                    147.58850574712645,
                    94.92250882909602,
                    147.58850574712645,
                    94.92250882909602,
                    147.58850574712645,
                    95.92276248268325,
                    147.58850574712645,
                    95.92276248268325,
                    147.58850574712645,
                    96.9230161362705,
                    147.58850574712645,
                    96.9230161362705,
                    147.58850574712645,
                    96.9230161362705,
                    147.58850574712645,
                    97.92326978985776,
                    147.58850574712645,
                    97.92326978985776,
                    147.58850574712645,
                    98.923523443445,
                    147.58850574712645,
                    98.923523443445,
                    147.58850574712645,
                    98.923523443445,
                    148.59080459770115,
                    99.92377709703224,
                    148.59080459770115,
                    99.92377709703224,
                    148.59080459770115,
                    99.92377709703224
                ]
            },
            {
                "tool": "pen",
                "points": [
                    152.6,
                    78.91845037170006,
                    152.6,
                    78.91845037170006,
                    152.6,
                    77.91819671811281,
                    152.6,
                    77.91819671811281,
                    152.6,
                    76.91794306452556,
                    152.6,
                    76.91794306452556,
                    152.6,
                    74.91743575735107,
                    152.6,
                    74.91743575735107,
                    152.6,
                    73.91718210376382,
                    152.6,
                    73.91718210376382,
                    152.6,
                    73.91718210376382,
                    152.6,
                    73.91718210376382,
                    152.6,
                    72.91692845017658,
                    153.60229885057473,
                    72.91692845017658,
                    153.60229885057473,
                    72.91692845017658,
                    153.60229885057473,
                    71.91667479658933,
                    154.60459770114943,
                    71.91667479658933,
                    154.60459770114943,
                    71.91667479658933,
                    154.60459770114943,
                    70.91642114300208,
                    155.60689655172413,
                    70.91642114300208,
                    155.60689655172413,
                    70.91642114300208,
                    156.60919540229887,
                    69.91616748941483,
                    156.60919540229887,
                    69.91616748941483,
                    156.60919540229887,
                    68.9159138358276,
                    157.61149425287357,
                    68.9159138358276,
                    157.61149425287357,
                    68.9159138358276,
                    158.61379310344827,
                    68.9159138358276,
                    159.616091954023,
                    68.9159138358276,
                    159.616091954023,
                    68.9159138358276,
                    159.616091954023,
                    67.91566018224034,
                    160.6183908045977,
                    67.91566018224034,
                    160.6183908045977,
                    67.91566018224034,
                    161.6206896551724,
                    67.91566018224034,
                    161.6206896551724,
                    67.91566018224034,
                    162.62298850574714,
                    67.91566018224034,
                    163.62528735632185,
                    67.91566018224034,
                    163.62528735632185,
                    67.91566018224034,
                    163.62528735632185,
                    67.91566018224034,
                    164.62758620689655,
                    67.91566018224034,
                    164.62758620689655,
                    68.9159138358276,
                    165.62988505747128,
                    68.9159138358276,
                    165.62988505747128,
                    69.91616748941483,
                    165.62988505747128,
                    69.91616748941483
                ]
            },
            {
                "tool": "pen",
                "points": [
                    192.6919540229885,
                    29.906021345924955,
                    192.6919540229885,
                    29.906021345924955,
                    193.69425287356322,
                    30.906274999512203,
                    193.69425287356322,
                    30.906274999512203,
                    193.69425287356322,
                    31.90652865309945,
                    193.69425287356322,
                    31.90652865309945,
                    193.69425287356322,
                    32.906782306686694,
                    193.69425287356322,
                    32.906782306686694,
                    193.69425287356322,
                    33.907035960273944,
                    193.69425287356322,
                    34.90728961386119,
                    193.69425287356322,
                    34.90728961386119,
                    193.69425287356322,
                    35.90754326744844,
                    193.69425287356322,
                    35.90754326744844,
                    193.69425287356322,
                    36.90779692103568,
                    193.69425287356322,
                    37.90805057462293,
                    193.69425287356322,
                    37.90805057462293,
                    193.69425287356322,
                    38.90830422821018,
                    193.69425287356322,
                    39.90855788179743,
                    193.69425287356322,
                    39.90855788179743,
                    193.69425287356322,
                    40.90881153538467,
                    193.69425287356322,
                    41.90906518897192,
                    193.69425287356322,
                    42.909318842559166,
                    193.69425287356322,
                    43.90957249614642,
                    193.69425287356322,
                    44.90982614973366,
                    193.69425287356322,
                    44.90982614973366,
                    193.69425287356322,
                    45.91007980332091,
                    193.69425287356322,
                    46.910333456908155,
                    193.69425287356322,
                    46.910333456908155,
                    193.69425287356322,
                    46.910333456908155,
                    193.69425287356322,
                    48.91084076408265,
                    193.69425287356322,
                    49.911094417669894,
                    193.69425287356322,
                    49.911094417669894,
                    193.69425287356322,
                    50.911348071257144,
                    193.69425287356322,
                    51.91160172484439,
                    193.69425287356322,
                    51.91160172484439,
                    193.69425287356322,
                    52.91185537843164,
                    193.69425287356322,
                    52.91185537843164,
                    193.69425287356322,
                    53.91210903201888,
                    193.69425287356322,
                    54.91236268560613,
                    193.69425287356322,
                    54.91236268560613,
                    192.6919540229885,
                    55.91261633919338,
                    192.6919540229885,
                    55.91261633919338,
                    192.6919540229885,
                    56.91286999278063,
                    192.6919540229885,
                    56.91286999278063,
                    192.6919540229885,
                    56.91286999278063,
                    192.6919540229885,
                    57.91312364636787,
                    192.6919540229885,
                    59.913630953542366,
                    192.6919540229885,
                    59.913630953542366,
                    192.6919540229885,
                    60.91388460712962,
                    192.6919540229885,
                    60.91388460712962,
                    192.6919540229885,
                    61.91413826071686,
                    192.6919540229885,
                    61.91413826071686,
                    192.6919540229885,
                    61.91413826071686,
                    192.6919540229885,
                    62.914391914304105,
                    192.6919540229885,
                    62.914391914304105,
                    192.6919540229885,
                    63.914645567891355,
                    192.6919540229885,
                    63.914645567891355,
                    192.6919540229885,
                    63.914645567891355,
                    192.6919540229885,
                    64.9148992214786,
                    191.6896551724138,
                    65.91515287506584,
                    191.6896551724138,
                    65.91515287506584,
                    191.6896551724138,
                    66.9154065286531,
                    191.6896551724138,
                    66.9154065286531,
                    191.6896551724138,
                    67.91566018224034,
                    191.6896551724138,
                    68.9159138358276,
                    191.6896551724138,
                    68.9159138358276,
                    191.6896551724138,
                    69.91616748941483,
                    191.6896551724138,
                    70.91642114300208,
                    191.6896551724138,
                    70.91642114300208,
                    191.6896551724138,
                    71.91667479658933,
                    191.6896551724138,
                    72.91692845017658,
                    191.6896551724138,
                    75.91768941093832,
                    191.6896551724138,
                    76.91794306452556,
                    191.6896551724138,
                    76.91794306452556,
                    190.68735632183908,
                    77.91819671811281,
                    190.68735632183908,
                    77.91819671811281,
                    190.68735632183908,
                    78.91845037170006,
                    190.68735632183908,
                    78.91845037170006,
                    190.68735632183908,
                    79.91870402528731,
                    190.68735632183908,
                    79.91870402528731,
                    190.68735632183908,
                    80.91895767887455,
                    190.68735632183908,
                    80.91895767887455,
                    190.68735632183908,
                    81.9192113324618,
                    190.68735632183908,
                    81.9192113324618,
                    190.68735632183908,
                    82.91946498604905,
                    190.68735632183908,
                    83.9197186396363,
                    190.68735632183908,
                    83.9197186396363,
                    190.68735632183908,
                    84.91997229322354,
                    190.68735632183908,
                    84.91997229322354,
                    190.68735632183908,
                    85.92022594681079,
                    190.68735632183908,
                    85.92022594681079,
                    190.68735632183908,
                    86.92047960039804,
                    190.68735632183908,
                    86.92047960039804,
                    190.68735632183908,
                    86.92047960039804,
                    190.68735632183908,
                    87.92073325398529,
                    190.68735632183908,
                    87.92073325398529,
                    190.68735632183908,
                    88.92098690757253,
                    190.68735632183908,
                    88.92098690757253,
                    190.68735632183908,
                    88.92098690757253,
                    190.68735632183908,
                    89.92124056115978,
                    190.68735632183908,
                    90.92149421474703,
                    190.68735632183908,
                    90.92149421474703,
                    190.68735632183908,
                    90.92149421474703,
                    190.68735632183908,
                    91.92174786833426,
                    190.68735632183908,
                    91.92174786833426,
                    190.68735632183908,
                    92.92200152192152,
                    190.68735632183908,
                    92.92200152192152,
                    190.68735632183908,
                    92.92200152192152,
                    190.68735632183908,
                    93.92225517550877,
                    190.68735632183908,
                    93.92225517550877,
                    190.68735632183908,
                    94.92250882909602,
                    190.68735632183908,
                    94.92250882909602,
                    190.68735632183908,
                    94.92250882909602,
                    190.68735632183908,
                    95.92276248268325,
                    190.68735632183908,
                    95.92276248268325,
                    190.68735632183908,
                    96.9230161362705,
                    190.68735632183908,
                    96.9230161362705,
                    190.68735632183908,
                    97.92326978985776,
                    190.68735632183908,
                    97.92326978985776,
                    190.68735632183908,
                    98.923523443445,
                    190.68735632183908,
                    98.923523443445,
                    190.68735632183908,
                    99.92377709703224,
                    190.68735632183908,
                    99.92377709703224,
                    190.68735632183908,
                    99.92377709703224,
                    190.68735632183908,
                    100.9240307506195,
                    190.68735632183908,
                    101.92428440420674,
                    189.68505747126437,
                    101.92428440420674
                ]
            },
            {
                "tool": "pen",
                "points": [
                    175.6528735632184,
                    47.9105871104954,
                    175.6528735632184,
                    47.9105871104954,
                    176.6551724137931,
                    47.9105871104954,
                    176.6551724137931,
                    47.9105871104954,
                    176.6551724137931,
                    47.9105871104954,
                    177.6574712643678,
                    47.9105871104954,
                    177.6574712643678,
                    47.9105871104954,
                    178.65977011494255,
                    47.9105871104954,
                    178.65977011494255,
                    47.9105871104954,
                    179.66206896551725,
                    47.9105871104954,
                    179.66206896551725,
                    47.9105871104954,
                    180.66436781609195,
                    47.9105871104954,
                    180.66436781609195,
                    47.9105871104954,
                    181.66666666666669,
                    47.9105871104954,
                    181.66666666666669,
                    47.9105871104954,
                    182.6689655172414,
                    47.9105871104954,
                    182.6689655172414,
                    47.9105871104954,
                    183.6712643678161,
                    47.9105871104954,
                    183.6712643678161,
                    47.9105871104954,
                    184.67356321839083,
                    47.9105871104954,
                    184.67356321839083,
                    47.9105871104954,
                    185.67586206896553,
                    47.9105871104954,
                    185.67586206896553,
                    47.9105871104954,
                    186.67816091954023,
                    47.9105871104954,
                    186.67816091954023,
                    47.9105871104954,
                    187.68045977011494,
                    47.9105871104954,
                    187.68045977011494,
                    47.9105871104954,
                    187.68045977011494,
                    47.9105871104954,
                    188.68275862068967,
                    47.9105871104954,
                    188.68275862068967,
                    47.9105871104954,
                    189.68505747126437,
                    47.9105871104954,
                    189.68505747126437,
                    47.9105871104954,
                    190.68735632183908,
                    47.9105871104954,
                    191.6896551724138,
                    47.9105871104954,
                    191.6896551724138,
                    47.9105871104954,
                    191.6896551724138,
                    47.9105871104954,
                    192.6919540229885,
                    47.9105871104954,
                    192.6919540229885,
                    47.9105871104954,
                    193.69425287356322,
                    47.9105871104954,
                    194.69655172413795,
                    47.9105871104954,
                    194.69655172413795,
                    47.9105871104954,
                    195.69885057471265,
                    47.9105871104954,
                    195.69885057471265,
                    47.9105871104954,
                    196.70114942528735,
                    47.9105871104954,
                    196.70114942528735,
                    47.9105871104954,
                    196.70114942528735,
                    47.9105871104954,
                    197.7034482758621,
                    47.9105871104954,
                    198.7057471264368,
                    47.9105871104954,
                    198.7057471264368,
                    47.9105871104954,
                    199.7080459770115,
                    47.9105871104954,
                    199.7080459770115,
                    47.9105871104954,
                    200.71034482758623,
                    47.9105871104954,
                    200.71034482758623,
                    47.9105871104954,
                    200.71034482758623,
                    47.9105871104954,
                    201.71264367816093,
                    47.9105871104954,
                    201.71264367816093,
                    47.9105871104954,
                    202.71494252873563,
                    47.9105871104954,
                    202.71494252873563,
                    47.9105871104954,
                    203.71724137931037,
                    47.9105871104954,
                    203.71724137931037,
                    47.9105871104954
                ]
            },
            {
                "tool": "pen",
                "points": [
                    3.2574712643678163,
                    163.94001092661605,
                    3.2574712643678163,
                    164.9402645802033,
                    3.2574712643678163,
                    165.94051823379056,
                    3.2574712643678163,
                    165.94051823379056,
                    3.2574712643678163,
                    165.94051823379056,
                    3.2574712643678163,
                    167.94102554096506,
                    3.2574712643678163,
                    168.9412791945523,
                    3.2574712643678163,
                    168.9412791945523,
                    3.2574712643678163,
                    169.94153284813953,
                    3.2574712643678163,
                    169.94153284813953,
                    3.2574712643678163,
                    170.9417865017268,
                    3.2574712643678163,
                    170.9417865017268,
                    3.2574712643678163,
                    171.94204015531403,
                    3.2574712643678163,
                    171.94204015531403,
                    3.2574712643678163,
                    172.94229380890127,
                    3.2574712643678163,
                    172.94229380890127,
                    3.2574712643678163,
                    173.94254746248853,
                    3.2574712643678163,
                    173.94254746248853,
                    3.2574712643678163,
                    174.94280111607577,
                    3.2574712643678163,
                    174.94280111607577,
                    3.2574712643678163,
                    175.94305476966304,
                    3.2574712643678163,
                    176.94330842325027,
                    3.2574712643678163,
                    176.94330842325027,
                    3.2574712643678163,
                    177.9435620768375,
                    3.2574712643678163,
                    177.9435620768375,
                    3.2574712643678163,
                    178.94381573042477,
                    3.2574712643678163,
                    179.944069384012,
                    3.2574712643678163,
                    179.944069384012,
                    3.2574712643678163,
                    180.94432303759925,
                    2.2551724137931037,
                    182.94483034477375,
                    2.2551724137931037,
                    182.94483034477375,
                    2.2551724137931037,
                    183.94508399836099,
                    2.2551724137931037,
                    183.94508399836099,
                    2.2551724137931037,
                    184.94533765194825,
                    2.2551724137931037,
                    184.94533765194825,
                    2.2551724137931037,
                    185.9455913055355,
                    2.2551724137931037,
                    186.94584495912275,
                    2.2551724137931037,
                    187.94609861271,
                    2.2551724137931037,
                    187.94609861271,
                    2.2551724137931037,
                    188.94635226629723,
                    2.2551724137931037,
                    188.94635226629723,
                    2.2551724137931037,
                    189.9466059198845,
                    2.2551724137931037,
                    191.94711322705896,
                    2.2551724137931037,
                    191.94711322705896,
                    2.2551724137931037,
                    191.94711322705896,
                    2.2551724137931037,
                    192.94736688064623,
                    2.2551724137931037,
                    193.94762053423347,
                    1.252873563218391,
                    193.94762053423347,
                    1.252873563218391,
                    194.9478741878207,
                    1.252873563218391,
                    194.9478741878207,
                    1.252873563218391,
                    195.94812784140797,
                    1.252873563218391,
                    196.9483814949952,
                    1.252873563218391,
                    196.9483814949952,
                    1.252873563218391,
                    197.94863514858247,
                    1.252873563218391,
                    199.94914245575694,
                    1.252873563218391,
                    199.94914245575694,
                    1.252873563218391,
                    200.9493961093442,
                    1.252873563218391,
                    200.9493961093442,
                    1.252873563218391,
                    201.94964976293144,
                    0.2505747126436782,
                    202.94990341651868,
                    0.2505747126436782,
                    202.94990341651868,
                    0.2505747126436782,
                    203.95015707010594,
                    0.2505747126436782,
                    203.95015707010594,
                    0.2505747126436782,
                    204.95041072369318,
                    0.2505747126436782,
                    204.95041072369318,
                    0.2505747126436782,
                    205.95066437728045,
                    0.2505747126436782,
                    205.95066437728045,
                    0.2505747126436782,
                    206.95091803086768,
                    0.2505747126436782,
                    206.95091803086768,
                    0.2505747126436782,
                    207.95117168445492,
                    0.2505747126436782,
                    207.95117168445492,
                    0.2505747126436782,
                    207.95117168445492,
                    0.2505747126436782,
                    208.95142533804218,
                    0.2505747126436782,
                    208.95142533804218,
                    0.2505747126436782,
                    209.95167899162942
                ]
            },
            {
                "tool": "pen",
                "points": [
                    4.259770114942529,
                    158.93874265867981,
                    4.259770114942529,
                    158.93874265867981,
                    5.2620689655172415,
                    158.93874265867981,
                    5.2620689655172415,
                    158.93874265867981,
                    5.2620689655172415,
                    157.93848900509258,
                    6.264367816091954,
                    157.93848900509258,
                    6.264367816091954,
                    157.93848900509258,
                    7.266666666666667,
                    157.93848900509258,
                    7.266666666666667,
                    157.93848900509258,
                    7.266666666666667,
                    157.93848900509258,
                    8.26896551724138,
                    157.93848900509258,
                    8.26896551724138,
                    158.93874265867981,
                    9.271264367816093,
                    158.93874265867981,
                    9.271264367816093,
                    158.93874265867981,
                    9.271264367816093,
                    158.93874265867981,
                    10.273563218390805,
                    159.93899631226708,
                    10.273563218390805,
                    159.93899631226708,
                    11.275862068965518,
                    159.93899631226708,
                    11.275862068965518,
                    160.93924996585432,
                    12.27816091954023,
                    160.93924996585432,
                    13.280459770114943,
                    161.93950361944155,
                    13.280459770114943,
                    161.93950361944155,
                    14.282758620689656,
                    161.93950361944155,
                    14.282758620689656,
                    162.93975727302882,
                    15.285057471264368,
                    162.93975727302882,
                    15.285057471264368,
                    162.93975727302882,
                    16.28735632183908,
                    163.94001092661605,
                    16.28735632183908,
                    163.94001092661605,
                    16.28735632183908,
                    163.94001092661605,
                    17.289655172413795,
                    164.9402645802033,
                    17.289655172413795,
                    164.9402645802033,
                    18.291954022988506,
                    165.94051823379056,
                    18.291954022988506,
                    165.94051823379056,
                    18.291954022988506,
                    165.94051823379056,
                    18.291954022988506,
                    166.9407718873778,
                    19.29425287356322,
                    167.94102554096506,
                    19.29425287356322,
                    168.9412791945523,
                    19.29425287356322,
                    169.94153284813953,
                    19.29425287356322,
                    169.94153284813953,
                    19.29425287356322,
                    170.9417865017268,
                    19.29425287356322,
                    170.9417865017268,
                    19.29425287356322,
                    170.9417865017268,
                    20.29655172413793,
                    171.94204015531403,
                    20.29655172413793,
                    171.94204015531403,
                    20.29655172413793,
                    172.94229380890127,
                    20.29655172413793,
                    172.94229380890127,
                    20.29655172413793,
                    172.94229380890127,
                    21.298850574712645,
                    173.94254746248853,
                    21.298850574712645,
                    173.94254746248853,
                    21.298850574712645,
                    174.94280111607577,
                    21.298850574712645,
                    174.94280111607577,
                    22.301149425287356,
                    174.94280111607577,
                    23.30344827586207,
                    175.94305476966304,
                    23.30344827586207,
                    176.94330842325027,
                    23.30344827586207,
                    176.94330842325027,
                    23.30344827586207,
                    176.94330842325027,
                    24.30574712643678,
                    177.9435620768375,
                    24.30574712643678,
                    177.9435620768375,
                    24.30574712643678,
                    178.94381573042477,
                    24.30574712643678,
                    179.944069384012,
                    24.30574712643678,
                    179.944069384012,
                    24.30574712643678,
                    179.944069384012,
                    24.30574712643678,
                    180.94432303759925,
                    24.30574712643678,
                    180.94432303759925,
                    24.30574712643678,
                    181.9445766911865,
                    24.30574712643678,
                    181.9445766911865,
                    24.30574712643678,
                    181.9445766911865,
                    24.30574712643678,
                    182.94483034477375,
                    24.30574712643678,
                    183.94508399836099,
                    24.30574712643678,
                    183.94508399836099,
                    24.30574712643678,
                    183.94508399836099,
                    24.30574712643678,
                    184.94533765194825,
                    24.30574712643678,
                    184.94533765194825,
                    24.30574712643678,
                    185.9455913055355,
                    24.30574712643678,
                    185.9455913055355,
                    24.30574712643678,
                    185.9455913055355,
                    24.30574712643678,
                    186.94584495912275,
                    24.30574712643678,
                    186.94584495912275,
                    24.30574712643678,
                    187.94609861271,
                    24.30574712643678,
                    187.94609861271,
                    24.30574712643678,
                    188.94635226629723,
                    24.30574712643678,
                    188.94635226629723,
                    24.30574712643678,
                    189.9466059198845,
                    24.30574712643678,
                    189.9466059198845,
                    24.30574712643678,
                    190.94685957347173,
                    24.30574712643678,
                    190.94685957347173,
                    24.30574712643678,
                    191.94711322705896,
                    24.30574712643678,
                    192.94736688064623,
                    24.30574712643678,
                    192.94736688064623,
                    24.30574712643678,
                    193.94762053423347,
                    24.30574712643678,
                    194.9478741878207,
                    24.30574712643678,
                    194.9478741878207,
                    24.30574712643678,
                    195.94812784140797,
                    24.30574712643678,
                    195.94812784140797,
                    24.30574712643678,
                    196.9483814949952,
                    24.30574712643678,
                    196.9483814949952,
                    24.30574712643678,
                    197.94863514858247,
                    24.30574712643678,
                    197.94863514858247,
                    24.30574712643678,
                    198.9488888021697,
                    24.30574712643678,
                    198.9488888021697,
                    24.30574712643678,
                    199.94914245575694,
                    24.30574712643678,
                    199.94914245575694,
                    24.30574712643678,
                    200.9493961093442,
                    24.30574712643678,
                    200.9493961093442,
                    24.30574712643678,
                    200.9493961093442,
                    24.30574712643678,
                    201.94964976293144,
                    24.30574712643678,
                    201.94964976293144,
                    24.30574712643678,
                    202.94990341651868,
                    24.30574712643678,
                    202.94990341651868,
                    24.30574712643678,
                    203.95015707010594,
                    24.30574712643678,
                    203.95015707010594,
                    24.30574712643678,
                    204.95041072369318,
                    23.30344827586207,
                    204.95041072369318,
                    22.301149425287356,
                    205.95066437728045,
                    21.298850574712645,
                    205.95066437728045,
                    21.298850574712645,
                    206.95091803086768,
                    21.298850574712645,
                    206.95091803086768,
                    20.29655172413793,
                    206.95091803086768,
                    20.29655172413793,
                    206.95091803086768,
                    19.29425287356322,
                    207.95117168445492,
                    17.289655172413795,
                    207.95117168445492,
                    17.289655172413795,
                    208.95142533804218,
                    16.28735632183908,
                    208.95142533804218,
                    16.28735632183908,
                    208.95142533804218,
                    15.285057471264368,
                    208.95142533804218,
                    15.285057471264368,
                    208.95142533804218,
                    15.285057471264368,
                    208.95142533804218,
                    14.282758620689656,
                    209.95167899162942,
                    13.280459770114943,
                    209.95167899162942,
                    13.280459770114943,
                    209.95167899162942,
                    12.27816091954023,
                    209.95167899162942,
                    12.27816091954023,
                    209.95167899162942,
                    12.27816091954023,
                    209.95167899162942,
                    11.275862068965518,
                    209.95167899162942,
                    10.273563218390805,
                    209.95167899162942,
                    10.273563218390805,
                    209.95167899162942,
                    9.271264367816093,
                    209.95167899162942,
                    9.271264367816093,
                    209.95167899162942,
                    8.26896551724138,
                    210.95193264521666,
                    8.26896551724138,
                    210.95193264521666,
                    8.26896551724138,
                    210.95193264521666
                ]
            },
            {
                "tool": "pen",
                "points": [
                    46.356321839080465,
                    183.94508399836099,
                    46.356321839080465,
                    184.94533765194825,
                    46.356321839080465,
                    184.94533765194825,
                    46.356321839080465,
                    184.94533765194825,
                    46.356321839080465,
                    185.9455913055355,
                    46.356321839080465,
                    185.9455913055355,
                    46.356321839080465,
                    186.94584495912275,
                    46.356321839080465,
                    186.94584495912275,
                    46.356321839080465,
                    187.94609861271,
                    46.356321839080465,
                    187.94609861271,
                    46.356321839080465,
                    188.94635226629723,
                    46.356321839080465,
                    188.94635226629723,
                    46.356321839080465,
                    189.9466059198845,
                    46.356321839080465,
                    190.94685957347173,
                    46.356321839080465,
                    190.94685957347173,
                    46.356321839080465,
                    191.94711322705896,
                    45.35402298850575,
                    191.94711322705896,
                    45.35402298850575,
                    192.94736688064623,
                    45.35402298850575,
                    192.94736688064623,
                    45.35402298850575,
                    193.94762053423347,
                    45.35402298850575,
                    193.94762053423347,
                    45.35402298850575,
                    194.9478741878207,
                    45.35402298850575,
                    194.9478741878207,
                    45.35402298850575,
                    195.94812784140797,
                    45.35402298850575,
                    195.94812784140797,
                    45.35402298850575,
                    197.94863514858247,
                    45.35402298850575,
                    197.94863514858247,
                    45.35402298850575,
                    198.9488888021697,
                    45.35402298850575,
                    198.9488888021697,
                    45.35402298850575,
                    199.94914245575694,
                    45.35402298850575,
                    199.94914245575694,
                    44.351724137931036,
                    200.9493961093442,
                    44.351724137931036,
                    200.9493961093442,
                    44.351724137931036,
                    201.94964976293144,
                    44.351724137931036,
                    202.94990341651868,
                    44.351724137931036,
                    202.94990341651868,
                    44.351724137931036,
                    203.95015707010594,
                    44.351724137931036,
                    203.95015707010594,
                    44.351724137931036,
                    204.95041072369318,
                    44.351724137931036,
                    204.95041072369318,
                    44.351724137931036,
                    205.95066437728045,
                    44.351724137931036,
                    206.95091803086768,
                    44.351724137931036,
                    206.95091803086768,
                    44.351724137931036,
                    207.95117168445492,
                    44.351724137931036,
                    207.95117168445492,
                    44.351724137931036,
                    207.95117168445492,
                    44.351724137931036,
                    208.95142533804218,
                    43.349425287356325,
                    208.95142533804218,
                    43.349425287356325,
                    209.95167899162942,
                    43.349425287356325,
                    209.95167899162942,
                    43.349425287356325,
                    210.95193264521666,
                    43.349425287356325,
                    211.95218629880392,
                    43.349425287356325,
                    211.95218629880392,
                    43.349425287356325,
                    211.95218629880392,
                    43.349425287356325,
                    212.95243995239116,
                    43.349425287356325,
                    213.9526936059784,
                    43.349425287356325,
                    213.9526936059784,
                    43.349425287356325,
                    213.9526936059784,
                    43.349425287356325,
                    212.95243995239116,
                    43.349425287356325,
                    211.95218629880392,
                    43.349425287356325,
                    210.95193264521666,
                    43.349425287356325,
                    210.95193264521666,
                    44.351724137931036,
                    209.95167899162942,
                    44.351724137931036,
                    209.95167899162942,
                    44.351724137931036,
                    209.95167899162942,
                    44.351724137931036,
                    208.95142533804218,
                    44.351724137931036,
                    208.95142533804218,
                    44.351724137931036,
                    207.95117168445492,
                    44.351724137931036,
                    207.95117168445492,
                    44.351724137931036,
                    206.95091803086768,
                    44.351724137931036,
                    206.95091803086768,
                    44.351724137931036,
                    205.95066437728045,
                    44.351724137931036,
                    205.95066437728045,
                    44.351724137931036,
                    204.95041072369318,
                    44.351724137931036,
                    204.95041072369318,
                    44.351724137931036,
                    203.95015707010594,
                    44.351724137931036,
                    203.95015707010594,
                    44.351724137931036,
                    203.95015707010594,
                    44.351724137931036,
                    202.94990341651868,
                    44.351724137931036,
                    202.94990341651868,
                    44.351724137931036,
                    201.94964976293144,
                    44.351724137931036,
                    201.94964976293144,
                    45.35402298850575,
                    201.94964976293144,
                    45.35402298850575,
                    200.9493961093442,
                    45.35402298850575,
                    200.9493961093442,
                    45.35402298850575,
                    199.94914245575694,
                    45.35402298850575,
                    199.94914245575694,
                    46.356321839080465,
                    199.94914245575694,
                    46.356321839080465,
                    198.9488888021697,
                    46.356321839080465,
                    197.94863514858247,
                    46.356321839080465,
                    197.94863514858247,
                    46.356321839080465,
                    196.9483814949952,
                    46.356321839080465,
                    196.9483814949952,
                    46.356321839080465,
                    196.9483814949952,
                    46.356321839080465,
                    196.9483814949952,
                    46.356321839080465,
                    195.94812784140797,
                    46.356321839080465,
                    195.94812784140797,
                    47.358620689655176,
                    194.9478741878207,
                    47.358620689655176,
                    194.9478741878207,
                    48.36091954022989,
                    193.94762053423347,
                    48.36091954022989,
                    193.94762053423347,
                    48.36091954022989,
                    192.94736688064623,
                    48.36091954022989,
                    192.94736688064623,
                    48.36091954022989,
                    191.94711322705896,
                    49.3632183908046,
                    191.94711322705896,
                    49.3632183908046,
                    190.94685957347173,
                    49.3632183908046,
                    190.94685957347173,
                    50.365517241379315,
                    190.94685957347173,
                    50.365517241379315,
                    190.94685957347173,
                    50.365517241379315,
                    189.9466059198845,
                    51.367816091954026,
                    189.9466059198845,
                    51.367816091954026,
                    188.94635226629723,
                    52.37011494252874,
                    188.94635226629723,
                    52.37011494252874,
                    187.94609861271,
                    52.37011494252874,
                    187.94609861271,
                    53.37241379310345,
                    186.94584495912275,
                    53.37241379310345,
                    186.94584495912275,
                    53.37241379310345,
                    185.9455913055355,
                    54.374712643678166,
                    185.9455913055355,
                    55.377011494252876,
                    185.9455913055355,
                    55.377011494252876,
                    185.9455913055355,
                    55.377011494252876,
                    185.9455913055355,
                    56.37931034482759,
                    185.9455913055355,
                    56.37931034482759,
                    185.9455913055355,
                    57.3816091954023,
                    185.9455913055355,
                    57.3816091954023,
                    185.9455913055355,
                    58.383908045977016,
                    185.9455913055355,
                    58.383908045977016,
                    185.9455913055355,
                    59.38620689655173,
                    185.9455913055355,
                    59.38620689655173,
                    185.9455913055355,
                    60.38850574712644,
                    185.9455913055355,
                    61.390804597701155,
                    185.9455913055355,
                    61.390804597701155,
                    185.9455913055355,
                    61.390804597701155,
                    185.9455913055355,
                    62.393103448275866,
                    185.9455913055355,
                    62.393103448275866,
                    185.9455913055355,
                    63.39540229885058,
                    185.9455913055355,
                    64.39770114942529,
                    185.9455913055355,
                    64.39770114942529,
                    185.9455913055355,
                    65.4,
                    185.9455913055355,
                    65.4,
                    185.9455913055355
                ]
            },
            {
                "tool": "pen",
                "points": [
                    91.45977011494253,
                    188.94635226629723,
                    91.45977011494253,
                    188.94635226629723,
                    90.45747126436783,
                    188.94635226629723,
                    90.45747126436783,
                    188.94635226629723,
                    90.45747126436783,
                    188.94635226629723,
                    89.45517241379311,
                    188.94635226629723,
                    89.45517241379311,
                    189.9466059198845,
                    88.45287356321839,
                    189.9466059198845,
                    88.45287356321839,
                    189.9466059198845,
                    87.45057471264369,
                    189.9466059198845,
                    87.45057471264369,
                    189.9466059198845,
                    86.44827586206897,
                    190.94685957347173,
                    86.44827586206897,
                    190.94685957347173,
                    85.44597701149425,
                    190.94685957347173,
                    85.44597701149425,
                    191.94711322705896,
                    85.44597701149425,
                    191.94711322705896,
                    84.44367816091955,
                    191.94711322705896,
                    83.44137931034483,
                    192.94736688064623,
                    83.44137931034483,
                    192.94736688064623,
                    83.44137931034483,
                    193.94762053423347,
                    82.43908045977012,
                    193.94762053423347,
                    81.4367816091954,
                    193.94762053423347,
                    81.4367816091954,
                    194.9478741878207,
                    81.4367816091954,
                    195.94812784140797,
                    81.4367816091954,
                    195.94812784140797,
                    81.4367816091954,
                    196.9483814949952,
                    80.43448275862069,
                    196.9483814949952,
                    80.43448275862069,
                    196.9483814949952,
                    80.43448275862069,
                    198.9488888021697,
                    79.43218390804599,
                    198.9488888021697,
                    79.43218390804599,
                    198.9488888021697,
                    78.42988505747127,
                    199.94914245575694,
                    78.42988505747127,
                    200.9493961093442,
                    77.42758620689655,
                    200.9493961093442,
                    77.42758620689655,
                    201.94964976293144,
                    77.42758620689655,
                    201.94964976293144,
                    77.42758620689655,
                    202.94990341651868,
                    77.42758620689655,
                    202.94990341651868,
                    76.42528735632185,
                    203.95015707010594,
                    76.42528735632185,
                    203.95015707010594,
                    76.42528735632185,
                    204.95041072369318,
                    76.42528735632185,
                    204.95041072369318,
                    75.42298850574713,
                    204.95041072369318,
                    75.42298850574713,
                    205.95066437728045,
                    75.42298850574713,
                    206.95091803086768,
                    75.42298850574713,
                    206.95091803086768,
                    75.42298850574713,
                    206.95091803086768,
                    74.42068965517241,
                    207.95117168445492,
                    74.42068965517241,
                    207.95117168445492,
                    74.42068965517241,
                    207.95117168445492,
                    74.42068965517241,
                    208.95142533804218,
                    74.42068965517241,
                    209.95167899162942,
                    74.42068965517241,
                    209.95167899162942,
                    74.42068965517241,
                    209.95167899162942,
                    74.42068965517241,
                    210.95193264521666,
                    74.42068965517241,
                    211.95218629880392,
                    74.42068965517241,
                    211.95218629880392,
                    74.42068965517241,
                    212.95243995239116,
                    74.42068965517241,
                    212.95243995239116,
                    74.42068965517241,
                    213.9526936059784,
                    74.42068965517241,
                    213.9526936059784,
                    74.42068965517241,
                    214.95294725956566,
                    74.42068965517241,
                    214.95294725956566,
                    74.42068965517241,
                    215.9532009131529,
                    75.42298850574713,
                    215.9532009131529,
                    75.42298850574713,
                    216.95345456674016,
                    76.42528735632185,
                    217.9537082203274,
                    76.42528735632185,
                    217.9537082203274,
                    76.42528735632185,
                    217.9537082203274,
                    76.42528735632185,
                    218.95396187391464,
                    77.42758620689655,
                    218.95396187391464,
                    78.42988505747127,
                    218.95396187391464,
                    78.42988505747127,
                    218.95396187391464,
                    79.43218390804599,
                    218.95396187391464,
                    80.43448275862069,
                    219.9542155275019,
                    80.43448275862069,
                    219.9542155275019,
                    81.4367816091954,
                    219.9542155275019,
                    81.4367816091954,
                    219.9542155275019,
                    82.43908045977012,
                    219.9542155275019,
                    82.43908045977012,
                    219.9542155275019,
                    83.44137931034483,
                    219.9542155275019,
                    84.44367816091955,
                    219.9542155275019,
                    85.44597701149425,
                    219.9542155275019,
                    85.44597701149425,
                    219.9542155275019,
                    86.44827586206897,
                    218.95396187391464,
                    87.45057471264369,
                    218.95396187391464,
                    87.45057471264369,
                    217.9537082203274,
                    88.45287356321839,
                    217.9537082203274,
                    89.45517241379311,
                    216.95345456674016,
                    89.45517241379311,
                    215.9532009131529,
                    90.45747126436783,
                    215.9532009131529,
                    90.45747126436783,
                    214.95294725956566,
                    90.45747126436783,
                    214.95294725956566,
                    90.45747126436783,
                    214.95294725956566,
                    91.45977011494253,
                    214.95294725956566,
                    91.45977011494253,
                    213.9526936059784,
                    91.45977011494253,
                    213.9526936059784,
                    91.45977011494253,
                    212.95243995239116,
                    91.45977011494253,
                    211.95218629880392,
                    92.46206896551725,
                    210.95193264521666,
                    92.46206896551725,
                    210.95193264521666,
                    92.46206896551725,
                    209.95167899162942,
                    92.46206896551725,
                    208.95142533804218,
                    92.46206896551725,
                    208.95142533804218,
                    92.46206896551725,
                    207.95117168445492,
                    92.46206896551725,
                    206.95091803086768,
                    92.46206896551725,
                    206.95091803086768,
                    92.46206896551725,
                    206.95091803086768,
                    92.46206896551725,
                    205.95066437728045,
                    92.46206896551725,
                    205.95066437728045,
                    92.46206896551725,
                    204.95041072369318,
                    92.46206896551725,
                    203.95015707010594,
                    92.46206896551725,
                    202.94990341651868,
                    92.46206896551725,
                    202.94990341651868,
                    92.46206896551725,
                    202.94990341651868,
                    92.46206896551725,
                    201.94964976293144,
                    92.46206896551725,
                    201.94964976293144,
                    92.46206896551725,
                    200.9493961093442,
                    92.46206896551725,
                    199.94914245575694,
                    92.46206896551725,
                    199.94914245575694,
                    92.46206896551725,
                    199.94914245575694,
                    92.46206896551725,
                    198.9488888021697,
                    92.46206896551725,
                    198.9488888021697,
                    92.46206896551725,
                    197.94863514858247,
                    92.46206896551725,
                    197.94863514858247,
                    92.46206896551725,
                    196.9483814949952,
                    93.46436781609196,
                    196.9483814949952,
                    93.46436781609196,
                    195.94812784140797,
                    93.46436781609196,
                    195.94812784140797,
                    93.46436781609196,
                    195.94812784140797,
                    93.46436781609196,
                    194.9478741878207,
                    93.46436781609196,
                    194.9478741878207,
                    93.46436781609196,
                    193.94762053423347,
                    93.46436781609196,
                    193.94762053423347,
                    94.46666666666667,
                    192.94736688064623,
                    94.46666666666667,
                    192.94736688064623,
                    94.46666666666667,
                    192.94736688064623,
                    94.46666666666667,
                    192.94736688064623,
                    95.46896551724139,
                    198.9488888021697,
                    95.46896551724139,
                    199.94914245575694,
                    95.46896551724139,
                    199.94914245575694,
                    95.46896551724139,
                    201.94964976293144,
                    95.46896551724139,
                    202.94990341651868,
                    95.46896551724139,
                    203.95015707010594,
                    95.46896551724139,
                    203.95015707010594,
                    95.46896551724139,
                    204.95041072369318,
                    95.46896551724139,
                    205.95066437728045,
                    95.46896551724139,
                    206.95091803086768,
                    95.46896551724139,
                    207.95117168445492,
                    95.46896551724139,
                    208.95142533804218,
                    95.46896551724139,
                    208.95142533804218,
                    95.46896551724139,
                    209.95167899162942,
                    95.46896551724139,
                    210.95193264521666,
                    95.46896551724139,
                    210.95193264521666,
                    95.46896551724139,
                    210.95193264521666,
                    95.46896551724139,
                    211.95218629880392,
                    95.46896551724139,
                    212.95243995239116,
                    95.46896551724139,
                    212.95243995239116,
                    95.46896551724139,
                    213.9526936059784,
                    96.47126436781609,
                    213.9526936059784,
                    96.47126436781609,
                    215.9532009131529,
                    96.47126436781609,
                    215.9532009131529,
                    97.47356321839081,
                    216.95345456674016,
                    97.47356321839081,
                    217.9537082203274,
                    97.47356321839081,
                    217.9537082203274,
                    98.47586206896553,
                    218.95396187391464,
                    98.47586206896553,
                    219.9542155275019,
                    99.47816091954023,
                    220.95446918108914,
                    99.47816091954023,
                    220.95446918108914,
                    100.48045977011495,
                    222.95497648826364,
                    100.48045977011495,
                    222.95497648826364,
                    100.48045977011495,
                    223.95523014185088,
                    101.48275862068967,
                    223.95523014185088
                ]
            },
            {
                "tool": "pen",
                "points": [
                    119.52413793103449,
                    191.94711322705896,
                    119.52413793103449,
                    192.94736688064623,
                    119.52413793103449,
                    193.94762053423347,
                    119.52413793103449,
                    193.94762053423347,
                    119.52413793103449,
                    194.9478741878207,
                    119.52413793103449,
                    194.9478741878207,
                    119.52413793103449,
                    195.94812784140797,
                    119.52413793103449,
                    196.9483814949952,
                    119.52413793103449,
                    196.9483814949952,
                    119.52413793103449,
                    197.94863514858247,
                    119.52413793103449,
                    198.9488888021697,
                    119.52413793103449,
                    199.94914245575694,
                    120.5264367816092,
                    200.9493961093442,
                    120.5264367816092,
                    201.94964976293144,
                    120.5264367816092,
                    202.94990341651868,
                    120.5264367816092,
                    203.95015707010594,
                    120.5264367816092,
                    204.95041072369318,
                    121.52873563218391,
                    205.95066437728045,
                    121.52873563218391,
                    207.95117168445492,
                    121.52873563218391,
                    208.95142533804218,
                    121.52873563218391,
                    208.95142533804218,
                    121.52873563218391,
                    209.95167899162942,
                    122.53103448275863,
                    211.95218629880392,
                    122.53103448275863,
                    211.95218629880392,
                    122.53103448275863,
                    213.9526936059784,
                    122.53103448275863,
                    214.95294725956566,
                    122.53103448275863,
                    214.95294725956566,
                    122.53103448275863,
                    215.9532009131529,
                    122.53103448275863,
                    216.95345456674016,
                    123.53333333333333,
                    219.9542155275019,
                    124.53563218390805,
                    220.95446918108914,
                    124.53563218390805,
                    220.95446918108914,
                    126.54022988505747,
                    221.95472283467637,
                    126.54022988505747,
                    222.95497648826364,
                    126.54022988505747,
                    223.95523014185088,
                    127.54252873563219,
                    223.95523014185088,
                    127.54252873563219,
                    223.95523014185088,
                    128.5448275862069,
                    223.95523014185088,
                    128.5448275862069,
                    223.95523014185088,
                    129.5471264367816,
                    223.95523014185088,
                    129.5471264367816,
                    222.95497648826364,
                    129.5471264367816,
                    222.95497648826364,
                    130.5494252873563,
                    221.95472283467637,
                    130.5494252873563,
                    221.95472283467637,
                    130.5494252873563,
                    220.95446918108914,
                    131.55172413793105,
                    219.9542155275019,
                    132.55402298850575,
                    218.95396187391464,
                    133.55632183908045,
                    216.95345456674016,
                    133.55632183908045,
                    216.95345456674016,
                    133.55632183908045,
                    215.9532009131529,
                    133.55632183908045,
                    215.9532009131529,
                    134.55862068965519,
                    214.95294725956566,
                    134.55862068965519,
                    214.95294725956566,
                    134.55862068965519,
                    214.95294725956566,
                    134.55862068965519,
                    213.9526936059784,
                    135.5609195402299,
                    213.9526936059784,
                    135.5609195402299,
                    212.95243995239116,
                    135.5609195402299,
                    211.95218629880392,
                    135.5609195402299,
                    211.95218629880392,
                    136.5632183908046,
                    210.95193264521666,
                    136.5632183908046,
                    210.95193264521666,
                    136.5632183908046,
                    209.95167899162942,
                    136.5632183908046,
                    209.95167899162942,
                    136.5632183908046,
                    208.95142533804218,
                    137.56551724137933,
                    208.95142533804218,
                    137.56551724137933,
                    207.95117168445492,
                    138.56781609195403,
                    207.95117168445492,
                    138.56781609195403,
                    207.95117168445492,
                    138.56781609195403,
                    206.95091803086768,
                    138.56781609195403,
                    205.95066437728045,
                    138.56781609195403,
                    206.95091803086768,
                    138.56781609195403,
                    206.95091803086768,
                    138.56781609195403,
                    207.95117168445492,
                    138.56781609195403,
                    211.95218629880392,
                    138.56781609195403,
                    213.9526936059784,
                    138.56781609195403,
                    213.9526936059784,
                    138.56781609195403,
                    214.95294725956566,
                    138.56781609195403,
                    215.9532009131529,
                    138.56781609195403,
                    215.9532009131529,
                    138.56781609195403,
                    215.9532009131529,
                    138.56781609195403,
                    216.95345456674016,
                    138.56781609195403,
                    217.9537082203274,
                    140.57241379310346,
                    218.95396187391464,
                    140.57241379310346,
                    218.95396187391464,
                    141.57471264367817,
                    220.95446918108914,
                    141.57471264367817,
                    220.95446918108914,
                    142.57701149425287,
                    221.95472283467637,
                    142.57701149425287,
                    222.95497648826364,
                    142.57701149425287,
                    222.95497648826364,
                    143.5793103448276,
                    223.95523014185088,
                    143.5793103448276,
                    223.95523014185088,
                    143.5793103448276,
                    224.9554837954381,
                    144.5816091954023,
                    224.9554837954381,
                    144.5816091954023,
                    224.9554837954381,
                    145.583908045977,
                    224.9554837954381,
                    145.583908045977,
                    225.95573744902538,
                    146.58620689655174,
                    225.95573744902538,
                    146.58620689655174,
                    225.95573744902538,
                    146.58620689655174,
                    225.95573744902538,
                    146.58620689655174,
                    224.9554837954381,
                    146.58620689655174,
                    224.9554837954381,
                    147.58850574712645,
                    224.9554837954381,
                    147.58850574712645,
                    223.95523014185088,
                    147.58850574712645,
                    222.95497648826364,
                    148.59080459770115,
                    222.95497648826364,
                    149.59310344827585,
                    221.95472283467637,
                    149.59310344827585,
                    221.95472283467637,
                    149.59310344827585,
                    220.95446918108914,
                    150.5954022988506,
                    220.95446918108914,
                    150.5954022988506,
                    219.9542155275019,
                    150.5954022988506,
                    218.95396187391464,
                    151.5977011494253,
                    218.95396187391464,
                    152.6,
                    217.9537082203274,
                    152.6,
                    216.95345456674016,
                    153.60229885057473,
                    215.9532009131529,
                    153.60229885057473,
                    214.95294725956566,
                    153.60229885057473,
                    214.95294725956566,
                    154.60459770114943,
                    213.9526936059784,
                    154.60459770114943,
                    213.9526936059784,
                    154.60459770114943,
                    213.9526936059784,
                    155.60689655172413,
                    213.9526936059784,
                    155.60689655172413,
                    212.95243995239116,
                    155.60689655172413,
                    211.95218629880392,
                    155.60689655172413,
                    211.95218629880392,
                    156.60919540229887,
                    210.95193264521666,
                    156.60919540229887,
                    209.95167899162942,
                    157.61149425287357,
                    209.95167899162942,
                    157.61149425287357,
                    208.95142533804218,
                    157.61149425287357,
                    208.95142533804218,
                    157.61149425287357,
                    208.95142533804218,
                    157.61149425287357,
                    207.95117168445492,
                    158.61379310344827,
                    207.95117168445492,
                    158.61379310344827,
                    207.95117168445492,
                    159.616091954023,
                    206.95091803086768,
                    159.616091954023,
                    206.95091803086768,
                    159.616091954023,
                    205.95066437728045,
                    159.616091954023,
                    204.95041072369318,
                    160.6183908045977,
                    204.95041072369318,
                    160.6183908045977,
                    203.95015707010594,
                    161.6206896551724,
                    202.94990341651868,
                    161.6206896551724,
                    202.94990341651868,
                    161.6206896551724,
                    202.94990341651868,
                    161.6206896551724,
                    202.94990341651868,
                    162.62298850574714,
                    201.94964976293144,
                    162.62298850574714,
                    201.94964976293144,
                    162.62298850574714,
                    201.94964976293144,
                    163.62528735632185,
                    200.9493961093442,
                    163.62528735632185,
                    200.9493961093442,
                    163.62528735632185,
                    200.9493961093442
                ]
            },
            {
                "tool": "pen",
                "points": [
                    85.44597701149425,
                    128.9311330510624,
                    85.44597701149425,
                    127.93087939747517,
                    85.44597701149425,
                    128.9311330510624,
                    85.44597701149425,
                    129.93138670464967,
                    85.44597701149425,
                    129.93138670464967,
                    85.44597701149425,
                    130.9316403582369,
                    85.44597701149425,
                    130.9316403582369,
                    85.44597701149425,
                    131.93189401182414,
                    85.44597701149425,
                    131.93189401182414,
                    85.44597701149425,
                    131.93189401182414,
                    85.44597701149425,
                    132.9321476654114,
                    85.44597701149425,
                    132.9321476654114,
                    85.44597701149425,
                    133.93240131899864,
                    85.44597701149425,
                    133.93240131899864,
                    85.44597701149425,
                    134.93265497258588,
                    85.44597701149425,
                    134.93265497258588,
                    85.44597701149425,
                    135.93290862617314,
                    84.44367816091955,
                    135.93290862617314,
                    84.44367816091955,
                    136.93316227976038,
                    84.44367816091955,
                    136.93316227976038,
                    84.44367816091955,
                    137.93341593334765,
                    84.44367816091955,
                    137.93341593334765,
                    84.44367816091955,
                    138.93366958693488,
                    84.44367816091955,
                    138.93366958693488,
                    84.44367816091955,
                    139.93392324052212,
                    84.44367816091955,
                    139.93392324052212,
                    84.44367816091955,
                    140.93417689410938,
                    84.44367816091955,
                    140.93417689410938,
                    84.44367816091955,
                    141.93443054769662,
                    84.44367816091955,
                    141.93443054769662,
                    84.44367816091955,
                    142.93468420128386,
                    84.44367816091955,
                    142.93468420128386,
                    84.44367816091955,
                    142.93468420128386,
                    84.44367816091955,
                    143.93493785487112,
                    84.44367816091955,
                    143.93493785487112,
                    84.44367816091955,
                    144.93519150845836,
                    84.44367816091955,
                    145.93544516204562,
                    84.44367816091955,
                    146.93569881563286,
                    84.44367816091955,
                    146.93569881563286,
                    84.44367816091955,
                    147.9359524692201,
                    84.44367816091955,
                    147.9359524692201,
                    84.44367816091955,
                    147.9359524692201,
                    84.44367816091955,
                    148.93620612280736,
                    84.44367816091955,
                    148.93620612280736,
                    84.44367816091955,
                    149.9364597763946,
                    84.44367816091955,
                    149.9364597763946,
                    84.44367816091955,
                    149.9364597763946,
                    84.44367816091955,
                    150.93671342998184,
                    84.44367816091955,
                    150.93671342998184,
                    84.44367816091955,
                    151.9369670835691,
                    84.44367816091955,
                    151.9369670835691,
                    84.44367816091955,
                    151.9369670835691,
                    84.44367816091955,
                    152.93722073715634,
                    84.44367816091955,
                    153.93747439074357,
                    84.44367816091955,
                    153.93747439074357,
                    84.44367816091955,
                    153.93747439074357,
                    84.44367816091955,
                    154.93772804433084,
                    84.44367816091955,
                    153.93747439074357,
                    84.44367816091955,
                    153.93747439074357,
                    84.44367816091955,
                    152.93722073715634,
                    84.44367816091955,
                    151.9369670835691,
                    84.44367816091955,
                    151.9369670835691,
                    85.44597701149425,
                    151.9369670835691,
                    85.44597701149425,
                    150.93671342998184,
                    85.44597701149425,
                    150.93671342998184,
                    85.44597701149425,
                    149.9364597763946,
                    85.44597701149425,
                    149.9364597763946,
                    85.44597701149425,
                    149.9364597763946,
                    85.44597701149425,
                    147.9359524692201,
                    86.44827586206897,
                    147.9359524692201,
                    86.44827586206897,
                    146.93569881563286,
                    86.44827586206897,
                    146.93569881563286,
                    86.44827586206897,
                    146.93569881563286,
                    86.44827586206897,
                    145.93544516204562,
                    86.44827586206897,
                    145.93544516204562,
                    87.45057471264369,
                    145.93544516204562,
                    87.45057471264369,
                    144.93519150845836,
                    87.45057471264369,
                    144.93519150845836,
                    87.45057471264369,
                    143.93493785487112,
                    87.45057471264369,
                    143.93493785487112,
                    87.45057471264369,
                    142.93468420128386,
                    87.45057471264369,
                    141.93443054769662,
                    87.45057471264369,
                    140.93417689410938,
                    87.45057471264369,
                    139.93392324052212,
                    87.45057471264369,
                    139.93392324052212,
                    87.45057471264369,
                    138.93366958693488,
                    87.45057471264369,
                    138.93366958693488,
                    87.45057471264369,
                    138.93366958693488,
                    87.45057471264369,
                    137.93341593334765,
                    87.45057471264369,
                    137.93341593334765,
                    87.45057471264369,
                    136.93316227976038,
                    87.45057471264369,
                    136.93316227976038,
                    87.45057471264369,
                    135.93290862617314,
                    87.45057471264369,
                    135.93290862617314,
                    87.45057471264369,
                    134.93265497258588,
                    87.45057471264369,
                    134.93265497258588,
                    87.45057471264369,
                    134.93265497258588,
                    87.45057471264369,
                    133.93240131899864,
                    86.44827586206897,
                    131.93189401182414,
                    86.44827586206897,
                    131.93189401182414,
                    86.44827586206897,
                    130.9316403582369,
                    86.44827586206897,
                    130.9316403582369,
                    85.44597701149425,
                    129.93138670464967,
                    85.44597701149425,
                    129.93138670464967,
                    84.44367816091955,
                    129.93138670464967,
                    84.44367816091955,
                    128.9311330510624,
                    84.44367816091955,
                    128.9311330510624,
                    84.44367816091955,
                    128.9311330510624,
                    83.44137931034483,
                    126.93062574388792,
                    83.44137931034483,
                    125.93037209030066,
                    83.44137931034483,
                    125.93037209030066,
                    83.44137931034483,
                    125.93037209030066,
                    83.44137931034483,
                    124.93011843671343,
                    83.44137931034483,
                    123.92986478312618,
                    83.44137931034483,
                    123.92986478312618,
                    83.44137931034483,
                    123.92986478312618,
                    83.44137931034483,
                    122.92961112953893,
                    83.44137931034483,
                    122.92961112953893,
                    83.44137931034483,
                    121.92935747595168,
                    83.44137931034483,
                    121.92935747595168,
                    83.44137931034483,
                    120.92910382236444,
                    83.44137931034483,
                    120.92910382236444,
                    83.44137931034483,
                    119.92885016877719,
                    83.44137931034483,
                    119.92885016877719,
                    83.44137931034483,
                    118.92859651518994,
                    83.44137931034483,
                    118.92859651518994,
                    83.44137931034483,
                    119.92885016877719,
                    83.44137931034483,
                    119.92885016877719,
                    83.44137931034483,
                    120.92910382236444,
                    83.44137931034483,
                    120.92910382236444,
                    83.44137931034483,
                    121.92935747595168,
                    83.44137931034483,
                    122.92961112953893,
                    83.44137931034483,
                    123.92986478312618,
                    83.44137931034483,
                    124.93011843671343,
                    83.44137931034483,
                    125.93037209030066,
                    83.44137931034483,
                    126.93062574388792,
                    83.44137931034483,
                    126.93062574388792,
                    83.44137931034483,
                    127.93087939747517,
                    83.44137931034483,
                    128.9311330510624,
                    83.44137931034483,
                    128.9311330510624,
                    82.43908045977012,
                    129.93138670464967,
                    82.43908045977012,
                    129.93138670464967,
                    82.43908045977012,
                    130.9316403582369,
                    82.43908045977012,
                    131.93189401182414,
                    82.43908045977012,
                    131.93189401182414,
                    82.43908045977012,
                    132.9321476654114,
                    82.43908045977012,
                    132.9321476654114,
                    82.43908045977012,
                    133.93240131899864,
                    82.43908045977012,
                    134.93265497258588,
                    82.43908045977012,
                    134.93265497258588,
                    82.43908045977012,
                    135.93290862617314,
                    82.43908045977012,
                    135.93290862617314,
                    82.43908045977012,
                    136.93316227976038,
                    82.43908045977012,
                    136.93316227976038,
                    82.43908045977012,
                    136.93316227976038,
                    82.43908045977012,
                    137.93341593334765,
                    82.43908045977012,
                    137.93341593334765,
                    82.43908045977012,
                    138.93366958693488,
                    82.43908045977012,
                    138.93366958693488,
                    82.43908045977012,
                    139.93392324052212,
                    82.43908045977012,
                    139.93392324052212,
                    82.43908045977012,
                    140.93417689410938,
                    82.43908045977012,
                    140.93417689410938,
                    82.43908045977012,
                    141.93443054769662,
                    82.43908045977012,
                    141.93443054769662,
                    82.43908045977012,
                    141.93443054769662,
                    82.43908045977012,
                    142.93468420128386,
                    82.43908045977012,
                    142.93468420128386,
                    82.43908045977012,
                    143.93493785487112,
                    82.43908045977012,
                    143.93493785487112,
                    82.43908045977012,
                    143.93493785487112,
                    82.43908045977012,
                    144.93519150845836,
                    82.43908045977012,
                    144.93519150845836,
                    82.43908045977012,
                    145.93544516204562,
                    82.43908045977012,
                    145.93544516204562,
                    82.43908045977012,
                    146.93569881563286,
                    82.43908045977012,
                    146.93569881563286,
                    82.43908045977012,
                    147.9359524692201,
                    82.43908045977012,
                    147.9359524692201,
                    82.43908045977012,
                    148.93620612280736,
                    82.43908045977012,
                    148.93620612280736,
                    82.43908045977012,
                    149.9364597763946,
                    82.43908045977012,
                    149.9364597763946,
                    82.43908045977012,
                    150.93671342998184,
                    82.43908045977012,
                    150.93671342998184,
                    82.43908045977012,
                    151.9369670835691,
                    82.43908045977012,
                    151.9369670835691,
                    82.43908045977012,
                    152.93722073715634,
                    82.43908045977012,
                    152.93722073715634,
                    82.43908045977012,
                    152.93722073715634,
                    82.43908045977012,
                    153.93747439074357,
                    82.43908045977012,
                    154.93772804433084,
                    82.43908045977012,
                    154.93772804433084,
                    82.43908045977012,
                    155.93798169791808,
                    82.43908045977012,
                    155.93798169791808,
                    82.43908045977012,
                    156.93823535150534,
                    82.43908045977012,
                    156.93823535150534,
                    82.43908045977012,
                    156.93823535150534,
                    82.43908045977012,
                    157.93848900509258,
                    82.43908045977012,
                    157.93848900509258,
                    83.44137931034483,
                    158.93874265867981,
                    83.44137931034483,
                    158.93874265867981,
                    83.44137931034483,
                    159.93899631226708,
                    83.44137931034483,
                    159.93899631226708,
                    83.44137931034483,
                    158.93874265867981,
                    84.44367816091955,
                    158.93874265867981,
                    84.44367816091955,
                    157.93848900509258,
                    84.44367816091955,
                    157.93848900509258,
                    84.44367816091955,
                    156.93823535150534,
                    84.44367816091955,
                    156.93823535150534,
                    84.44367816091955,
                    155.93798169791808,
                    84.44367816091955,
                    155.93798169791808,
                    85.44597701149425,
                    154.93772804433084,
                    85.44597701149425,
                    154.93772804433084,
                    85.44597701149425,
                    154.93772804433084,
                    85.44597701149425,
                    153.93747439074357,
                    85.44597701149425,
                    152.93722073715634,
                    85.44597701149425,
                    151.9369670835691,
                    85.44597701149425,
                    151.9369670835691,
                    85.44597701149425,
                    151.9369670835691,
                    85.44597701149425,
                    150.93671342998184,
                    85.44597701149425,
                    150.93671342998184,
                    85.44597701149425,
                    149.9364597763946,
                    85.44597701149425,
                    149.9364597763946,
                    85.44597701149425,
                    148.93620612280736,
                    85.44597701149425,
                    148.93620612280736,
                    85.44597701149425,
                    147.9359524692201,
                    85.44597701149425,
                    143.93493785487112,
                    85.44597701149425,
                    142.93468420128386,
                    85.44597701149425,
                    141.93443054769662,
                    85.44597701149425,
                    140.93417689410938,
                    85.44597701149425,
                    138.93366958693488,
                    85.44597701149425,
                    138.93366958693488,
                    85.44597701149425,
                    137.93341593334765,
                    85.44597701149425,
                    136.93316227976038,
                    85.44597701149425,
                    135.93290862617314,
                    85.44597701149425,
                    135.93290862617314,
                    85.44597701149425,
                    134.93265497258588,
                    85.44597701149425,
                    133.93240131899864,
                    85.44597701149425,
                    133.93240131899864,
                    85.44597701149425,
                    132.9321476654114,
                    85.44597701149425,
                    128.9311330510624,
                    85.44597701149425,
                    128.9311330510624,
                    85.44597701149425,
                    128.9311330510624,
                    85.44597701149425,
                    127.93087939747517,
                    85.44597701149425,
                    126.93062574388792,
                    85.44597701149425,
                    126.93062574388792,
                    85.44597701149425,
                    125.93037209030066,
                    85.44597701149425,
                    125.93037209030066,
                    85.44597701149425,
                    124.93011843671343,
                    85.44597701149425,
                    124.93011843671343,
                    85.44597701149425,
                    124.93011843671343,
                    85.44597701149425,
                    123.92986478312618,
                    85.44597701149425,
                    123.92986478312618,
                    85.44597701149425,
                    122.92961112953893,
                    85.44597701149425,
                    121.92935747595168,
                    85.44597701149425,
                    121.92935747595168,
                    85.44597701149425,
                    121.92935747595168,
                    85.44597701149425,
                    120.92910382236444,
                    85.44597701149425,
                    120.92910382236444,
                    85.44597701149425,
                    119.92885016877719,
                    85.44597701149425,
                    120.92910382236444,
                    85.44597701149425,
                    121.92935747595168,
                    85.44597701149425,
                    121.92935747595168,
                    85.44597701149425,
                    122.92961112953893,
                    85.44597701149425,
                    123.92986478312618,
                    85.44597701149425,
                    123.92986478312618,
                    85.44597701149425,
                    124.93011843671343,
                    85.44597701149425,
                    125.93037209030066,
                    85.44597701149425,
                    125.93037209030066,
                    84.44367816091955,
                    126.93062574388792,
                    84.44367816091955,
                    126.93062574388792,
                    84.44367816091955,
                    127.93087939747517,
                    84.44367816091955,
                    131.93189401182414,
                    84.44367816091955,
                    132.9321476654114,
                    84.44367816091955,
                    134.93265497258588,
                    84.44367816091955,
                    134.93265497258588,
                    84.44367816091955,
                    136.93316227976038,
                    84.44367816091955,
                    137.93341593334765,
                    84.44367816091955,
                    137.93341593334765,
                    84.44367816091955,
                    138.93366958693488,
                    84.44367816091955,
                    139.93392324052212,
                    84.44367816091955,
                    139.93392324052212,
                    84.44367816091955,
                    139.93392324052212,
                    84.44367816091955,
                    140.93417689410938,
                    84.44367816091955,
                    140.93417689410938,
                    84.44367816091955,
                    141.93443054769662,
                    84.44367816091955,
                    141.93443054769662,
                    84.44367816091955,
                    142.93468420128386,
                    84.44367816091955,
                    142.93468420128386,
                    83.44137931034483,
                    143.93493785487112,
                    83.44137931034483,
                    143.93493785487112,
                    83.44137931034483,
                    144.93519150845836,
                    83.44137931034483,
                    144.93519150845836,
                    83.44137931034483,
                    145.93544516204562,
                    83.44137931034483,
                    145.93544516204562,
                    83.44137931034483,
                    145.93544516204562,
                    83.44137931034483,
                    146.93569881563286,
                    83.44137931034483,
                    147.9359524692201,
                    83.44137931034483,
                    147.9359524692201,
                    83.44137931034483,
                    148.93620612280736,
                    83.44137931034483,
                    148.93620612280736,
                    83.44137931034483,
                    148.93620612280736,
                    83.44137931034483,
                    149.9364597763946,
                    83.44137931034483,
                    149.9364597763946,
                    83.44137931034483,
                    150.93671342998184,
                    83.44137931034483,
                    150.93671342998184,
                    83.44137931034483,
                    150.93671342998184,
                    83.44137931034483,
                    151.9369670835691,
                    83.44137931034483,
                    152.93722073715634,
                    83.44137931034483,
                    152.93722073715634,
                    83.44137931034483,
                    152.93722073715634,
                    83.44137931034483,
                    153.93747439074357,
                    83.44137931034483,
                    153.93747439074357,
                    83.44137931034483,
                    154.93772804433084,
                    83.44137931034483,
                    154.93772804433084,
                    83.44137931034483,
                    155.93798169791808,
                    83.44137931034483,
                    155.93798169791808,
                    83.44137931034483,
                    156.93823535150534,
                    83.44137931034483,
                    156.93823535150534,
                    83.44137931034483,
                    156.93823535150534,
                    83.44137931034483,
                    157.93848900509258,
                    83.44137931034483,
                    157.93848900509258,
                    83.44137931034483,
                    158.93874265867981,
                    83.44137931034483,
                    158.93874265867981,
                    83.44137931034483,
                    158.93874265867981,
                    83.44137931034483,
                    159.93899631226708,
                    83.44137931034483,
                    159.93899631226708,
                    83.44137931034483,
                    160.93924996585432,
                    83.44137931034483,
                    160.93924996585432,
                    83.44137931034483,
                    161.93950361944155,
                    83.44137931034483,
                    161.93950361944155,
                    83.44137931034483,
                    162.93975727302882
                ]
            },
            {
                "tool": "pen",
                "points": [
                    83.44137931034483,
                    129.93138670464967,
                    83.44137931034483,
                    129.93138670464967,
                    83.44137931034483,
                    129.93138670464967,
                    83.44137931034483,
                    128.9311330510624,
                    83.44137931034483,
                    128.9311330510624,
                    83.44137931034483,
                    127.93087939747517,
                    83.44137931034483,
                    127.93087939747517,
                    83.44137931034483,
                    126.93062574388792,
                    83.44137931034483,
                    126.93062574388792,
                    83.44137931034483,
                    125.93037209030066,
                    83.44137931034483,
                    125.93037209030066,
                    83.44137931034483,
                    123.92986478312618,
                    83.44137931034483,
                    123.92986478312618,
                    83.44137931034483,
                    122.92961112953893,
                    83.44137931034483,
                    121.92935747595168,
                    83.44137931034483,
                    121.92935747595168,
                    83.44137931034483,
                    121.92935747595168,
                    83.44137931034483,
                    120.92910382236444,
                    83.44137931034483,
                    120.92910382236444,
                    84.44367816091955,
                    119.92885016877719,
                    84.44367816091955,
                    119.92885016877719,
                    84.44367816091955,
                    119.92885016877719,
                    84.44367816091955,
                    118.92859651518994,
                    84.44367816091955,
                    118.92859651518994,
                    84.44367816091955,
                    117.9283428616027
                ]
            },
            {
                "tool": "pen",
                "points": [
                    66.40229885057471,
                    137.93341593334765,
                    68.40689655172415,
                    136.93316227976038,
                    68.40689655172415,
                    136.93316227976038,
                    69.40919540229885,
                    136.93316227976038,
                    71.41379310344828,
                    136.93316227976038,
                    72.41609195402299,
                    136.93316227976038,
                    73.4183908045977,
                    136.93316227976038,
                    73.4183908045977,
                    136.93316227976038,
                    74.42068965517241,
                    136.93316227976038,
                    75.42298850574713,
                    136.93316227976038,
                    75.42298850574713,
                    136.93316227976038,
                    76.42528735632185,
                    136.93316227976038,
                    76.42528735632185,
                    136.93316227976038,
                    77.42758620689655,
                    136.93316227976038,
                    77.42758620689655,
                    136.93316227976038,
                    77.42758620689655,
                    136.93316227976038,
                    78.42988505747127,
                    136.93316227976038,
                    79.43218390804599,
                    136.93316227976038,
                    79.43218390804599,
                    136.93316227976038,
                    79.43218390804599,
                    136.93316227976038,
                    80.43448275862069,
                    136.93316227976038,
                    81.4367816091954,
                    136.93316227976038,
                    81.4367816091954,
                    136.93316227976038,
                    82.43908045977012,
                    136.93316227976038,
                    83.44137931034483,
                    136.93316227976038,
                    84.44367816091955,
                    136.93316227976038,
                    84.44367816091955,
                    136.93316227976038,
                    84.44367816091955,
                    136.93316227976038,
                    85.44597701149425,
                    136.93316227976038,
                    86.44827586206897,
                    136.93316227976038,
                    86.44827586206897,
                    136.93316227976038,
                    87.45057471264369,
                    136.93316227976038,
                    88.45287356321839,
                    136.93316227976038,
                    88.45287356321839,
                    136.93316227976038,
                    88.45287356321839,
                    136.93316227976038,
                    89.45517241379311,
                    136.93316227976038,
                    90.45747126436783,
                    136.93316227976038,
                    90.45747126436783,
                    136.93316227976038,
                    90.45747126436783,
                    136.93316227976038,
                    91.45977011494253,
                    136.93316227976038,
                    92.46206896551725,
                    136.93316227976038,
                    92.46206896551725,
                    136.93316227976038,
                    93.46436781609196,
                    136.93316227976038,
                    93.46436781609196,
                    136.93316227976038,
                    94.46666666666667,
                    136.93316227976038,
                    95.46896551724139,
                    136.93316227976038,
                    95.46896551724139,
                    136.93316227976038,
                    95.46896551724139,
                    136.93316227976038,
                    96.47126436781609,
                    136.93316227976038,
                    97.47356321839081,
                    136.93316227976038,
                    97.47356321839081,
                    136.93316227976038,
                    97.47356321839081,
                    136.93316227976038,
                    98.47586206896553,
                    136.93316227976038,
                    99.47816091954023,
                    136.93316227976038,
                    100.48045977011495,
                    136.93316227976038,
                    101.48275862068967,
                    136.93316227976038,
                    101.48275862068967,
                    136.93316227976038,
                    103.48735632183909,
                    136.93316227976038,
                    104.48965517241379,
                    136.93316227976038,
                    104.48965517241379,
                    136.93316227976038
                ]
            },
            {
                "tool": "pen",
                "points": [
                    142.57701149425287,
                    145.93544516204562,
                    142.57701149425287,
                    145.93544516204562,
                    142.57701149425287,
                    145.93544516204562,
                    141.57471264367817,
                    144.93519150845836,
                    140.57241379310346,
                    144.93519150845836,
                    140.57241379310346,
                    144.93519150845836,
                    138.56781609195403,
                    144.93519150845836,
                    138.56781609195403,
                    144.93519150845836,
                    137.56551724137933,
                    144.93519150845836,
                    136.5632183908046,
                    143.93493785487112,
                    136.5632183908046,
                    143.93493785487112,
                    135.5609195402299,
                    143.93493785487112,
                    135.5609195402299,
                    143.93493785487112,
                    134.55862068965519,
                    143.93493785487112,
                    134.55862068965519,
                    143.93493785487112,
                    134.55862068965519,
                    143.93493785487112,
                    133.55632183908045,
                    143.93493785487112,
                    132.55402298850575,
                    143.93493785487112,
                    132.55402298850575,
                    143.93493785487112,
                    132.55402298850575,
                    143.93493785487112,
                    131.55172413793105,
                    143.93493785487112,
                    130.5494252873563,
                    143.93493785487112,
                    130.5494252873563,
                    143.93493785487112,
                    130.5494252873563,
                    143.93493785487112,
                    128.5448275862069,
                    144.93519150845836,
                    127.54252873563219,
                    144.93519150845836,
                    127.54252873563219,
                    144.93519150845836,
                    126.54022988505747,
                    145.93544516204562,
                    126.54022988505747,
                    145.93544516204562,
                    126.54022988505747,
                    145.93544516204562,
                    125.53793103448277,
                    146.93569881563286,
                    125.53793103448277,
                    146.93569881563286,
                    124.53563218390805,
                    146.93569881563286,
                    124.53563218390805,
                    147.9359524692201,
                    124.53563218390805,
                    147.9359524692201,
                    123.53333333333333,
                    148.93620612280736,
                    123.53333333333333,
                    148.93620612280736,
                    123.53333333333333,
                    148.93620612280736,
                    122.53103448275863,
                    149.9364597763946,
                    122.53103448275863,
                    149.9364597763946,
                    122.53103448275863,
                    150.93671342998184,
                    122.53103448275863,
                    150.93671342998184,
                    122.53103448275863,
                    151.9369670835691,
                    122.53103448275863,
                    151.9369670835691,
                    122.53103448275863,
                    152.93722073715634,
                    122.53103448275863,
                    152.93722073715634,
                    122.53103448275863,
                    153.93747439074357,
                    122.53103448275863,
                    153.93747439074357,
                    122.53103448275863,
                    154.93772804433084,
                    122.53103448275863,
                    154.93772804433084,
                    122.53103448275863,
                    155.93798169791808,
                    122.53103448275863,
                    155.93798169791808,
                    122.53103448275863,
                    156.93823535150534,
                    122.53103448275863,
                    156.93823535150534,
                    122.53103448275863,
                    157.93848900509258,
                    122.53103448275863,
                    157.93848900509258,
                    122.53103448275863,
                    158.93874265867981,
                    122.53103448275863,
                    159.93899631226708,
                    122.53103448275863,
                    160.93924996585432,
                    122.53103448275863,
                    161.93950361944155,
                    122.53103448275863,
                    162.93975727302882,
                    122.53103448275863,
                    162.93975727302882,
                    122.53103448275863,
                    162.93975727302882,
                    122.53103448275863,
                    163.94001092661605,
                    122.53103448275863,
                    164.9402645802033,
                    122.53103448275863,
                    164.9402645802033,
                    123.53333333333333,
                    164.9402645802033,
                    124.53563218390805,
                    165.94051823379056,
                    124.53563218390805,
                    165.94051823379056,
                    124.53563218390805,
                    165.94051823379056,
                    125.53793103448277,
                    166.9407718873778,
                    125.53793103448277,
                    166.9407718873778,
                    126.54022988505747,
                    166.9407718873778,
                    127.54252873563219,
                    168.9412791945523,
                    127.54252873563219,
                    169.94153284813953,
                    128.5448275862069,
                    169.94153284813953,
                    128.5448275862069,
                    169.94153284813953,
                    129.5471264367816,
                    169.94153284813953,
                    131.55172413793105,
                    170.9417865017268,
                    131.55172413793105,
                    170.9417865017268,
                    131.55172413793105,
                    170.9417865017268,
                    132.55402298850575,
                    170.9417865017268,
                    133.55632183908045,
                    171.94204015531403,
                    133.55632183908045,
                    171.94204015531403,
                    133.55632183908045,
                    171.94204015531403,
                    134.55862068965519,
                    171.94204015531403,
                    134.55862068965519,
                    171.94204015531403,
                    135.5609195402299,
                    171.94204015531403,
                    135.5609195402299,
                    171.94204015531403,
                    136.5632183908046,
                    171.94204015531403,
                    136.5632183908046,
                    171.94204015531403,
                    136.5632183908046,
                    171.94204015531403,
                    137.56551724137933,
                    171.94204015531403,
                    137.56551724137933,
                    171.94204015531403,
                    138.56781609195403,
                    171.94204015531403,
                    138.56781609195403,
                    171.94204015531403,
                    138.56781609195403,
                    171.94204015531403,
                    139.57011494252873,
                    171.94204015531403,
                    140.57241379310346,
                    171.94204015531403,
                    140.57241379310346,
                    171.94204015531403,
                    141.57471264367817,
                    171.94204015531403,
                    141.57471264367817,
                    171.94204015531403,
                    142.57701149425287,
                    171.94204015531403,
                    142.57701149425287,
                    171.94204015531403,
                    143.5793103448276,
                    171.94204015531403,
                    144.5816091954023,
                    171.94204015531403,
                    144.5816091954023,
                    171.94204015531403,
                    144.5816091954023,
                    171.94204015531403,
                    145.583908045977,
                    170.9417865017268,
                    146.58620689655174,
                    170.9417865017268,
                    146.58620689655174,
                    170.9417865017268,
                    147.58850574712645,
                    170.9417865017268,
                    148.59080459770115,
                    169.94153284813953,
                    149.59310344827585,
                    169.94153284813953,
                    149.59310344827585,
                    169.94153284813953,
                    149.59310344827585,
                    168.9412791945523,
                    150.5954022988506,
                    168.9412791945523,
                    150.5954022988506,
                    167.94102554096506,
                    150.5954022988506,
                    167.94102554096506,
                    151.5977011494253,
                    166.9407718873778,
                    151.5977011494253,
                    166.9407718873778,
                    151.5977011494253,
                    165.94051823379056,
                    152.6,
                    165.94051823379056,
                    152.6,
                    164.9402645802033,
                    152.6,
                    164.9402645802033,
                    152.6,
                    164.9402645802033,
                    152.6,
                    164.9402645802033,
                    152.6,
                    163.94001092661605,
                    152.6,
                    161.93950361944155,
                    152.6,
                    161.93950361944155,
                    152.6,
                    160.93924996585432,
                    152.6,
                    160.93924996585432,
                    152.6,
                    159.93899631226708,
                    152.6,
                    159.93899631226708,
                    152.6,
                    158.93874265867981,
                    152.6,
                    158.93874265867981,
                    152.6,
                    158.93874265867981,
                    152.6,
                    157.93848900509258,
                    152.6,
                    157.93848900509258,
                    152.6,
                    156.93823535150534,
                    152.6,
                    156.93823535150534,
                    152.6,
                    156.93823535150534,
                    152.6,
                    155.93798169791808,
                    152.6,
                    155.93798169791808,
                    152.6,
                    154.93772804433084,
                    152.6,
                    154.93772804433084,
                    152.6,
                    153.93747439074357,
                    152.6,
                    152.93722073715634,
                    152.6,
                    152.93722073715634,
                    152.6,
                    151.9369670835691,
                    152.6,
                    151.9369670835691,
                    152.6,
                    151.9369670835691,
                    152.6,
                    150.93671342998184,
                    152.6,
                    150.93671342998184,
                    152.6,
                    149.9364597763946,
                    152.6,
                    149.9364597763946,
                    152.6,
                    149.9364597763946,
                    152.6,
                    148.93620612280736,
                    151.5977011494253,
                    148.93620612280736,
                    151.5977011494253,
                    148.93620612280736,
                    151.5977011494253,
                    147.9359524692201,
                    150.5954022988506,
                    147.9359524692201,
                    150.5954022988506,
                    146.93569881563286,
                    149.59310344827585,
                    146.93569881563286,
                    149.59310344827585,
                    145.93544516204562,
                    148.59080459770115,
                    145.93544516204562,
                    148.59080459770115,
                    145.93544516204562,
                    147.58850574712645,
                    145.93544516204562,
                    147.58850574712645,
                    145.93544516204562,
                    146.58620689655174,
                    145.93544516204562,
                    146.58620689655174,
                    145.93544516204562,
                    146.58620689655174,
                    145.93544516204562,
                    145.583908045977,
                    145.93544516204562,
                    144.5816091954023,
                    144.93519150845836,
                    144.5816091954023,
                    144.93519150845836
                ]
            },
            {
                "tool": "pen",
                "points": [
                    14.282758620689656,
                    266.9661372461025,
                    14.282758620689656,
                    266.9661372461025,
                    14.282758620689656,
                    266.9661372461025,
                    14.282758620689656,
                    266.9661372461025,
                    15.285057471264368,
                    267.96639089968977,
                    15.285057471264368,
                    267.96639089968977,
                    16.28735632183908,
                    268.966644553277,
                    16.28735632183908,
                    268.966644553277,
                    16.28735632183908,
                    269.96689820686424,
                    16.28735632183908,
                    269.96689820686424,
                    17.289655172413795,
                    270.9671518604515,
                    18.291954022988506,
                    270.9671518604515,
                    18.291954022988506,
                    271.9674055140387,
                    18.291954022988506,
                    272.967659167626,
                    18.291954022988506,
                    272.967659167626,
                    19.29425287356322,
                    273.96791282121325,
                    19.29425287356322,
                    273.96791282121325,
                    19.29425287356322,
                    274.96816647480046,
                    20.29655172413793,
                    274.96816647480046,
                    20.29655172413793,
                    275.9684201283877,
                    21.298850574712645,
                    276.968673781975,
                    21.298850574712645,
                    276.968673781975,
                    21.298850574712645,
                    277.9689274355622,
                    22.301149425287356,
                    277.9689274355622,
                    22.301149425287356,
                    278.96918108914946,
                    22.301149425287356,
                    278.96918108914946,
                    23.30344827586207,
                    279.9694347427367,
                    23.30344827586207,
                    279.9694347427367,
                    23.30344827586207,
                    279.9694347427367,
                    23.30344827586207,
                    280.96968839632393,
                    23.30344827586207,
                    281.9699420499112,
                    24.30574712643678,
                    281.9699420499112,
                    25.308045977011496,
                    281.9699420499112,
                    25.308045977011496,
                    281.9699420499112,
                    25.308045977011496,
                    282.97019570349846,
                    26.310344827586206,
                    282.97019570349846,
                    26.310344827586206,
                    283.9704493570857,
                    26.310344827586206,
                    283.9704493570857,
                    27.31264367816092,
                    283.9704493570857,
                    27.31264367816092,
                    284.97070301067293,
                    27.31264367816092,
                    284.97070301067293,
                    28.314942528735635,
                    286.97121031784746,
                    28.314942528735635,
                    286.97121031784746,
                    28.314942528735635,
                    287.9714639714347,
                    28.314942528735635,
                    288.97171762502194,
                    30.31954022988506,
                    289.9719712786092,
                    30.31954022988506,
                    289.9719712786092,
                    30.31954022988506,
                    290.9722249321964,
                    30.31954022988506,
                    290.9722249321964,
                    31.32183908045977,
                    291.9724785857837,
                    31.32183908045977,
                    291.9724785857837,
                    31.32183908045977,
                    292.97273223937094,
                    31.32183908045977,
                    292.97273223937094,
                    31.32183908045977,
                    293.97298589295815,
                    32.324137931034485,
                    293.97298589295815,
                    32.324137931034485,
                    293.97298589295815,
                    32.324137931034485,
                    294.9732395465454,
                    33.326436781609196,
                    295.9734932001327,
                    33.326436781609196,
                    295.9734932001327,
                    33.326436781609196,
                    295.9734932001327,
                    34.32873563218391,
                    296.9737468537199,
                    34.32873563218391,
                    297.97400050730715,
                    37.33563218390805,
                    299.9745078144816,
                    38.33793103448276,
                    300.9747614680689,
                    39.340229885057475,
                    301.97501512165616,
                    40.342528735632186,
                    301.97501512165616,
                    40.342528735632186,
                    302.9752687752434,
                    41.3448275862069,
                    302.9752687752434,
                    41.3448275862069,
                    303.97552242883063,
                    42.34712643678161,
                    304.9757760824179,
                    43.349425287356325,
                    305.97602973600516,
                    43.349425287356325,
                    305.97602973600516,
                    43.349425287356325,
                    306.97628338959237,
                    44.351724137931036,
                    306.97628338959237,
                    45.35402298850575,
                    307.97653704317963,
                    45.35402298850575,
                    308.9767906967669,
                    46.356321839080465,
                    308.9767906967669,
                    47.358620689655176,
                    308.9767906967669,
                    48.36091954022989,
                    310.97729800394137,
                    49.3632183908046,
                    310.97729800394137,
                    50.365517241379315,
                    310.97729800394137,
                    50.365517241379315,
                    311.97755165752864,
                    51.367816091954026,
                    311.97755165752864,
                    52.37011494252874,
                    312.97780531111584,
                    52.37011494252874,
                    313.9780589647031,
                    53.37241379310345,
                    313.9780589647031,
                    53.37241379310345,
                    313.9780589647031,
                    54.374712643678166,
                    314.9783126182904,
                    54.374712643678166,
                    315.9785662718776,
                    55.377011494252876,
                    315.9785662718776,
                    57.3816091954023,
                    316.97881992546485,
                    58.383908045977016,
                    317.9790735790521,
                    59.38620689655173,
                    317.9790735790521,
                    61.390804597701155,
                    318.9793272326393,
                    62.393103448275866,
                    320.97983453981385,
                    62.393103448275866,
                    321.9800881934011,
                    63.39540229885058,
                    321.9800881934011,
                    63.39540229885058,
                    322.9803418469883,
                    63.39540229885058,
                    322.9803418469883,
                    64.39770114942529,
                    322.9803418469883,
                    65.4,
                    322.9803418469883,
                    65.4,
                    322.9803418469883,
                    66.40229885057471,
                    322.9803418469883,
                    67.40459770114943,
                    322.9803418469883,
                    67.40459770114943,
                    323.9805955005756,
                    68.40689655172415,
                    323.9805955005756,
                    69.40919540229885,
                    323.9805955005756,
                    69.40919540229885,
                    323.9805955005756,
                    69.40919540229885,
                    323.9805955005756,
                    70.41149425287357,
                    323.9805955005756,
                    71.41379310344828,
                    323.9805955005756,
                    71.41379310344828,
                    323.9805955005756,
                    72.41609195402299,
                    323.9805955005756,
                    72.41609195402299,
                    323.9805955005756,
                    73.4183908045977,
                    323.9805955005756,
                    73.4183908045977,
                    323.9805955005756,
                    74.42068965517241,
                    323.9805955005756,
                    75.42298850574713,
                    323.9805955005756,
                    75.42298850574713,
                    323.9805955005756,
                    76.42528735632185,
                    323.9805955005756,
                    77.42758620689655,
                    323.9805955005756,
                    78.42988505747127,
                    323.9805955005756,
                    78.42988505747127,
                    323.9805955005756,
                    79.43218390804599,
                    323.9805955005756,
                    80.43448275862069,
                    322.9803418469883,
                    80.43448275862069,
                    322.9803418469883,
                    81.4367816091954,
                    322.9803418469883,
                    81.4367816091954,
                    322.9803418469883,
                    82.43908045977012,
                    322.9803418469883,
                    82.43908045977012,
                    322.9803418469883,
                    83.44137931034483,
                    322.9803418469883,
                    84.44367816091955,
                    322.9803418469883,
                    84.44367816091955,
                    321.9800881934011,
                    84.44367816091955,
                    321.9800881934011,
                    85.44597701149425,
                    321.9800881934011,
                    85.44597701149425,
                    321.9800881934011,
                    86.44827586206897,
                    321.9800881934011,
                    86.44827586206897,
                    321.9800881934011,
                    87.45057471264369,
                    321.9800881934011,
                    88.45287356321839,
                    321.9800881934011,
                    88.45287356321839,
                    320.97983453981385,
                    89.45517241379311,
                    320.97983453981385,
                    89.45517241379311,
                    319.9795808862266,
                    90.45747126436783,
                    318.9793272326393,
                    90.45747126436783,
                    318.9793272326393,
                    90.45747126436783,
                    314.9783126182904,
                    90.45747126436783,
                    310.97729800394137,
                    90.45747126436783,
                    309.9770443503541,
                    90.45747126436783,
                    307.97653704317963,
                    90.45747126436783,
                    306.97628338959237,
                    90.45747126436783,
                    305.97602973600516,
                    90.45747126436783,
                    304.9757760824179,
                    90.45747126436783,
                    304.9757760824179,
                    90.45747126436783,
                    302.9752687752434,
                    90.45747126436783,
                    300.9747614680689,
                    90.45747126436783,
                    300.9747614680689,
                    90.45747126436783,
                    299.9745078144816,
                    90.45747126436783,
                    297.97400050730715,
                    90.45747126436783,
                    296.9737468537199,
                    90.45747126436783,
                    295.9734932001327,
                    90.45747126436783,
                    295.9734932001327,
                    89.45517241379311,
                    291.9724785857837,
                    88.45287356321839,
                    291.9724785857837,
                    88.45287356321839,
                    290.9722249321964,
                    88.45287356321839,
                    290.9722249321964,
                    86.44827586206897,
                    288.97171762502194,
                    86.44827586206897,
                    288.97171762502194,
                    86.44827586206897,
                    287.9714639714347,
                    86.44827586206897,
                    286.97121031784746,
                    85.44597701149425,
                    285.9709566642602,
                    85.44597701149425,
                    285.9709566642602,
                    85.44597701149425,
                    284.97070301067293,
                    84.44367816091955,
                    284.97070301067293,
                    84.44367816091955,
                    285.9709566642602,
                    84.44367816091955,
                    285.9709566642602,
                    84.44367816091955,
                    286.97121031784746,
                    85.44597701149425,
                    286.97121031784746,
                    85.44597701149425,
                    286.97121031784746,
                    85.44597701149425,
                    287.9714639714347,
                    85.44597701149425,
                    288.97171762502194,
                    85.44597701149425,
                    288.97171762502194,
                    86.44827586206897,
                    289.9719712786092,
                    86.44827586206897,
                    289.9719712786092,
                    88.45287356321839,
                    292.97273223937094,
                    88.45287356321839,
                    293.97298589295815,
                    88.45287356321839,
                    293.97298589295815,
                    88.45287356321839,
                    294.9732395465454,
                    88.45287356321839,
                    295.9734932001327,
                    88.45287356321839,
                    295.9734932001327,
                    89.45517241379311,
                    295.9734932001327,
                    89.45517241379311,
                    296.9737468537199,
                    90.45747126436783,
                    297.97400050730715,
                    90.45747126436783,
                    297.97400050730715,
                    90.45747126436783,
                    298.9742541608944,
                    90.45747126436783,
                    298.9742541608944,
                    90.45747126436783,
                    299.9745078144816,
                    90.45747126436783,
                    299.9745078144816,
                    91.45977011494253,
                    300.9747614680689,
                    91.45977011494253,
                    301.97501512165616,
                    91.45977011494253,
                    301.97501512165616,
                    91.45977011494253,
                    302.9752687752434,
                    91.45977011494253,
                    302.9752687752434,
                    92.46206896551725,
                    304.9757760824179,
                    92.46206896551725,
                    305.97602973600516,
                    92.46206896551725,
                    305.97602973600516,
                    93.46436781609196,
                    307.97653704317963,
                    93.46436781609196,
                    308.9767906967669,
                    93.46436781609196,
                    310.97729800394137,
                    93.46436781609196,
                    316.97881992546485,
                    93.46436781609196,
                    317.9790735790521,
                    93.46436781609196,
                    318.9793272326393,
                    93.46436781609196,
                    320.97983453981385,
                    93.46436781609196,
                    320.97983453981385,
                    93.46436781609196,
                    322.9803418469883,
                    93.46436781609196,
                    323.9805955005756,
                    93.46436781609196,
                    323.9805955005756,
                    93.46436781609196,
                    324.98084915416285,
                    93.46436781609196,
                    324.98084915416285,
                    93.46436781609196,
                    325.98110280775006,
                    93.46436781609196,
                    325.98110280775006,
                    93.46436781609196,
                    326.9813564613373,
                    93.46436781609196,
                    326.9813564613373,
                    92.46206896551725,
                    327.9816101149246,
                    92.46206896551725,
                    327.9816101149246,
                    91.45977011494253,
                    327.9816101149246,
                    90.45747126436783,
                    327.9816101149246,
                    89.45517241379311,
                    327.9816101149246,
                    89.45517241379311,
                    327.9816101149246,
                    88.45287356321839,
                    327.9816101149246,
                    87.45057471264369,
                    327.9816101149246,
                    87.45057471264369,
                    327.9816101149246,
                    86.44827586206897,
                    327.9816101149246,
                    86.44827586206897,
                    327.9816101149246,
                    86.44827586206897,
                    327.9816101149246,
                    84.44367816091955,
                    327.9816101149246,
                    83.44137931034483,
                    327.9816101149246,
                    83.44137931034483,
                    327.9816101149246,
                    83.44137931034483,
                    327.9816101149246,
                    82.43908045977012,
                    327.9816101149246,
                    82.43908045977012,
                    327.9816101149246,
                    81.4367816091954,
                    327.9816101149246,
                    81.4367816091954,
                    327.9816101149246,
                    81.4367816091954,
                    327.9816101149246,
                    80.43448275862069,
                    327.9816101149246,
                    79.43218390804599,
                    327.9816101149246,
                    79.43218390804599,
                    327.9816101149246,
                    78.42988505747127,
                    327.9816101149246,
                    78.42988505747127,
                    327.9816101149246,
                    77.42758620689655,
                    327.9816101149246,
                    75.42298850574713,
                    327.9816101149246,
                    75.42298850574713,
                    327.9816101149246,
                    74.42068965517241,
                    327.9816101149246,
                    73.4183908045977,
                    327.9816101149246,
                    71.41379310344828,
                    327.9816101149246,
                    69.40919540229885,
                    327.9816101149246,
                    69.40919540229885,
                    327.9816101149246,
                    68.40689655172415,
                    327.9816101149246,
                    67.40459770114943,
                    327.9816101149246,
                    66.40229885057471,
                    327.9816101149246,
                    65.4,
                    327.9816101149246,
                    65.4,
                    328.9818637685118,
                    64.39770114942529,
                    328.9818637685118,
                    63.39540229885058,
                    328.9818637685118,
                    63.39540229885058,
                    328.9818637685118,
                    62.393103448275866,
                    328.9818637685118,
                    62.393103448275866,
                    328.9818637685118,
                    61.390804597701155,
                    328.9818637685118,
                    59.38620689655173,
                    329.98211742209907,
                    59.38620689655173,
                    330.98237107568633,
                    58.383908045977016,
                    330.98237107568633,
                    57.3816091954023,
                    330.98237107568633,
                    57.3816091954023,
                    330.98237107568633,
                    56.37931034482759,
                    330.98237107568633,
                    52.37011494252874,
                    331.98262472927354,
                    52.37011494252874,
                    331.98262472927354,
                    52.37011494252874,
                    331.98262472927354,
                    51.367816091954026,
                    332.9828783828608
                ]
            },
            {
                "tool": "eraser",
                "points": [
                    39.340229885057475,
                    332.9828783828608,
                    40.342528735632186,
                    332.9828783828608,
                    41.3448275862069,
                    332.9828783828608,
                    41.3448275862069,
                    332.9828783828608,
                    42.34712643678161,
                    332.9828783828608,
                    43.349425287356325,
                    332.9828783828608,
                    43.349425287356325,
                    332.9828783828608,
                    44.351724137931036,
                    332.9828783828608,
                    44.351724137931036,
                    332.9828783828608,
                    46.356321839080465,
                    333.98313203644807,
                    47.358620689655176,
                    333.98313203644807,
                    48.36091954022989,
                    333.98313203644807,
                    48.36091954022989,
                    333.98313203644807,
                    49.3632183908046,
                    333.98313203644807,
                    50.365517241379315,
                    333.98313203644807,
                    50.365517241379315,
                    333.98313203644807,
                    51.367816091954026,
                    333.98313203644807,
                    51.367816091954026,
                    333.98313203644807,
                    52.37011494252874,
                    333.98313203644807,
                    52.37011494252874,
                    333.98313203644807,
                    52.37011494252874,
                    333.98313203644807,
                    53.37241379310345,
                    333.98313203644807,
                    54.374712643678166,
                    333.98313203644807,
                    54.374712643678166,
                    333.98313203644807,
                    55.377011494252876,
                    334.9833856900353,
                    55.377011494252876,
                    334.9833856900353,
                    56.37931034482759,
                    334.9833856900353,
                    56.37931034482759,
                    334.9833856900353,
                    57.3816091954023,
                    334.9833856900353,
                    57.3816091954023,
                    334.9833856900353,
                    58.383908045977016,
                    335.98363934362254,
                    58.383908045977016,
                    335.98363934362254,
                    59.38620689655173,
                    335.98363934362254,
                    58.383908045977016,
                    335.98363934362254,
                    58.383908045977016,
                    335.98363934362254,
                    57.3816091954023,
                    335.98363934362254,
                    57.3816091954023,
                    335.98363934362254,
                    56.37931034482759,
                    334.9833856900353,
                    55.377011494252876,
                    334.9833856900353,
                    54.374712643678166,
                    334.9833856900353,
                    54.374712643678166,
                    334.9833856900353,
                    53.37241379310345,
                    333.98313203644807,
                    52.37011494252874,
                    333.98313203644807,
                    51.367816091954026,
                    333.98313203644807,
                    50.365517241379315,
                    333.98313203644807,
                    49.3632183908046,
                    332.9828783828608,
                    49.3632183908046,
                    332.9828783828608,
                    48.36091954022989,
                    332.9828783828608,
                    48.36091954022989,
                    332.9828783828608,
                    46.356321839080465,
                    330.98237107568633,
                    46.356321839080465,
                    330.98237107568633,
                    44.351724137931036,
                    329.98211742209907,
                    43.349425287356325,
                    328.9818637685118,
                    43.349425287356325,
                    328.9818637685118,
                    43.349425287356325,
                    328.9818637685118,
                    42.34712643678161,
                    327.9816101149246,
                    41.3448275862069,
                    327.9816101149246,
                    41.3448275862069,
                    327.9816101149246,
                    41.3448275862069,
                    327.9816101149246,
                    42.34712643678161,
                    327.9816101149246,
                    43.349425287356325,
                    327.9816101149246,
                    43.349425287356325,
                    327.9816101149246,
                    44.351724137931036,
                    327.9816101149246,
                    44.351724137931036,
                    328.9818637685118,
                    45.35402298850575,
                    328.9818637685118,
                    45.35402298850575,
                    328.9818637685118,
                    45.35402298850575,
                    328.9818637685118,
                    46.356321839080465,
                    328.9818637685118,
                    47.358620689655176,
                    328.9818637685118,
                    47.358620689655176,
                    328.9818637685118,
                    48.36091954022989,
                    328.9818637685118,
                    49.3632183908046,
                    329.98211742209907,
                    49.3632183908046,
                    329.98211742209907,
                    49.3632183908046,
                    329.98211742209907,
                    50.365517241379315,
                    329.98211742209907,
                    51.367816091954026,
                    329.98211742209907,
                    51.367816091954026,
                    330.98237107568633,
                    51.367816091954026,
                    330.98237107568633,
                    52.37011494252874,
                    330.98237107568633,
                    52.37011494252874,
                    331.98262472927354,
                    53.37241379310345,
                    331.98262472927354,
                    53.37241379310345,
                    331.98262472927354,
                    55.377011494252876,
                    332.9828783828608,
                    55.377011494252876,
                    332.9828783828608,
                    56.37931034482759,
                    332.9828783828608,
                    57.3816091954023,
                    332.9828783828608,
                    57.3816091954023,
                    332.9828783828608,
                    59.38620689655173,
                    333.98313203644807,
                    59.38620689655173,
                    333.98313203644807,
                    60.38850574712644,
                    333.98313203644807,
                    60.38850574712644,
                    333.98313203644807,
                    61.390804597701155,
                    334.9833856900353,
                    61.390804597701155,
                    334.9833856900353,
                    62.393103448275866,
                    334.9833856900353,
                    62.393103448275866,
                    334.9833856900353,
                    63.39540229885058,
                    334.9833856900353,
                    64.39770114942529,
                    334.9833856900353,
                    64.39770114942529,
                    334.9833856900353,
                    64.39770114942529,
                    335.98363934362254,
                    65.4,
                    335.98363934362254,
                    65.4,
                    335.98363934362254,
                    66.40229885057471,
                    335.98363934362254,
                    66.40229885057471,
                    335.98363934362254,
                    66.40229885057471,
                    335.98363934362254,
                    67.40459770114943,
                    335.98363934362254,
                    67.40459770114943,
                    335.98363934362254,
                    68.40689655172415,
                    336.9838929972098,
                    68.40689655172415,
                    336.9838929972098,
                    69.40919540229885,
                    336.9838929972098,
                    69.40919540229885,
                    336.9838929972098,
                    70.41149425287357,
                    336.9838929972098,
                    70.41149425287357,
                    336.9838929972098,
                    71.41379310344828,
                    337.984146650797,
                    72.41609195402299,
                    337.984146650797,
                    72.41609195402299,
                    337.984146650797,
                    73.4183908045977,
                    337.984146650797,
                    73.4183908045977,
                    337.984146650797,
                    73.4183908045977,
                    337.984146650797,
                    74.42068965517241,
                    338.9844003043843,
                    75.42298850574713,
                    338.9844003043843,
                    75.42298850574713,
                    338.9844003043843,
                    76.42528735632185,
                    338.9844003043843,
                    76.42528735632185,
                    338.9844003043843,
                    77.42758620689655,
                    338.9844003043843,
                    77.42758620689655,
                    338.9844003043843,
                    77.42758620689655,
                    338.9844003043843,
                    78.42988505747127,
                    338.9844003043843,
                    78.42988505747127,
                    338.9844003043843,
                    79.43218390804599,
                    338.9844003043843,
                    79.43218390804599,
                    338.9844003043843,
                    79.43218390804599,
                    339.98465395797155,
                    80.43448275862069,
                    339.98465395797155,
                    81.4367816091954,
                    339.98465395797155,
                    81.4367816091954,
                    339.98465395797155,
                    81.4367816091954,
                    339.98465395797155,
                    82.43908045977012,
                    339.98465395797155,
                    82.43908045977012,
                    340.98490761155875,
                    83.44137931034483,
                    340.98490761155875,
                    83.44137931034483,
                    340.98490761155875,
                    83.44137931034483,
                    340.98490761155875,
                    84.44367816091955,
                    340.98490761155875,
                    84.44367816091955,
                    340.98490761155875,
                    85.44597701149425,
                    340.98490761155875,
                    85.44597701149425,
                    340.98490761155875,
                    86.44827586206897,
                    340.98490761155875
                ]
            },
            {
                "tool": "eraser",
                "points": [
                    99.47816091954023,
                    340.98490761155875,
                    99.47816091954023,
                    341.985161265146,
                    100.48045977011495,
                    341.985161265146,
                    100.48045977011495,
                    341.985161265146,
                    101.48275862068967,
                    341.985161265146,
                    101.48275862068967,
                    340.98490761155875,
                    101.48275862068967,
                    339.98465395797155,
                    101.48275862068967,
                    339.98465395797155,
                    101.48275862068967,
                    339.98465395797155,
                    101.48275862068967,
                    338.9844003043843,
                    101.48275862068967,
                    338.9844003043843,
                    101.48275862068967,
                    338.9844003043843,
                    101.48275862068967,
                    337.984146650797,
                    102.48505747126437,
                    337.984146650797,
                    102.48505747126437,
                    336.9838929972098,
                    102.48505747126437,
                    336.9838929972098,
                    102.48505747126437,
                    336.9838929972098,
                    102.48505747126437,
                    335.98363934362254,
                    102.48505747126437,
                    335.98363934362254,
                    102.48505747126437,
                    333.98313203644807,
                    102.48505747126437,
                    333.98313203644807,
                    102.48505747126437,
                    332.9828783828608,
                    102.48505747126437,
                    332.9828783828608,
                    102.48505747126437,
                    331.98262472927354,
                    102.48505747126437,
                    331.98262472927354,
                    102.48505747126437,
                    331.98262472927354,
                    102.48505747126437,
                    330.98237107568633,
                    102.48505747126437,
                    330.98237107568633,
                    102.48505747126437,
                    329.98211742209907,
                    102.48505747126437,
                    329.98211742209907,
                    102.48505747126437,
                    328.9818637685118,
                    102.48505747126437,
                    328.9818637685118,
                    102.48505747126437,
                    327.9816101149246,
                    102.48505747126437,
                    327.9816101149246,
                    102.48505747126437,
                    326.9813564613373,
                    102.48505747126437,
                    326.9813564613373,
                    102.48505747126437,
                    325.98110280775006,
                    102.48505747126437,
                    325.98110280775006,
                    102.48505747126437,
                    324.98084915416285,
                    102.48505747126437,
                    324.98084915416285,
                    102.48505747126437,
                    324.98084915416285,
                    102.48505747126437,
                    323.9805955005756,
                    102.48505747126437,
                    323.9805955005756,
                    102.48505747126437,
                    322.9803418469883,
                    102.48505747126437,
                    322.9803418469883,
                    102.48505747126437,
                    321.9800881934011,
                    102.48505747126437,
                    321.9800881934011,
                    102.48505747126437,
                    321.9800881934011,
                    102.48505747126437,
                    320.97983453981385,
                    102.48505747126437,
                    319.9795808862266,
                    102.48505747126437,
                    319.9795808862266,
                    101.48275862068967,
                    317.9790735790521,
                    101.48275862068967,
                    317.9790735790521,
                    101.48275862068967,
                    316.97881992546485,
                    101.48275862068967,
                    315.9785662718776,
                    101.48275862068967,
                    315.9785662718776,
                    101.48275862068967,
                    314.9783126182904,
                    101.48275862068967,
                    314.9783126182904,
                    101.48275862068967,
                    314.9783126182904,
                    101.48275862068967,
                    313.9780589647031,
                    101.48275862068967,
                    313.9780589647031,
                    101.48275862068967,
                    312.97780531111584,
                    100.48045977011495,
                    311.97755165752864,
                    100.48045977011495,
                    309.9770443503541,
                    100.48045977011495,
                    308.9767906967669,
                    100.48045977011495,
                    308.9767906967669,
                    100.48045977011495,
                    308.9767906967669,
                    100.48045977011495,
                    307.97653704317963,
                    100.48045977011495,
                    306.97628338959237,
                    100.48045977011495,
                    306.97628338959237,
                    99.47816091954023,
                    305.97602973600516,
                    99.47816091954023,
                    305.97602973600516,
                    99.47816091954023,
                    305.97602973600516,
                    99.47816091954023,
                    304.9757760824179,
                    99.47816091954023,
                    304.9757760824179,
                    99.47816091954023,
                    303.97552242883063,
                    99.47816091954023,
                    303.97552242883063,
                    99.47816091954023,
                    302.9752687752434,
                    99.47816091954023,
                    302.9752687752434,
                    99.47816091954023,
                    301.97501512165616,
                    99.47816091954023,
                    301.97501512165616,
                    99.47816091954023,
                    300.9747614680689,
                    99.47816091954023,
                    300.9747614680689,
                    99.47816091954023,
                    299.9745078144816,
                    99.47816091954023,
                    299.9745078144816,
                    99.47816091954023,
                    298.9742541608944,
                    99.47816091954023,
                    298.9742541608944,
                    99.47816091954023,
                    297.97400050730715,
                    99.47816091954023,
                    297.97400050730715,
                    99.47816091954023,
                    296.9737468537199,
                    99.47816091954023,
                    296.9737468537199,
                    99.47816091954023,
                    296.9737468537199,
                    99.47816091954023,
                    295.9734932001327,
                    99.47816091954023,
                    295.9734932001327,
                    99.47816091954023,
                    294.9732395465454,
                    99.47816091954023,
                    294.9732395465454,
                    98.47586206896553,
                    292.97273223937094,
                    98.47586206896553,
                    291.9724785857837,
                    98.47586206896553,
                    291.9724785857837,
                    98.47586206896553,
                    291.9724785857837,
                    98.47586206896553,
                    290.9722249321964,
                    97.47356321839081,
                    288.97171762502194,
                    96.47126436781609,
                    288.97171762502194,
                    96.47126436781609,
                    288.97171762502194,
                    96.47126436781609,
                    287.9714639714347,
                    95.46896551724139,
                    286.97121031784746,
                    95.46896551724139,
                    285.9709566642602,
                    95.46896551724139,
                    285.9709566642602,
                    94.46666666666667,
                    284.97070301067293,
                    93.46436781609196,
                    282.97019570349846,
                    93.46436781609196,
                    282.97019570349846,
                    93.46436781609196,
                    281.9699420499112,
                    92.46206896551725,
                    279.9694347427367,
                    92.46206896551725,
                    279.9694347427367,
                    91.45977011494253,
                    278.96918108914946,
                    91.45977011494253,
                    278.96918108914946,
                    91.45977011494253,
                    277.9689274355622,
                    91.45977011494253,
                    277.9689274355622,
                    91.45977011494253,
                    276.968673781975,
                    91.45977011494253,
                    275.9684201283877,
                    90.45747126436783,
                    275.9684201283877,
                    90.45747126436783,
                    275.9684201283877,
                    90.45747126436783,
                    276.968673781975,
                    89.45517241379311,
                    276.968673781975,
                    88.45287356321839,
                    276.968673781975,
                    88.45287356321839,
                    277.9689274355622,
                    87.45057471264369,
                    277.9689274355622,
                    87.45057471264369,
                    278.96918108914946,
                    86.44827586206897,
                    278.96918108914946,
                    86.44827586206897,
                    279.9694347427367,
                    86.44827586206897,
                    280.96968839632393,
                    85.44597701149425,
                    280.96968839632393,
                    84.44367816091955,
                    281.9699420499112,
                    84.44367816091955,
                    281.9699420499112,
                    84.44367816091955,
                    283.9704493570857,
                    84.44367816091955,
                    284.97070301067293,
                    84.44367816091955,
                    284.97070301067293,
                    84.44367816091955,
                    285.9709566642602,
                    84.44367816091955,
                    286.97121031784746,
                    84.44367816091955,
                    286.97121031784746,
                    84.44367816091955,
                    286.97121031784746,
                    84.44367816091955,
                    288.97171762502194,
                    84.44367816091955,
                    289.9719712786092,
                    84.44367816091955,
                    289.9719712786092,
                    84.44367816091955,
                    290.9722249321964,
                    84.44367816091955,
                    291.9724785857837,
                    84.44367816091955,
                    291.9724785857837,
                    84.44367816091955,
                    292.97273223937094,
                    84.44367816091955,
                    290.9722249321964,
                    84.44367816091955,
                    289.9719712786092,
                    84.44367816091955,
                    288.97171762502194,
                    84.44367816091955,
                    286.97121031784746,
                    84.44367816091955,
                    285.9709566642602,
                    84.44367816091955,
                    285.9709566642602,
                    84.44367816091955,
                    284.97070301067293,
                    84.44367816091955,
                    283.9704493570857,
                    84.44367816091955,
                    283.9704493570857,
                    84.44367816091955,
                    282.97019570349846,
                    84.44367816091955,
                    282.97019570349846,
                    84.44367816091955,
                    281.9699420499112,
                    83.44137931034483,
                    282.97019570349846,
                    83.44137931034483,
                    283.9704493570857,
                    83.44137931034483,
                    284.97070301067293,
                    83.44137931034483,
                    285.9709566642602,
                    83.44137931034483,
                    287.9714639714347,
                    83.44137931034483,
                    287.9714639714347,
                    83.44137931034483,
                    289.9719712786092,
                    83.44137931034483,
                    291.9724785857837,
                    83.44137931034483,
                    291.9724785857837,
                    83.44137931034483,
                    292.97273223937094,
                    83.44137931034483,
                    293.97298589295815,
                    83.44137931034483,
                    294.9732395465454,
                    83.44137931034483,
                    295.9734932001327,
                    83.44137931034483,
                    295.9734932001327,
                    83.44137931034483,
                    296.9737468537199,
                    83.44137931034483,
                    296.9737468537199,
                    83.44137931034483,
                    297.97400050730715,
                    83.44137931034483,
                    297.97400050730715,
                    83.44137931034483,
                    298.9742541608944,
                    83.44137931034483,
                    298.9742541608944,
                    83.44137931034483,
                    299.9745078144816,
                    83.44137931034483,
                    299.9745078144816,
                    83.44137931034483,
                    300.9747614680689,
                    83.44137931034483,
                    300.9747614680689,
                    83.44137931034483,
                    300.9747614680689,
                    83.44137931034483,
                    301.97501512165616,
                    83.44137931034483,
                    301.97501512165616,
                    83.44137931034483,
                    302.9752687752434,
                    83.44137931034483,
                    302.9752687752434,
                    83.44137931034483,
                    303.97552242883063,
                    83.44137931034483,
                    303.97552242883063,
                    83.44137931034483,
                    304.9757760824179,
                    83.44137931034483,
                    304.9757760824179,
                    83.44137931034483,
                    305.97602973600516,
                    84.44367816091955,
                    306.97628338959237,
                    84.44367816091955,
                    306.97628338959237,
                    85.44597701149425,
                    306.97628338959237,
                    85.44597701149425,
                    306.97628338959237,
                    85.44597701149425,
                    307.97653704317963,
                    85.44597701149425,
                    307.97653704317963,
                    85.44597701149425,
                    308.9767906967669,
                    86.44827586206897,
                    308.9767906967669,
                    86.44827586206897,
                    309.9770443503541,
                    86.44827586206897,
                    310.97729800394137,
                    86.44827586206897,
                    310.97729800394137,
                    86.44827586206897,
                    310.97729800394137,
                    86.44827586206897,
                    311.97755165752864,
                    87.45057471264369,
                    311.97755165752864,
                    87.45057471264369,
                    310.97729800394137,
                    88.45287356321839,
                    310.97729800394137,
                    88.45287356321839,
                    309.9770443503541,
                    88.45287356321839,
                    308.9767906967669,
                    89.45517241379311,
                    304.9757760824179,
                    89.45517241379311,
                    302.9752687752434,
                    89.45517241379311,
                    302.9752687752434,
                    89.45517241379311,
                    298.9742541608944,
                    89.45517241379311,
                    296.9737468537199,
                    90.45747126436783,
                    296.9737468537199,
                    90.45747126436783,
                    295.9734932001327,
                    90.45747126436783,
                    294.9732395465454,
                    90.45747126436783,
                    294.9732395465454,
                    90.45747126436783,
                    293.97298589295815,
                    90.45747126436783,
                    293.97298589295815,
                    90.45747126436783,
                    293.97298589295815,
                    89.45517241379311,
                    299.9745078144816,
                    89.45517241379311,
                    301.97501512165616,
                    88.45287356321839,
                    303.97552242883063,
                    88.45287356321839,
                    306.97628338959237,
                    88.45287356321839,
                    307.97653704317963,
                    88.45287356321839,
                    308.9767906967669,
                    88.45287356321839,
                    310.97729800394137,
                    87.45057471264369,
                    312.97780531111584,
                    87.45057471264369,
                    312.97780531111584,
                    87.45057471264369,
                    313.9780589647031,
                    87.45057471264369,
                    315.9785662718776,
                    87.45057471264369,
                    315.9785662718776,
                    87.45057471264369,
                    316.97881992546485,
                    87.45057471264369,
                    317.9790735790521,
                    87.45057471264369,
                    318.9793272326393,
                    87.45057471264369,
                    319.9795808862266,
                    87.45057471264369,
                    319.9795808862266,
                    87.45057471264369,
                    320.97983453981385,
                    87.45057471264369,
                    320.97983453981385,
                    87.45057471264369,
                    321.9800881934011,
                    87.45057471264369,
                    321.9800881934011,
                    87.45057471264369,
                    322.9803418469883,
                    87.45057471264369,
                    322.9803418469883,
                    87.45057471264369,
                    323.9805955005756,
                    87.45057471264369,
                    323.9805955005756,
                    87.45057471264369,
                    324.98084915416285,
                    87.45057471264369,
                    324.98084915416285,
                    87.45057471264369,
                    325.98110280775006,
                    87.45057471264369,
                    327.9816101149246,
                    87.45057471264369,
                    328.9818637685118,
                    87.45057471264369,
                    328.9818637685118,
                    87.45057471264369,
                    329.98211742209907,
                    87.45057471264369,
                    331.98262472927354,
                    87.45057471264369,
                    331.98262472927354,
                    87.45057471264369,
                    331.98262472927354,
                    87.45057471264369,
                    332.9828783828608,
                    87.45057471264369,
                    332.9828783828608,
                    87.45057471264369,
                    333.98313203644807,
                    87.45057471264369,
                    333.98313203644807,
                    87.45057471264369,
                    333.98313203644807,
                    87.45057471264369,
                    334.9833856900353,
                    87.45057471264369,
                    335.98363934362254,
                    87.45057471264369,
                    335.98363934362254,
                    87.45057471264369,
                    335.98363934362254,
                    88.45287356321839,
                    335.98363934362254,
                    89.45517241379311,
                    334.9833856900353,
                    89.45517241379311,
                    333.98313203644807,
                    90.45747126436783,
                    333.98313203644807,
                    90.45747126436783,
                    333.98313203644807,
                    91.45977011494253,
                    332.9828783828608,
                    91.45977011494253,
                    331.98262472927354,
                    91.45977011494253,
                    331.98262472927354,
                    92.46206896551725,
                    330.98237107568633,
                    92.46206896551725,
                    330.98237107568633,
                    93.46436781609196,
                    330.98237107568633,
                    93.46436781609196,
                    329.98211742209907,
                    93.46436781609196,
                    329.98211742209907,
                    92.46206896551725,
                    329.98211742209907,
                    90.45747126436783,
                    329.98211742209907,
                    90.45747126436783,
                    329.98211742209907,
                    90.45747126436783,
                    329.98211742209907,
                    89.45517241379311,
                    329.98211742209907,
                    88.45287356321839,
                    329.98211742209907,
                    88.45287356321839,
                    330.98237107568633,
                    86.44827586206897,
                    330.98237107568633,
                    85.44597701149425,
                    331.98262472927354,
                    84.44367816091955,
                    331.98262472927354,
                    83.44137931034483,
                    332.9828783828608,
                    81.4367816091954,
                    333.98313203644807,
                    81.4367816091954,
                    333.98313203644807,
                    81.4367816091954,
                    334.9833856900353,
                    80.43448275862069,
                    334.9833856900353,
                    80.43448275862069,
                    334.9833856900353,
                    79.43218390804599,
                    335.98363934362254,
                    79.43218390804599,
                    334.9833856900353,
                    79.43218390804599,
                    332.9828783828608,
                    80.43448275862069,
                    332.9828783828608,
                    80.43448275862069,
                    331.98262472927354,
                    81.4367816091954,
                    330.98237107568633,
                    82.43908045977012,
                    328.9818637685118,
                    83.44137931034483,
                    326.9813564613373,
                    83.44137931034483,
                    324.98084915416285,
                    84.44367816091955,
                    322.9803418469883,
                    84.44367816091955,
                    321.9800881934011,
                    85.44597701149425,
                    319.9795808862266,
                    86.44827586206897,
                    317.9790735790521,
                    86.44827586206897,
                    317.9790735790521,
                    86.44827586206897,
                    316.97881992546485,
                    86.44827586206897,
                    315.9785662718776,
                    86.44827586206897,
                    315.9785662718776,
                    86.44827586206897,
                    314.9783126182904,
                    86.44827586206897,
                    314.9783126182904,
                    86.44827586206897,
                    313.9780589647031,
                    86.44827586206897,
                    313.9780589647031,
                    86.44827586206897,
                    312.97780531111584,
                    86.44827586206897,
                    313.9780589647031,
                    84.44367816091955,
                    313.9780589647031,
                    82.43908045977012,
                    314.9783126182904,
                    80.43448275862069,
                    315.9785662718776,
                    78.42988505747127,
                    316.97881992546485,
                    78.42988505747127,
                    317.9790735790521,
                    76.42528735632185,
                    318.9793272326393,
                    75.42298850574713,
                    319.9795808862266,
                    75.42298850574713,
                    319.9795808862266,
                    74.42068965517241,
                    319.9795808862266,
                    71.41379310344828,
                    322.9803418469883,
                    71.41379310344828,
                    323.9805955005756,
                    70.41149425287357,
                    323.9805955005756,
                    69.40919540229885,
                    324.98084915416285,
                    68.40689655172415,
                    325.98110280775006,
                    68.40689655172415,
                    325.98110280775006,
                    68.40689655172415,
                    326.9813564613373,
                    67.40459770114943,
                    326.9813564613373,
                    67.40459770114943,
                    326.9813564613373,
                    66.40229885057471,
                    327.9816101149246,
                    66.40229885057471,
                    328.9818637685118,
                    66.40229885057471,
                    328.9818637685118,
                    66.40229885057471,
                    328.9818637685118,
                    65.4,
                    329.98211742209907,
                    65.4,
                    330.98237107568633,
                    65.4,
                    330.98237107568633,
                    64.39770114942529,
                    331.98262472927354,
                    64.39770114942529,
                    331.98262472927354,
                    64.39770114942529,
                    332.9828783828608,
                    63.39540229885058,
                    332.9828783828608,
                    63.39540229885058,
                    332.9828783828608
                ]
            },
            {
                "tool": "pen",
                "points": [
                    51.367816091954026,
                    310.97729800394137,
                    51.367816091954026,
                    311.97755165752864,
                    51.367816091954026,
                    311.97755165752864,
                    53.37241379310345,
                    312.97780531111584,
                    53.37241379310345,
                    313.9780589647031,
                    54.374712643678166,
                    313.9780589647031,
                    54.374712643678166,
                    313.9780589647031,
                    54.374712643678166,
                    314.9783126182904,
                    55.377011494252876,
                    314.9783126182904,
                    56.37931034482759,
                    315.9785662718776,
                    56.37931034482759,
                    315.9785662718776,
                    57.3816091954023,
                    315.9785662718776,
                    57.3816091954023,
                    315.9785662718776,
                    58.383908045977016,
                    316.97881992546485,
                    58.383908045977016,
                    316.97881992546485,
                    59.38620689655173,
                    316.97881992546485,
                    59.38620689655173,
                    317.9790735790521,
                    60.38850574712644,
                    317.9790735790521,
                    60.38850574712644,
                    318.9793272326393,
                    61.390804597701155,
                    319.9795808862266,
                    61.390804597701155,
                    319.9795808862266,
                    62.393103448275866,
                    319.9795808862266,
                    63.39540229885058,
                    319.9795808862266,
                    63.39540229885058,
                    320.97983453981385,
                    64.39770114942529,
                    321.9800881934011,
                    64.39770114942529,
                    321.9800881934011,
                    65.4,
                    321.9800881934011,
                    65.4,
                    322.9803418469883,
                    66.40229885057471,
                    322.9803418469883,
                    66.40229885057471,
                    322.9803418469883,
                    67.40459770114943,
                    323.9805955005756,
                    67.40459770114943,
                    323.9805955005756,
                    67.40459770114943,
                    323.9805955005756,
                    67.40459770114943,
                    322.9803418469883,
                    67.40459770114943,
                    322.9803418469883,
                    67.40459770114943,
                    321.9800881934011,
                    67.40459770114943,
                    321.9800881934011,
                    67.40459770114943,
                    320.97983453981385,
                    67.40459770114943,
                    318.9793272326393,
                    67.40459770114943,
                    318.9793272326393,
                    67.40459770114943,
                    318.9793272326393,
                    67.40459770114943,
                    317.9790735790521,
                    67.40459770114943,
                    317.9790735790521,
                    66.40229885057471,
                    316.97881992546485,
                    66.40229885057471,
                    316.97881992546485,
                    66.40229885057471,
                    315.9785662718776,
                    65.4,
                    315.9785662718776,
                    65.4,
                    314.9783126182904,
                    65.4,
                    313.9780589647031,
                    65.4,
                    313.9780589647031,
                    64.39770114942529,
                    313.9780589647031,
                    64.39770114942529,
                    312.97780531111584,
                    64.39770114942529,
                    311.97755165752864,
                    64.39770114942529,
                    311.97755165752864,
                    64.39770114942529,
                    311.97755165752864,
                    63.39540229885058,
                    310.97729800394137,
                    63.39540229885058,
                    309.9770443503541,
                    62.393103448275866,
                    309.9770443503541,
                    62.393103448275866,
                    308.9767906967669,
                    61.390804597701155,
                    308.9767906967669,
                    61.390804597701155,
                    307.97653704317963,
                    61.390804597701155,
                    307.97653704317963,
                    61.390804597701155,
                    306.97628338959237,
                    61.390804597701155,
                    306.97628338959237,
                    60.38850574712644,
                    305.97602973600516,
                    60.38850574712644,
                    304.9757760824179,
                    60.38850574712644,
                    304.9757760824179,
                    60.38850574712644,
                    303.97552242883063,
                    60.38850574712644,
                    303.97552242883063,
                    60.38850574712644,
                    302.9752687752434,
                    60.38850574712644,
                    302.9752687752434
                ]
            },
            {
                "tool": "eraser",
                "points": [
                    51.367816091954026,
                    297.97400050730715,
                    51.367816091954026,
                    297.97400050730715,
                    52.37011494252874,
                    297.97400050730715,
                    52.37011494252874,
                    297.97400050730715,
                    52.37011494252874,
                    298.9742541608944,
                    52.37011494252874,
                    298.9742541608944,
                    52.37011494252874,
                    299.9745078144816,
                    52.37011494252874,
                    300.9747614680689,
                    53.37241379310345,
                    300.9747614680689,
                    53.37241379310345,
                    300.9747614680689,
                    53.37241379310345,
                    301.97501512165616,
                    54.374712643678166,
                    301.97501512165616,
                    54.374712643678166,
                    302.9752687752434,
                    55.377011494252876,
                    302.9752687752434,
                    55.377011494252876,
                    302.9752687752434,
                    55.377011494252876,
                    303.97552242883063,
                    56.37931034482759,
                    304.9757760824179,
                    56.37931034482759,
                    304.9757760824179,
                    58.383908045977016,
                    305.97602973600516,
                    58.383908045977016,
                    306.97628338959237,
                    58.383908045977016,
                    306.97628338959237,
                    58.383908045977016,
                    307.97653704317963,
                    59.38620689655173,
                    307.97653704317963,
                    59.38620689655173,
                    308.9767906967669,
                    60.38850574712644,
                    308.9767906967669,
                    60.38850574712644,
                    309.9770443503541,
                    61.390804597701155,
                    310.97729800394137,
                    62.393103448275866,
                    310.97729800394137,
                    63.39540229885058,
                    311.97755165752864,
                    63.39540229885058,
                    311.97755165752864,
                    64.39770114942529,
                    312.97780531111584,
                    64.39770114942529,
                    313.9780589647031,
                    65.4,
                    313.9780589647031,
                    65.4,
                    313.9780589647031,
                    68.40689655172415,
                    316.97881992546485,
                    68.40689655172415,
                    317.9790735790521,
                    69.40919540229885,
                    317.9790735790521,
                    69.40919540229885,
                    317.9790735790521,
                    69.40919540229885,
                    318.9793272326393,
                    70.41149425287357,
                    319.9795808862266,
                    70.41149425287357,
                    319.9795808862266,
                    70.41149425287357,
                    320.97983453981385,
                    70.41149425287357,
                    320.97983453981385,
                    71.41379310344828,
                    321.9800881934011,
                    71.41379310344828,
                    321.9800881934011,
                    71.41379310344828,
                    321.9800881934011,
                    72.41609195402299,
                    321.9800881934011,
                    72.41609195402299,
                    322.9803418469883,
                    73.4183908045977,
                    322.9803418469883,
                    73.4183908045977,
                    321.9800881934011,
                    73.4183908045977,
                    321.9800881934011,
                    73.4183908045977,
                    319.9795808862266,
                    73.4183908045977,
                    317.9790735790521,
                    73.4183908045977,
                    315.9785662718776,
                    73.4183908045977,
                    313.9780589647031,
                    73.4183908045977,
                    310.97729800394137,
                    73.4183908045977,
                    304.9757760824179,
                    73.4183908045977,
                    300.9747614680689,
                    73.4183908045977,
                    296.9737468537199,
                    72.41609195402299,
                    294.9732395465454,
                    72.41609195402299,
                    294.9732395465454,
                    71.41379310344828,
                    293.97298589295815,
                    70.41149425287357,
                    292.97273223937094,
                    70.41149425287357,
                    292.97273223937094,
                    69.40919540229885,
                    292.97273223937094,
                    66.40229885057471,
                    296.9737468537199,
                    64.39770114942529,
                    300.9747614680689,
                    64.39770114942529,
                    302.9752687752434,
                    63.39540229885058,
                    308.9767906967669,
                    63.39540229885058,
                    313.9780589647031,
                    62.393103448275866,
                    319.9795808862266,
                    62.393103448275866,
                    325.98110280775006,
                    62.393103448275866,
                    329.98211742209907,
                    62.393103448275866,
                    333.98313203644807,
                    62.393103448275866,
                    338.9844003043843,
                    62.393103448275866,
                    342.9854149187333,
                    62.393103448275866,
                    346.9864295330823,
                    62.393103448275866,
                    348.98693684025676,
                    62.393103448275866,
                    349.987190493844,
                    62.393103448275866,
                    350.98744414743123,
                    62.393103448275866,
                    352.98795145460576,
                    62.393103448275866,
                    353.988205108193,
                    62.393103448275866,
                    353.988205108193,
                    62.393103448275866,
                    354.98845876178024,
                    62.393103448275866,
                    353.988205108193,
                    63.39540229885058,
                    352.98795145460576,
                    64.39770114942529,
                    348.98693684025676,
                    67.40459770114943,
                    342.9854149187333,
                    68.40689655172415,
                    338.9844003043843,
                    69.40919540229885,
                    334.9833856900353,
                    70.41149425287357,
                    329.98211742209907,
                    71.41379310344828,
                    321.9800881934011,
                    72.41609195402299,
                    315.9785662718776,
                    73.4183908045977,
                    309.9770443503541,
                    73.4183908045977,
                    308.9767906967669,
                    74.42068965517241,
                    308.9767906967669,
                    74.42068965517241,
                    307.97653704317963,
                    74.42068965517241,
                    307.97653704317963,
                    74.42068965517241,
                    307.97653704317963,
                    74.42068965517241,
                    308.9767906967669,
                    74.42068965517241,
                    309.9770443503541,
                    74.42068965517241,
                    313.9780589647031,
                    73.4183908045977,
                    317.9790735790521,
                    72.41609195402299,
                    323.9805955005756,
                    71.41379310344828,
                    327.9816101149246,
                    71.41379310344828,
                    330.98237107568633,
                    70.41149425287357,
                    334.9833856900353,
                    69.40919540229885,
                    338.9844003043843,
                    68.40689655172415,
                    342.9854149187333,
                    68.40689655172415,
                    343.98566857232055,
                    68.40689655172415,
                    343.98566857232055,
                    68.40689655172415,
                    343.98566857232055,
                    68.40689655172415,
                    344.98592222590776,
                    66.40229885057471,
                    343.98566857232055,
                    66.40229885057471,
                    341.985161265146,
                    66.40229885057471,
                    338.9844003043843,
                    66.40229885057471,
                    336.9838929972098,
                    65.4,
                    334.9833856900353,
                    65.4,
                    330.98237107568633,
                    64.39770114942529,
                    329.98211742209907,
                    63.39540229885058,
                    326.9813564613373,
                    61.390804597701155,
                    322.9803418469883,
                    61.390804597701155,
                    320.97983453981385,
                    60.38850574712644,
                    318.9793272326393,
                    59.38620689655173,
                    316.97881992546485,
                    59.38620689655173,
                    316.97881992546485,
                    59.38620689655173,
                    315.9785662718776,
                    59.38620689655173,
                    315.9785662718776,
                    58.383908045977016,
                    316.97881992546485,
                    56.37931034482759,
                    321.9800881934011,
                    55.377011494252876,
                    323.9805955005756,
                    54.374712643678166,
                    327.9816101149246,
                    54.374712643678166,
                    328.9818637685118,
                    54.374712643678166,
                    329.98211742209907,
                    54.374712643678166,
                    329.98211742209907,
                    54.374712643678166,
                    331.98262472927354,
                    54.374712643678166,
                    332.9828783828608,
                    54.374712643678166,
                    332.9828783828608,
                    54.374712643678166,
                    332.9828783828608,
                    54.374712643678166,
                    330.98237107568633,
                    54.374712643678166,
                    329.98211742209907,
                    54.374712643678166,
                    327.9816101149246,
                    53.37241379310345,
                    323.9805955005756,
                    53.37241379310345,
                    320.97983453981385,
                    53.37241379310345,
                    316.97881992546485,
                    53.37241379310345,
                    315.9785662718776,
                    53.37241379310345,
                    311.97755165752864,
                    53.37241379310345,
                    309.9770443503541,
                    52.37011494252874,
                    308.9767906967669,
                    52.37011494252874,
                    308.9767906967669,
                    52.37011494252874,
                    307.97653704317963,
                    52.37011494252874,
                    307.97653704317963,
                    50.365517241379315,
                    308.9767906967669,
                    48.36091954022989,
                    312.97780531111584,
                    47.358620689655176,
                    316.97881992546485,
                    40.342528735632186,
                    323.9805955005756,
                    38.33793103448276,
                    326.9813564613373,
                    34.32873563218391,
                    331.98262472927354,
                    31.32183908045977,
                    337.984146650797,
                    30.31954022988506,
                    339.98465395797155,
                    30.31954022988506,
                    339.98465395797155,
                    29.317241379310346,
                    340.98490761155875,
                    29.317241379310346,
                    339.98465395797155,
                    29.317241379310346,
                    335.98363934362254,
                    29.317241379310346,
                    331.98262472927354,
                    29.317241379310346,
                    326.9813564613373,
                    29.317241379310346,
                    324.98084915416285,
                    29.317241379310346,
                    322.9803418469883,
                    29.317241379310346,
                    318.9793272326393,
                    29.317241379310346,
                    312.97780531111584,
                    29.317241379310346,
                    307.97653704317963,
                    29.317241379310346,
                    301.97501512165616,
                    29.317241379310346,
                    295.9734932001327,
                    29.317241379310346,
                    289.9719712786092,
                    29.317241379310346,
                    289.9719712786092,
                    30.31954022988506,
                    288.97171762502194,
                    30.31954022988506,
                    288.97171762502194,
                    30.31954022988506,
                    286.97121031784746,
                    30.31954022988506,
                    285.9709566642602,
                    30.31954022988506,
                    285.9709566642602,
                    31.32183908045977,
                    286.97121031784746,
                    31.32183908045977,
                    288.97171762502194,
                    31.32183908045977,
                    291.9724785857837,
                    31.32183908045977,
                    293.97298589295815,
                    31.32183908045977,
                    299.9745078144816,
                    31.32183908045977,
                    303.97552242883063,
                    30.31954022988506,
                    307.97653704317963,
                    30.31954022988506,
                    310.97729800394137,
                    29.317241379310346,
                    311.97755165752864,
                    29.317241379310346,
                    313.9780589647031,
                    29.317241379310346,
                    313.9780589647031,
                    29.317241379310346,
                    314.9783126182904,
                    29.317241379310346,
                    313.9780589647031,
                    30.31954022988506,
                    312.97780531111584,
                    34.32873563218391,
                    307.97653704317963,
                    35.331034482758625,
                    305.97602973600516,
                    39.340229885057475,
                    301.97501512165616,
                    43.349425287356325,
                    296.9737468537199,
                    44.351724137931036,
                    294.9732395465454,
                    45.35402298850575,
                    293.97298589295815,
                    46.356321839080465,
                    292.97273223937094,
                    46.356321839080465,
                    292.97273223937094,
                    46.356321839080465,
                    292.97273223937094,
                    46.356321839080465,
                    296.9737468537199,
                    45.35402298850575,
                    298.9742541608944,
                    43.349425287356325,
                    302.9752687752434,
                    43.349425287356325,
                    304.9757760824179,
                    42.34712643678161,
                    307.97653704317963,
                    42.34712643678161,
                    308.9767906967669,
                    41.3448275862069,
                    309.9770443503541,
                    41.3448275862069,
                    309.9770443503541,
                    40.342528735632186,
                    310.97729800394137,
                    40.342528735632186,
                    311.97755165752864,
                    39.340229885057475,
                    310.97729800394137,
                    39.340229885057475,
                    309.9770443503541,
                    38.33793103448276,
                    309.9770443503541,
                    38.33793103448276,
                    307.97653704317963,
                    37.33563218390805,
                    305.97602973600516,
                    36.333333333333336,
                    301.97501512165616,
                    35.331034482758625,
                    297.97400050730715,
                    33.326436781609196,
                    292.97273223937094,
                    32.324137931034485,
                    288.97171762502194,
                    30.31954022988506,
                    284.97070301067293,
                    28.314942528735635,
                    280.96968839632393,
                    28.314942528735635,
                    280.96968839632393,
                    27.31264367816092,
                    279.9694347427367,
                    27.31264367816092,
                    279.9694347427367,
                    26.310344827586206,
                    279.9694347427367,
                    26.310344827586206,
                    279.9694347427367,
                    25.308045977011496,
                    280.96968839632393,
                    24.30574712643678,
                    282.97019570349846,
                    22.301149425287356,
                    286.97121031784746,
                    21.298850574712645,
                    288.97171762502194,
                    20.29655172413793,
                    294.9732395465454,
                    19.29425287356322,
                    295.9734932001327,
                    19.29425287356322,
                    297.97400050730715,
                    18.291954022988506,
                    299.9745078144816,
                    16.28735632183908,
                    300.9747614680689,
                    16.28735632183908,
                    301.97501512165616,
                    16.28735632183908,
                    301.97501512165616,
                    16.28735632183908,
                    302.9752687752434,
                    16.28735632183908,
                    302.9752687752434,
                    16.28735632183908,
                    296.9737468537199,
                    16.28735632183908,
                    291.9724785857837,
                    16.28735632183908,
                    287.9714639714347,
                    16.28735632183908,
                    285.9709566642602,
                    16.28735632183908,
                    281.9699420499112,
                    16.28735632183908,
                    275.9684201283877,
                    16.28735632183908,
                    274.96816647480046,
                    17.289655172413795,
                    270.9671518604515,
                    17.289655172413795,
                    266.9661372461025,
                    18.291954022988506,
                    262.9651226317535,
                    19.29425287356322,
                    258.9641080174045,
                    19.29425287356322,
                    258.9641080174045,
                    19.29425287356322,
                    257.9638543638173,
                    19.29425287356322,
                    257.9638543638173,
                    19.29425287356322,
                    263.96537628534077,
                    18.291954022988506,
                    266.9661372461025,
                    15.285057471264368,
                    271.9674055140387,
                    14.282758620689656,
                    273.96791282121325,
                    11.275862068965518,
                    279.9694347427367,
                    11.275862068965518,
                    280.96968839632393,
                    10.273563218390805,
                    281.9699420499112,
                    10.273563218390805,
                    283.9704493570857,
                    9.271264367816093,
                    283.9704493570857,
                    8.26896551724138,
                    284.97070301067293,
                    8.26896551724138,
                    285.9709566642602,
                    8.26896551724138,
                    285.9709566642602,
                    8.26896551724138,
                    283.9704493570857,
                    8.26896551724138,
                    282.97019570349846,
                    8.26896551724138,
                    281.9699420499112,
                    8.26896551724138,
                    280.96968839632393,
                    8.26896551724138,
                    274.96816647480046,
                    8.26896551724138,
                    272.967659167626,
                    8.26896551724138,
                    270.9671518604515,
                    8.26896551724138,
                    270.9671518604515,
                    8.26896551724138,
                    269.96689820686424,
                    8.26896551724138,
                    268.966644553277,
                    8.26896551724138,
                    267.96639089968977,
                    8.26896551724138,
                    267.96639089968977,
                    8.26896551724138,
                    266.9661372461025,
                    8.26896551724138,
                    265.96588359251524,
                    8.26896551724138,
                    265.96588359251524,
                    8.26896551724138,
                    265.96588359251524,
                    8.26896551724138,
                    265.96588359251524,
                    8.26896551724138,
                    264.96562993892803,
                    8.26896551724138,
                    264.96562993892803,
                    9.271264367816093,
                    263.96537628534077,
                    10.273563218390805,
                    264.96562993892803,
                    11.275862068965518,
                    264.96562993892803,
                    12.27816091954023,
                    265.96588359251524,
                    14.282758620689656,
                    266.9661372461025,
                    15.285057471264368,
                    266.9661372461025,
                    19.29425287356322,
                    270.9671518604515,
                    21.298850574712645,
                    271.9674055140387,
                    22.301149425287356,
                    271.9674055140387,
                    26.310344827586206,
                    273.96791282121325,
                    26.310344827586206,
                    274.96816647480046,
                    28.314942528735635,
                    275.9684201283877,
                    28.314942528735635,
                    275.9684201283877,
                    29.317241379310346,
                    276.968673781975,
                    29.317241379310346,
                    276.968673781975,
                    30.31954022988506,
                    276.968673781975,
                    30.31954022988506,
                    276.968673781975,
                    31.32183908045977,
                    277.9689274355622,
                    31.32183908045977,
                    278.96918108914946,
                    32.324137931034485,
                    279.9694347427367,
                    33.326436781609196,
                    281.9699420499112,
                    33.326436781609196,
                    281.9699420499112,
                    33.326436781609196,
                    282.97019570349846,
                    34.32873563218391,
                    282.97019570349846,
                    34.32873563218391,
                    282.97019570349846,
                    34.32873563218391,
                    283.9704493570857,
                    34.32873563218391,
                    284.97070301067293,
                    34.32873563218391,
                    284.97070301067293,
                    34.32873563218391,
                    285.9709566642602,
                    35.331034482758625,
                    289.9719712786092,
                    35.331034482758625,
                    289.9719712786092,
                    36.333333333333336,
                    290.9722249321964,
                    37.33563218390805,
                    292.97273223937094,
                    38.33793103448276,
                    292.97273223937094,
                    38.33793103448276,
                    293.97298589295815,
                    38.33793103448276,
                    294.9732395465454,
                    38.33793103448276,
                    294.9732395465454,
                    38.33793103448276,
                    295.9734932001327,
                    38.33793103448276,
                    296.9737468537199,
                    38.33793103448276,
                    297.97400050730715,
                    39.340229885057475,
                    298.9742541608944,
                    39.340229885057475,
                    298.9742541608944,
                    39.340229885057475,
                    299.9745078144816,
                    39.340229885057475,
                    299.9745078144816,
                    40.342528735632186,
                    299.9745078144816,
                    40.342528735632186,
                    300.9747614680689,
                    40.342528735632186,
                    301.97501512165616,
                    41.3448275862069,
                    302.9752687752434,
                    41.3448275862069,
                    302.9752687752434,
                    41.3448275862069,
                    303.97552242883063,
                    42.34712643678161,
                    305.97602973600516,
                    43.349425287356325,
                    309.9770443503541,
                    44.351724137931036,
                    309.9770443503541,
                    44.351724137931036,
                    310.97729800394137,
                    44.351724137931036,
                    311.97755165752864,
                    45.35402298850575,
                    313.9780589647031,
                    46.356321839080465,
                    313.9780589647031,
                    47.358620689655176,
                    315.9785662718776,
                    50.365517241379315,
                    318.9793272326393,
                    54.374712643678166,
                    321.9800881934011,
                    57.3816091954023,
                    324.98084915416285,
                    64.39770114942529,
                    331.98262472927354,
                    71.41379310344828,
                    336.9838929972098,
                    77.42758620689655,
                    342.9854149187333,
                    84.44367816091955,
                    347.9866831866695,
                    84.44367816091955,
                    347.9866831866695,
                    85.44597701149425,
                    348.98693684025676,
                    85.44597701149425,
                    349.987190493844,
                    85.44597701149425,
                    349.987190493844,
                    85.44597701149425,
                    350.98744414743123,
                    85.44597701149425,
                    350.98744414743123,
                    85.44597701149425,
                    351.9876978010185,
                    84.44367816091955,
                    351.9876978010185,
                    83.44137931034483,
                    351.9876978010185,
                    82.43908045977012,
                    351.9876978010185,
                    80.43448275862069,
                    351.9876978010185,
                    78.42988505747127,
                    351.9876978010185,
                    74.42068965517241,
                    351.9876978010185,
                    69.40919540229885,
                    349.987190493844,
                    66.40229885057471,
                    347.9866831866695,
                    65.4,
                    346.9864295330823,
                    64.39770114942529,
                    345.986175879495,
                    63.39540229885058,
                    344.98592222590776,
                    63.39540229885058,
                    344.98592222590776,
                    62.393103448275866,
                    344.98592222590776,
                    61.390804597701155,
                    342.9854149187333,
                    60.38850574712644,
                    342.9854149187333,
                    58.383908045977016,
                    341.985161265146,
                    58.383908045977016,
                    340.98490761155875,
                    57.3816091954023,
                    340.98490761155875,
                    57.3816091954023,
                    340.98490761155875,
                    56.37931034482759,
                    339.98465395797155,
                    54.374712643678166,
                    338.9844003043843,
                    54.374712643678166,
                    338.9844003043843,
                    53.37241379310345,
                    338.9844003043843,
                    53.37241379310345,
                    338.9844003043843,
                    53.37241379310345,
                    338.9844003043843,
                    52.37011494252874,
                    338.9844003043843,
                    51.367816091954026,
                    338.9844003043843,
                    50.365517241379315,
                    338.9844003043843,
                    46.356321839080465,
                    338.9844003043843,
                    45.35402298850575,
                    338.9844003043843,
                    42.34712643678161,
                    338.9844003043843,
                    40.342528735632186,
                    338.9844003043843,
                    39.340229885057475,
                    338.9844003043843,
                    38.33793103448276,
                    339.98465395797155,
                    37.33563218390805,
                    339.98465395797155,
                    36.333333333333336,
                    340.98490761155875,
                    35.331034482758625,
                    340.98490761155875,
                    35.331034482758625,
                    340.98490761155875,
                    33.326436781609196,
                    341.985161265146,
                    31.32183908045977,
                    342.9854149187333,
                    31.32183908045977,
                    342.9854149187333,
                    31.32183908045977,
                    342.9854149187333,
                    30.31954022988506,
                    343.98566857232055,
                    29.317241379310346,
                    343.98566857232055,
                    29.317241379310346,
                    344.98592222590776,
                    28.314942528735635,
                    344.98592222590776,
                    27.31264367816092,
                    345.986175879495,
                    27.31264367816092,
                    345.986175879495,
                    26.310344827586206,
                    345.986175879495,
                    26.310344827586206,
                    345.986175879495
                ]
            },
            {
                "tool": "pen",
                "points": [
                    29.317241379310346,
                    251.96233244229379,
                    29.317241379310346,
                    251.96233244229379,
                    29.317241379310346,
                    251.96233244229379,
                    29.317241379310346,
                    252.96258609588105,
                    29.317241379310346,
                    252.96258609588105,
                    29.317241379310346,
                    253.9628397494683,
                    29.317241379310346,
                    253.9628397494683,
                    29.317241379310346,
                    253.9628397494683,
                    29.317241379310346,
                    254.96309340305552,
                    29.317241379310346,
                    255.9633470566428,
                    29.317241379310346,
                    255.9633470566428,
                    29.317241379310346,
                    256.96360071023,
                    29.317241379310346,
                    257.9638543638173,
                    29.317241379310346,
                    257.9638543638173,
                    29.317241379310346,
                    257.9638543638173,
                    29.317241379310346,
                    258.9641080174045,
                    29.317241379310346,
                    259.96436167099176,
                    29.317241379310346,
                    259.96436167099176,
                    29.317241379310346,
                    260.96461532457903,
                    29.317241379310346,
                    261.9648689781663,
                    29.317241379310346,
                    261.9648689781663,
                    29.317241379310346,
                    261.9648689781663,
                    29.317241379310346,
                    262.9651226317535,
                    29.317241379310346,
                    262.9651226317535,
                    30.31954022988506,
                    263.96537628534077,
                    30.31954022988506,
                    263.96537628534077,
                    30.31954022988506,
                    264.96562993892803,
                    31.32183908045977,
                    264.96562993892803,
                    31.32183908045977,
                    264.96562993892803,
                    31.32183908045977,
                    264.96562993892803,
                    31.32183908045977,
                    265.96588359251524,
                    31.32183908045977,
                    265.96588359251524,
                    31.32183908045977,
                    266.9661372461025,
                    31.32183908045977,
                    266.9661372461025,
                    31.32183908045977,
                    267.96639089968977,
                    31.32183908045977,
                    268.966644553277,
                    32.324137931034485,
                    268.966644553277,
                    32.324137931034485,
                    268.966644553277,
                    32.324137931034485,
                    269.96689820686424,
                    32.324137931034485,
                    269.96689820686424,
                    32.324137931034485,
                    270.9671518604515,
                    32.324137931034485,
                    270.9671518604515,
                    33.326436781609196,
                    271.9674055140387,
                    33.326436781609196,
                    271.9674055140387,
                    33.326436781609196,
                    272.967659167626,
                    33.326436781609196,
                    272.967659167626,
                    33.326436781609196,
                    272.967659167626,
                    33.326436781609196,
                    273.96791282121325,
                    33.326436781609196,
                    274.96816647480046,
                    33.326436781609196,
                    274.96816647480046,
                    33.326436781609196,
                    275.9684201283877,
                    33.326436781609196,
                    275.9684201283877,
                    33.326436781609196,
                    276.968673781975,
                    33.326436781609196,
                    276.968673781975,
                    33.326436781609196,
                    277.9689274355622,
                    34.32873563218391,
                    278.96918108914946,
                    34.32873563218391,
                    279.9694347427367,
                    34.32873563218391,
                    280.96968839632393,
                    34.32873563218391,
                    280.96968839632393,
                    34.32873563218391,
                    280.96968839632393,
                    34.32873563218391,
                    281.9699420499112,
                    34.32873563218391,
                    282.97019570349846,
                    34.32873563218391,
                    282.97019570349846,
                    35.331034482758625,
                    283.9704493570857,
                    35.331034482758625,
                    283.9704493570857,
                    35.331034482758625,
                    284.97070301067293,
                    35.331034482758625,
                    284.97070301067293,
                    35.331034482758625,
                    285.9709566642602,
                    35.331034482758625,
                    285.9709566642602,
                    35.331034482758625,
                    286.97121031784746,
                    36.333333333333336,
                    286.97121031784746,
                    36.333333333333336,
                    287.9714639714347,
                    36.333333333333336,
                    288.97171762502194,
                    36.333333333333336,
                    288.97171762502194,
                    36.333333333333336,
                    289.9719712786092,
                    36.333333333333336,
                    289.9719712786092,
                    36.333333333333336,
                    290.9722249321964,
                    37.33563218390805,
                    290.9722249321964,
                    37.33563218390805,
                    291.9724785857837,
                    37.33563218390805,
                    291.9724785857837,
                    37.33563218390805,
                    292.97273223937094,
                    37.33563218390805,
                    292.97273223937094,
                    37.33563218390805,
                    293.97298589295815,
                    38.33793103448276,
                    293.97298589295815,
                    38.33793103448276,
                    294.9732395465454,
                    38.33793103448276,
                    294.9732395465454,
                    38.33793103448276,
                    295.9734932001327,
                    39.340229885057475,
                    295.9734932001327,
                    39.340229885057475,
                    296.9737468537199,
                    39.340229885057475,
                    296.9737468537199,
                    39.340229885057475,
                    297.97400050730715,
                    40.342528735632186,
                    297.97400050730715,
                    40.342528735632186,
                    297.97400050730715,
                    40.342528735632186,
                    298.9742541608944,
                    40.342528735632186,
                    298.9742541608944,
                    41.3448275862069,
                    299.9745078144816,
                    41.3448275862069,
                    299.9745078144816,
                    41.3448275862069,
                    300.9747614680689,
                    43.349425287356325,
                    301.97501512165616,
                    43.349425287356325,
                    302.9752687752434,
                    44.351724137931036,
                    302.9752687752434,
                    44.351724137931036,
                    304.9757760824179,
                    44.351724137931036,
                    304.9757760824179,
                    44.351724137931036,
                    305.97602973600516,
                    44.351724137931036,
                    305.97602973600516,
                    45.35402298850575,
                    306.97628338959237,
                    45.35402298850575,
                    306.97628338959237,
                    45.35402298850575,
                    306.97628338959237,
                    46.356321839080465,
                    307.97653704317963,
                    46.356321839080465,
                    308.9767906967669,
                    47.358620689655176,
                    309.9770443503541,
                    47.358620689655176,
                    309.9770443503541,
                    48.36091954022989,
                    309.9770443503541,
                    48.36091954022989,
                    310.97729800394137,
                    49.3632183908046,
                    312.97780531111584,
                    50.365517241379315,
                    312.97780531111584,
                    50.365517241379315,
                    313.9780589647031,
                    50.365517241379315,
                    313.9780589647031,
                    51.367816091954026,
                    314.9783126182904,
                    52.37011494252874,
                    314.9783126182904,
                    52.37011494252874,
                    314.9783126182904,
                    52.37011494252874,
                    314.9783126182904,
                    53.37241379310345,
                    315.9785662718776,
                    54.374712643678166,
                    315.9785662718776,
                    54.374712643678166,
                    316.97881992546485,
                    55.377011494252876,
                    316.97881992546485,
                    57.3816091954023,
                    316.97881992546485,
                    57.3816091954023,
                    316.97881992546485,
                    57.3816091954023,
                    317.9790735790521,
                    58.383908045977016,
                    317.9790735790521,
                    59.38620689655173,
                    317.9790735790521,
                    59.38620689655173,
                    317.9790735790521,
                    60.38850574712644,
                    317.9790735790521,
                    60.38850574712644,
                    317.9790735790521,
                    61.390804597701155,
                    318.9793272326393,
                    61.390804597701155,
                    318.9793272326393,
                    62.393103448275866,
                    318.9793272326393,
                    62.393103448275866,
                    318.9793272326393,
                    63.39540229885058,
                    318.9793272326393,
                    64.39770114942529,
                    319.9795808862266,
                    64.39770114942529,
                    319.9795808862266,
                    65.4,
                    319.9795808862266,
                    65.4,
                    319.9795808862266,
                    66.40229885057471,
                    319.9795808862266,
                    66.40229885057471,
                    319.9795808862266,
                    67.40459770114943,
                    319.9795808862266,
                    68.40689655172415,
                    319.9795808862266,
                    69.40919540229885,
                    319.9795808862266,
                    69.40919540229885,
                    319.9795808862266,
                    70.41149425287357,
                    319.9795808862266,
                    70.41149425287357,
                    319.9795808862266,
                    71.41379310344828,
                    319.9795808862266,
                    72.41609195402299,
                    319.9795808862266,
                    72.41609195402299,
                    319.9795808862266,
                    73.4183908045977,
                    319.9795808862266,
                    73.4183908045977,
                    320.97983453981385,
                    74.42068965517241,
                    320.97983453981385,
                    75.42298850574713,
                    320.97983453981385,
                    75.42298850574713,
                    320.97983453981385,
                    76.42528735632185,
                    320.97983453981385,
                    76.42528735632185,
                    320.97983453981385,
                    77.42758620689655,
                    320.97983453981385,
                    77.42758620689655,
                    320.97983453981385,
                    78.42988505747127,
                    320.97983453981385,
                    79.43218390804599,
                    320.97983453981385,
                    80.43448275862069,
                    320.97983453981385,
                    81.4367816091954,
                    320.97983453981385,
                    81.4367816091954,
                    320.97983453981385,
                    82.43908045977012,
                    320.97983453981385,
                    83.44137931034483,
                    320.97983453981385,
                    83.44137931034483,
                    320.97983453981385,
                    84.44367816091955,
                    320.97983453981385,
                    85.44597701149425,
                    320.97983453981385,
                    86.44827586206897,
                    320.97983453981385,
                    86.44827586206897,
                    320.97983453981385,
                    87.45057471264369,
                    320.97983453981385,
                    87.45057471264369,
                    320.97983453981385,
                    88.45287356321839,
                    320.97983453981385,
                    88.45287356321839,
                    320.97983453981385,
                    88.45287356321839,
                    320.97983453981385,
                    89.45517241379311,
                    320.97983453981385,
                    90.45747126436783,
                    320.97983453981385,
                    90.45747126436783,
                    320.97983453981385,
                    90.45747126436783,
                    320.97983453981385,
                    91.45977011494253,
                    320.97983453981385,
                    91.45977011494253,
                    320.97983453981385,
                    91.45977011494253,
                    319.9795808862266,
                    91.45977011494253,
                    318.9793272326393,
                    91.45977011494253,
                    317.9790735790521,
                    91.45977011494253,
                    316.97881992546485,
                    91.45977011494253,
                    316.97881992546485,
                    91.45977011494253,
                    315.9785662718776,
                    91.45977011494253,
                    313.9780589647031,
                    91.45977011494253,
                    313.9780589647031,
                    91.45977011494253,
                    313.9780589647031,
                    91.45977011494253,
                    312.97780531111584,
                    91.45977011494253,
                    311.97755165752864,
                    91.45977011494253,
                    311.97755165752864,
                    90.45747126436783,
                    309.9770443503541,
                    90.45747126436783,
                    309.9770443503541,
                    89.45517241379311,
                    308.9767906967669,
                    89.45517241379311,
                    307.97653704317963,
                    89.45517241379311,
                    307.97653704317963,
                    89.45517241379311,
                    307.97653704317963,
                    89.45517241379311,
                    306.97628338959237,
                    89.45517241379311,
                    306.97628338959237,
                    89.45517241379311,
                    305.97602973600516,
                    89.45517241379311,
                    305.97602973600516,
                    88.45287356321839,
                    304.9757760824179,
                    88.45287356321839,
                    304.9757760824179,
                    88.45287356321839,
                    303.97552242883063,
                    88.45287356321839,
                    303.97552242883063,
                    88.45287356321839,
                    303.97552242883063,
                    87.45057471264369,
                    302.9752687752434,
                    87.45057471264369,
                    301.97501512165616,
                    87.45057471264369,
                    301.97501512165616,
                    86.44827586206897,
                    301.97501512165616,
                    86.44827586206897,
                    300.9747614680689,
                    85.44597701149425,
                    300.9747614680689,
                    85.44597701149425,
                    300.9747614680689,
                    85.44597701149425,
                    299.9745078144816,
                    84.44367816091955,
                    299.9745078144816,
                    84.44367816091955,
                    298.9742541608944,
                    84.44367816091955,
                    298.9742541608944,
                    83.44137931034483,
                    298.9742541608944,
                    83.44137931034483,
                    297.97400050730715,
                    83.44137931034483,
                    296.9737468537199,
                    83.44137931034483,
                    296.9737468537199,
                    82.43908045977012,
                    296.9737468537199,
                    82.43908045977012,
                    295.9734932001327,
                    81.4367816091954,
                    295.9734932001327,
                    81.4367816091954,
                    294.9732395465454,
                    81.4367816091954,
                    294.9732395465454,
                    81.4367816091954,
                    293.97298589295815,
                    80.43448275862069,
                    292.97273223937094,
                    80.43448275862069,
                    292.97273223937094,
                    79.43218390804599,
                    290.9722249321964,
                    79.43218390804599,
                    290.9722249321964,
                    79.43218390804599,
                    289.9719712786092,
                    78.42988505747127,
                    288.97171762502194,
                    77.42758620689655,
                    288.97171762502194,
                    77.42758620689655,
                    288.97171762502194,
                    76.42528735632185,
                    288.97171762502194,
                    77.42758620689655,
                    288.97171762502194,
                    78.42988505747127,
                    288.97171762502194,
                    78.42988505747127,
                    288.97171762502194,
                    79.43218390804599,
                    288.97171762502194,
                    78.42988505747127,
                    288.97171762502194,
                    77.42758620689655,
                    288.97171762502194,
                    77.42758620689655,
                    288.97171762502194,
                    76.42528735632185,
                    288.97171762502194,
                    76.42528735632185,
                    287.9714639714347,
                    76.42528735632185,
                    287.9714639714347,
                    75.42298850574713,
                    286.97121031784746,
                    74.42068965517241,
                    286.97121031784746
                ]
            },
            {
                "tool": "pen",
                "points": [
                    95.46896551724139,
                    321.9800881934011,
                    95.46896551724139,
                    321.9800881934011,
                    95.46896551724139,
                    322.9803418469883,
                    95.46896551724139,
                    322.9803418469883,
                    94.46666666666667,
                    323.9805955005756,
                    94.46666666666667,
                    323.9805955005756,
                    93.46436781609196,
                    323.9805955005756,
                    93.46436781609196,
                    323.9805955005756,
                    92.46206896551725,
                    323.9805955005756,
                    92.46206896551725,
                    324.98084915416285,
                    91.45977011494253,
                    324.98084915416285,
                    90.45747126436783,
                    325.98110280775006,
                    90.45747126436783,
                    325.98110280775006,
                    90.45747126436783,
                    326.9813564613373,
                    89.45517241379311,
                    326.9813564613373,
                    89.45517241379311,
                    327.9816101149246,
                    89.45517241379311,
                    327.9816101149246,
                    88.45287356321839,
                    327.9816101149246,
                    88.45287356321839,
                    328.9818637685118,
                    87.45057471264369,
                    328.9818637685118,
                    87.45057471264369,
                    328.9818637685118,
                    86.44827586206897,
                    328.9818637685118,
                    86.44827586206897,
                    329.98211742209907,
                    86.44827586206897,
                    329.98211742209907,
                    85.44597701149425,
                    329.98211742209907,
                    84.44367816091955,
                    330.98237107568633,
                    84.44367816091955,
                    330.98237107568633,
                    82.43908045977012,
                    331.98262472927354,
                    82.43908045977012,
                    331.98262472927354,
                    81.4367816091954,
                    331.98262472927354,
                    81.4367816091954,
                    331.98262472927354,
                    80.43448275862069,
                    331.98262472927354,
                    80.43448275862069,
                    331.98262472927354,
                    80.43448275862069,
                    332.9828783828608,
                    79.43218390804599,
                    332.9828783828608,
                    78.42988505747127,
                    332.9828783828608,
                    78.42988505747127,
                    332.9828783828608,
                    78.42988505747127,
                    333.98313203644807,
                    77.42758620689655,
                    333.98313203644807,
                    77.42758620689655,
                    333.98313203644807,
                    76.42528735632185,
                    333.98313203644807,
                    76.42528735632185,
                    333.98313203644807,
                    73.4183908045977,
                    336.9838929972098,
                    73.4183908045977,
                    337.984146650797,
                    72.41609195402299,
                    337.984146650797,
                    71.41379310344828,
                    337.984146650797,
                    71.41379310344828,
                    337.984146650797,
                    71.41379310344828,
                    337.984146650797,
                    70.41149425287357,
                    337.984146650797,
                    70.41149425287357,
                    337.984146650797,
                    69.40919540229885,
                    338.9844003043843,
                    69.40919540229885,
                    338.9844003043843,
                    68.40689655172415,
                    338.9844003043843,
                    68.40689655172415,
                    338.9844003043843,
                    67.40459770114943,
                    339.98465395797155,
                    67.40459770114943,
                    339.98465395797155,
                    67.40459770114943,
                    340.98490761155875,
                    66.40229885057471,
                    340.98490761155875,
                    66.40229885057471,
                    340.98490761155875,
                    65.4,
                    340.98490761155875,
                    64.39770114942529,
                    340.98490761155875,
                    64.39770114942529,
                    340.98490761155875,
                    63.39540229885058,
                    340.98490761155875,
                    63.39540229885058,
                    340.98490761155875,
                    62.393103448275866,
                    341.985161265146,
                    62.393103448275866,
                    341.985161265146,
                    62.393103448275866,
                    341.985161265146,
                    61.390804597701155,
                    341.985161265146,
                    60.38850574712644,
                    341.985161265146,
                    60.38850574712644,
                    342.9854149187333,
                    59.38620689655173,
                    342.9854149187333,
                    59.38620689655173,
                    342.9854149187333,
                    58.383908045977016,
                    342.9854149187333,
                    58.383908045977016,
                    342.9854149187333,
                    57.3816091954023,
                    343.98566857232055,
                    57.3816091954023,
                    344.98592222590776,
                    56.37931034482759,
                    344.98592222590776,
                    56.37931034482759,
                    344.98592222590776,
                    55.377011494252876,
                    344.98592222590776,
                    55.377011494252876,
                    344.98592222590776,
                    54.374712643678166,
                    344.98592222590776
                ]
            }
        ]
    }
}

export {startingDoodle};